export const GET_USER_INFO_INITIATE = "GET_USER_INFO_INITIATE";
export const GET_USER_INFO_START = "GET_USER_INFO_START";
export const GET_USER_INFO_PASS = "GET_USER_INFO_PASS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";
export const SET_USER_INFO = "SET_USER_INFO";

export const setUserInfo = info => {
  return {
    type: SET_USER_INFO,
    info: info
  };
};

export const getUserInfoInitiate = () => {
  return {
    type: GET_USER_INFO_INITIATE
  };
};

export const getUserInfoStart = () => {
  return {
    type: GET_USER_INFO_START
  };
};

export const getUserInfoPass = (data, passMsg) => {
  return {
    type: GET_USER_INFO_PASS,
    data: data,
    msg: passMsg
  };
};
export const getUserInfoFail = errorMsg => {
  return {
    type: GET_USER_INFO_FAIL,
    msg: errorMsg
  };
};
