import { combineReducers } from "redux";

import loginReducer from "./login";
import commonReducer from "./common";
import signupReducer from "./signup";
import forgotPassword from "./forgot-password";
import getFormDataReducer from "./fetch-form-data";
import getTestsListReducer from "./fetch-tests-list";
import startTestRequestReducer from "./start-test";
import submitTestRequestReducer from "./submit-test";
import userInfoReducer from "./get-user-info";
import updateUserInfoReducer from "./update-user-info";
import fetchStatsReducer from "./fetch-stats";
import getEventsListReducer from "./fetch-events-list";
import getEventDetailsReducer from "./fetch-event-details";
import getEventAttendeesReducer from "./fetch-event-attendees";
import eventCheckInReducer from "./event-check-in";
import retryTestRequestReducer from "./retry-test-request";
import loadingReducer from './api/loadingReducer';

import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
  common: commonReducer,
  login: loginReducer,
  signup: signupReducer,
  form: formReducer,
  forgotPassword: forgotPassword,
  getFormData: getFormDataReducer,
  getTestsList: getTestsListReducer,
  startTestRequest: startTestRequestReducer,
  submitTestRequest: submitTestRequestReducer,
  userInfo: userInfoReducer,
  updateUserInfo: updateUserInfoReducer,
  fetchStats: fetchStatsReducer,
  getEvents: getEventsListReducer,
  getEventDetails: getEventDetailsReducer,
  eventAttendees: getEventAttendeesReducer,
  eventCheckIn: eventCheckInReducer,
  retryTestRequest: retryTestRequestReducer,
  loadingReducer: loadingReducer
});

export default rootReducer;
