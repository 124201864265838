import React, { useRef, useEffect } from "react";
import SimpleCrypto from "simple-crypto-js";
import next from "assets/images/arrow_nxt.svg";
import noPic from "assets/icons/no-pic.jpg";
import "./styles.scss";

import { useDispatch, useSelector } from "react-redux";
import { submit, reset } from 'redux-form';

import { ChangePasswordReduxForm } from "components/sef/forms/change-password";

import { useLoadingSelector } from "hooks/api/loadingSelector";

import { useFormDataFetching } from 'hooks/fetch-form-data';

import {
  getUserInfoInitiate,
  changePassword,
  uploadProfilePic,
  CHANGE_PASSWORD,
  UPLOAD_PROFILE_PIC,
  setUserInfo,
  setEncryptedPassword,
  unSecureUploadDocument
} from "../../../../../redux/sef/actions";

import { Loader } from 'components/sef/atoms/loader';
import Document from "components/sef/atoms/document";

export const UserProfile = ({
  showSnackBar
}) => {
  const dispatch = useDispatch();
  const { userTypes } = useFormDataFetching();
  const formDataLoading = useSelector((state) => state.getFormData.loading);
  const fileUploadRef = useRef();
  const profilePicRef = useRef();
  const userInfo = useSelector(state => state.userInfo);
  const userDataLoading = userInfo.loading;
  // const updateUserInfo = useSelector(state => state.updateUserInfo);
  // const updateUserInfoLoader = updateUserInfo.loading;
  const userData = userInfo.data;
  const [changePasswordRequested, uploadProfilePicRequested] = useLoadingSelector([CHANGE_PASSWORD, UPLOAD_PROFILE_PIC]);
  const handleFileUpload = (file) => {
    dispatch(unSecureUploadDocument(file, (data, msg) => {
      dispatch(uploadProfilePic({ "profileUrl": data.data.url },
        (msg) => {
          showSnackBar(msg, 'success');
          profilePicRef.current.src = data.data.url;
          dispatch(setUserInfo({ ...userInfo.data, profileUrl: data.data.url }));
        },
      (msg) => showSnackBar(msg, 'error')));
    }
    ))
    // let fileReader = new FileReader();
    // let chosenFile = fileUploadRef.current.files[0];
    // fileReader.onload = (event) => {
    //   dispatch(uploadProfilePic({ "profileUrl": event.target.result },
    //     (msg) => {
    //       showSnackBar(msg, 'success');
    //       profilePicRef.current.src = event.target.result;
    //       dispatch(setUserInfo({ ...userInfo.data, profileUrl: event.target.result }));
    //     },
    //     (msg) => showSnackBar(msg, 'error')));

    // }
    // fileReader.readAsDataURL(chosenFile);
  };
  useEffect(() => {
    dispatch(getUserInfoInitiate());
  },[]);
  return (
    <>
      {userDataLoading || formDataLoading ? <Loader /> : <>
      {changePasswordRequested || uploadProfilePicRequested ? <Loader/> : null}
      {/* {updateUserInfoLoader ? <Loader />: null} */}
      <h3 className="title-text">Profile</h3>
      <div className="card border-0 shadow">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="profile_pic text-center position-relative">
                <span className="user_pic"><img alt="profile-pic" src={userData?.profileUrl ? userData.profileUrl : noPic} ref={profilePicRef} /></span>
                <div className="upload_pic">
                  <label htmlFor="upload">
                    <i>
                      <img src={require('../../../../../assets/icons/edit_icon.svg')} width="" alt="SEF" />
                    </i>
                  </label>
                  <input
                    id="upload"
                    className="upload-photo"
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={fileUploadRef}
                    onChange={(e) => handleFileUpload(e.target.files[0])}
                  ></input>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="profile_detail pl-md-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <i className="mr-2"><img src={require('../../../../../assets/icons/email_icon.svg')} width="" alt="SEF" /></i>
                        Email
                      </label>
                      <div className="label_field">
                        {userData?.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <i className="mr-2"><img src={require('../../../../../assets/icons/user_icon.svg')} width="" alt="SEF" /></i>
                        Name
                      </label>
                      <div className="label_field">
                        {userData?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <i className="mr-2"><img src={require('../../../../../assets/icons/role_icon.svg')} width="" alt="SEF" /></i>
                        Role
                      </label>
                      <div className="label_field">
                          {userTypes.find(
                            (role, idx) => role.value === userData?.role
                          )?.label}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 className="title-text mt-4">Change Password </h3>
      <div className="card border-0 shadow ">
        <div className="card-body">
          <ChangePasswordReduxForm
            onSubmit={(values) => {
              let { oldPassword, newPassword } = values;
              dispatch(changePassword(
                { password: oldPassword, newPassword },
                (msg) => {
                  window.scrollTo(0, 0); showSnackBar(msg, 'success');
                  const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_REMEMBER_ME_SECRET_KEY);
                  dispatch(setEncryptedPassword(simpleCrypto.encrypt(newPassword)));
                  dispatch(reset('change-password'));
                },
                (msg) => {
                  window.scrollTo(0, 0);
                  showSnackBar(msg, 'error')
                }
                ))
            }
            } 
          />
        </div>
      </div>
      
      <div className="text-right my-3">
        <button type="submit" className={`btn btn-lg btn-primary`} onClick={() => dispatch(submit('change-password'))}>
          Save <i className="float-right ml-2"> <img src={next} alt="" width="24px" /></i>
        </button>
      </div>

      <Document
        userData={userData}
      />  
      </>}
    </>
  );
};
