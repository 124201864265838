const SERVER_URL = process.env.REACT_APP_API_URL || "https://0b00-112-196-113-2.ngrok.io";

export const URL = {
  SERVER_URL: SERVER_URL,
  LOGIN: SERVER_URL + "/v1/user/login",
  REGISTER: SERVER_URL + "/v1/user/register",
  GET_FORM_DATA: SERVER_URL + "/v1/user/initial-data",
  FORGOT_PASSWORD: SERVER_URL + "/v1/user/forgot-password",
  LOGOUT: SERVER_URL + "/v1/user/logout",
  TEST_UPCOMING: SERVER_URL + "/v1/test/upcoming",
  TEST_REQUEST: SERVER_URL + "/v1/test/request",
  TEST_SUBMIT: SERVER_URL + "/v1/test/submit",
  GET_USER_INFO: SERVER_URL + "/v1/user",
  UPDATE_USER_INFO: SERVER_URL + "/v1/user/profile",
  GET_STATS: SERVER_URL + "/v1/user/statistics",
  EVENTS_LIST: SERVER_URL + "/v1/event/list",
  EVENT_LIST_WITH_ATTENDEES: SERVER_URL + "/v1/event/list-with-attendees",
  EVENT_CHECK_IN: SERVER_URL + "/v1/event/check-in",
  EVENT_ATTENDEES: SERVER_URL + "/v1/event/attendees",
  EVENT_DETAIL: SERVER_URL + "/v1/event/:id",
  RETRY_TEST_REQUEST: SERVER_URL + "/v1/championship/user/unblock-request",
  PROFILE_PIC_UPLOAD: SERVER_URL + '/v1/user/profile-pic',
  CHANGE_PASSWORD: SERVER_URL + '/v1/user/change-password',
  UPLOAD_DOCUMENT: SERVER_URL + '/v1/file/secureUpload',
  SAVE_DOCUMENT: SERVER_URL + '/v1/user/documents',
  TEST_DOCUMENT: SERVER_URL + '/v1/test/documents',
  UNSECURE_UPLOAD_DOCUMENT: SERVER_URL + '/v1/file/upload'
};
