import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";

//import { useMultiStepWithUserRole } from "hooks/multi-step-with-user-role";
import { useFormDataFetching } from "hooks/fetch-form-data";
import { reset, clearFields } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { formValueSelector } from 'redux-form';

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const { Logo } = require(`components/${PLATFORM}/atoms/logo`);
const {
  SnackbarWrapper
} = require(`components/${PLATFORM}/molecules/snackbar-wrapper`);
const {
  BackgroundRacingFlags
} = require(`components/${PLATFORM}/atoms/background-racing-flags`);
const {
  LoginSignupTab
} = require(`components/${PLATFORM}/atoms/login-signup-tab`);
const { Loader } = require(`components/${PLATFORM}/atoms/loader`);
const { FormLoader } = require(`components/${PLATFORM}/atoms/form-loader`);
const {
  DecisionPopup
} = require(`components/${PLATFORM}/atoms/decision-popup`);

const { PersonalInfoReduxForm } = require(`components/sef/forms/personal-info`);

const { getCountryData } = require(`helpers/${PLATFORM}`);

let data = getCountryData();
let countries = data.nations;

export const SignUp = ({
  signupLoading,
  signupMsg,
  formValues,
  formLoading,
  signUpUser
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selector = formValueSelector("signup");
   const selectedUserType = selector(
     useSelector((state) => state),
     "role"
   );
  // const {
  //   selectedUserType,
  //   activeStep,
  //   goToNextStep,
  //   goToPrevStep
  // } = useMultiStepWithUserRole();
  const {
    userTypes,
  } = useFormDataFetching();
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isRegisteredSuccessfully, setisRegisteredSuccessfully] = useState(
    false
  );
  let hideCityField = true;
  let hideHouseNoField = true;
  let hideStreetField = true;
  let hidePostalCodeField = true;
  let hideNationalityField = true;
  let hideChampionshipField = true;
  let showOtherUserTypeField = false;
  let hideChampionshipAsterisk = false;

  if (selectedUserType?.label === 'Driver' || selectedUserType?.label === 'Media') {
    hideCityField = hideHouseNoField = hideStreetField = hidePostalCodeField = hideNationalityField = hideChampionshipField = false;
    showOtherUserTypeField = false;
    hideChampionshipAsterisk = false;
  }
  else if(selectedUserType?.label === 'Fan' || selectedUserType?.label === 'Others') {
    hideCityField = hideHouseNoField = hideStreetField = hidePostalCodeField = hideNationalityField = true;
    if (selectedUserType?.label === 'Others') {
      showOtherUserTypeField = true;
      hideChampionshipField = false;
      hideChampionshipAsterisk = true;
    }
    else {
      showOtherUserTypeField = false;
      hideChampionshipField = true;
      hideChampionshipAsterisk = false;
    }
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [activeStep, signupMsg]);

  useEffect(() => {
    return () => {
      dispatch(reset("signup"));
    };
  }, []);

  const handleInitialValues = React.useMemo(() => {
    return {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      role: undefined,
      nationality: undefined,
      city: undefined,
      hno: undefined,
      street: undefined,
      postalCode: undefined,
      championship: undefined,
      otherUserType: undefined,
      isConsent: undefined,
    };
  }, []);

  const finalSubmitHandler = values => {
    let finalData;
    const {
      firstName,
      lastName,
      email,
      role,
      nationality,
      city,
      hno,
      street,
      postalCode,
      championship,
      otherUserType,
      isConsent,
    } = values;
    finalData = {
      firstName, lastName, email, role: role.value, nationality: nationality && nationality.value, city,
      address: {
        hno, street
      },
      postalCode, championship, otherUserType, isConsent
    };
    signUpUser(
      finalData,
      () => {
        setisRegisteredSuccessfully(true);
        setOpenPopup(true);
      },
      () => {
        setOpenSnackbar(true);
      }
    );
  };

  return (
    <>
      {openPopup && (
        <DecisionPopup
          twoButtons={false}
          modalVisibility={true}
          dialogContent={signupMsg}
          dialogTitle={"Registered Successfully"}
          confirmButtonTitle={"Okay"}
          onConfirmation={() => history.push(ROUTES.LOGIN)}
        />
      )}
      <Prompt
        when={formValues && Object.keys(formValues).length === 0 && formValues.constructor === Object && !isRegisteredSuccessfully ? true : false}
        message="The data you entered will get lost, are you sure you want to leave?"
      />
      {signupLoading ? <Loader /> : null}
      <BackgroundRacingFlags />
      <div className={"container"}>
        <Logo />
        <div className={"login-signup-container"}>
          <LoginSignupTab tabValue={1} />
          {formLoading ? (
            <FormLoader />
          ) : (
            <>
              <div className="front-form">
                <div className="log_reg_form">
                  <SnackbarWrapper
                    visible={openSnackBar}
                    onClose={() => setOpenSnackbar(false)}
                    variant={"error"}
                    message={signupMsg}
                  />
                  <PersonalInfoReduxForm
                    initialValues={handleInitialValues}
                    onSubmit={finalSubmitHandler}
                    countries={countries}
                    userTypes={userTypes}
                    hideCityField={hideCityField}
                    hideHouseNoField={hideHouseNoField}
                    hideStreetField={hideStreetField}
                    hidePostalCodeField={hidePostalCodeField}
                    hideNationalityField={hideNationalityField}
                    hideChampionshipField={hideChampionshipField}
                    hideBirthDateField={true}
                    hidePhoneField={true}
                    showOtherUserTypeField={showOtherUserTypeField}
                    hideChampionshipAsterisk={hideChampionshipAsterisk}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
