import React from "react";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";

import { Input } from "../../atoms/input";
import { Checkbox } from "../../atoms/checkbox";
import { InputSubmit } from "../../atoms/input-submit";

import validator from "./validator";

import { STRINGS } from "./constants";
const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { onSubmitFail } = require(`helpers/${PLATFORM}`);

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const LoginForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <Field
          name={STRINGS.EMAIL_KEY}
          component={Input}
          label={STRINGS.EMAIL_LABEL}
          config={{
            type: "email",
            placeholder: STRINGS.EMAIL_PLACEHOLDER
          }}
        />
        <Field
          name={STRINGS.PASSWORD_KEY}
          component={Input}
          label={STRINGS.PASSWORD_LABEL}
          config={{
            type: "password",
            placeholder: STRINGS.PASSWORD_PLACEHOLDER
          }}
        />
      </div>
      <div className="row">
        <Field
          name={STRINGS.REMEMBER_ME_KEY}
          component={Checkbox}
          texts={[{
            isLink: false,
            value: STRINGS.REMEMBER_ME_TEXT
          }]}
        />
        <Link
          className="col-md-6 auth-link text-md-right"
          to={ROUTES.FORGOT_PASSWORD}
        >
          {STRINGS.FORGOT_PASSWORD_LINK_TEXT}
        </Link>
      </div>
      <InputSubmit
        buttonLabel={STRINGS.BUTTON_LABEL_LOGIN}
        containerStyle={[]}
        buttonStyle="float-right"
      />
    </form>
  );
};

export const LoginReduxForm = reduxForm({
  form: "login",
  validate: validator,
  enableReinitialize: true,
  onSubmitFail: onSubmitFail
})(LoginForm);
