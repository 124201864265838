export const LOGOUT_INITIATE = "LOGOUT_INITIATE";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_PASS = "LOGOUT_PASS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const logoutInitiate = (handleSuccess) => {
  return {
    type: LOGOUT_INITIATE,
    handleSuccess
  };
};

export const logoutStart = () => {
  return {
    type: LOGOUT_START
  };
};

export const logoutPass = passMsg => {
  return {
    type: LOGOUT_PASS,
    msg: passMsg
  };
};
export const logoutFail = errorMsg => {
  return {
    type: LOGOUT_FAIL,
    msg: errorMsg
  };
};
