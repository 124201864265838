export const STRINGS = {
  CONTINUE_BUTTON_LABEL: "CONTINUE",
  PREVIOUS_BUTTON_LABEL: "PREVIOUS",

  BLOOD_GROUP_KEY: "bloodGroup",
  BLOOD_GROUP_LABEL: "Blood Group",
  Blood_GROUP_PLACEHOLDER: "Select",

  BLOOD_ALLERGIES_KEY: "knownBloodAllergies",
  BLOOD_ALLERGIES_LABEL: "Any Known Blood Allergies",
  BLOOD_ALLERGIES_PLACEHOLDER: "E.g. Neurontin",

  MEDICAL_HISTORY_KEY: "relevantPastHistory",
  MEDICAL_HISTORY_LABEL: "Any relevant past history ( including Concussion )",
  MEDICAL_HISTORY_PLACEHOLDER: "Past medical history",

  OTHER_ISSUES_KEY: "issueUnderTreatment",
  OTHER_ISSUES_LABEL: "Any Issue under Treatment (Diabetes etc...)",
  OTHER_ISSUES_PLACEHOLDER: "Add any issue",

  TUE_KEY: "existingTUE",
  TUE_LABEL: "Any existing therapeutic Use Exemption ( T.U.E.)",
  TUE_PLACEHOLDER: "Add any T.U.E",

  LAST_TETANUS_INJECTION_YAER_KEY: "lastTetanusInjection",
  LAST_TETANUS_INJECTION_YAER_LABEL: "Year of last tetanus injection",
  LAST_TETANUS_INJECTION_YAER_PLACEHOLDER: "Select Year",

  EMERGENCY_HEADING_TEXT: "Emergency Details",

  EMERGENCY_CONTACT_NAME_KEY: "contactName",
  EMERGENCY_CONTACT_NAME_LABEL: "Contact Name",
  EMERGENCY_CONTACT_NAME_PLACEHOLDER: "Enter your emergency contact name",

  EMERGENCY_CONTACT_RELATION_KEY: "relationship",
  EMERGENCY_CONTACT_RELATION_LABEL: "Relationship",
  EMERGENCY_CONTACT_RELATION_PLACEHOLDER: "Select",

  EMERGENCY_CONTACT_NUMBER_KEY: "emergencyPhone",
  EMERGENCY_CONTACT_NUMBER_LABEL: "Contact Number",
  EMERGENCY_CONTACT_NUMBER_PLACEHOLDER: "Emergency contact number",

  EMERGENCY_CONTACT_NUMBER_CODE_KEY: "emergencyCode",
  EMERGENCY_CONTACT_NUMBER_CODE_PLACEHOLDER: "Select"
};

export const VALIDATION_MESSAGES = {
  BLOOD_TYPE_REQUIRED: "Blood group is required",
  BLOOD_ALLERGIES_REQUIRED: "Blood allergies is required",
  PAST_MEDICAL_HISTORY_REQUIRED: "Past medical history is required",
  ISSUES_UNDER_TREATMENT_REQUIRED: "Issues under treatment is required",
  T_U_E_REQUIRED: "T.U.E is required",
  LAST_TETANUS_INJECTION_YEAR_REQUIRED:
    "Year of last tetanus injection is required",
  EMERGENCY_CONTACT_NAME_REQUIRED: "Emergency contact name is required",
  EMERGENCY_CONTACT_NAME_INVALID:
    "Name cannot have digits or special characters (except period)",
  EMERGENCY_CONTACT_NUMBER_REQUIRED: "Emergency contact number is required",
  EMERGENCY_CONTACT_NUMBER_CODE_REQUIRED: "Phone code is required",
  RELATIONSHIP_REQUIRED: "Relationship is required",
  PHONE_NUMBER_MIN_LENGTH: "Phone number cannot be less than 6 digits"
};
