export const FORGOT_PASSWORD_INITIATE = "FORGOT_PASSWORD_INITIATE";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_PASS = "FORGOT_PASSWORD_PASS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAI";

export const forgotPasswordInitiate = (email, handleSuccess, handleError) => {
  return {
    type: FORGOT_PASSWORD_INITIATE,
    email: email,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const forgotPasswordStart = () => {
  return {
    type: FORGOT_PASSWORD_START
  };
};

export const forgotPasswordPass = passMsg => {
  return {
    type: FORGOT_PASSWORD_PASS,
    msg: passMsg
  };
};
export const forgotPasswordFail = errorMsg => {
  return {
    type: FORGOT_PASSWORD_FAIL,
    msg: errorMsg
  };
};
