import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Document from "../../../../../components/sef/atoms/document";

const {
  defaultConfig: { PLATFORM, LOCATION, VERSION }
} = require("config/default");

const {
  SEFTestScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/sef-test`);

const {
  TestsListScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/sef-test/tests-list`);
const {
  MyStatisticsScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/my-statistics/`);
const {
  EventsListingScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/events/events-listing/`);
const {
  EventDetailsScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/events/event-details/`);
const {
  EventAttendeesScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/events/event-attendees/`);
const { UserProfile } = require(`views/sef/us/1.0/user-profile`);

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const { HomeScreenHOC } = require(`components/${PLATFORM}/hoc/home`);

const {
  PrivacyPolicy
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/privacy-policy/`);

const { TermsAndConditions } = require(`views/sef/us/1.0/terms-and-conditions`);
const { PrivacyNotice } = require(`views/sef/us/1.0/privacy-notice`);
const { CookiesPolicy } = require(`views/sef/us/1.0/cookies-policy`);

export const AppRoutes = ({ isProfileCompleted }) => {
  return (
    <HomeScreenHOC>
      {(showPopup, showSnackBar, openSnackBar, showCompleteYourDetailsDialog) => (
        <>
          <Switch>
            <Route exact path={ROUTES.PRIVACY_POLICY}>
              <PrivacyPolicy />
            </Route>
            <Route exact path={ROUTES.TERMS_CONDITIONS}>
              <TermsAndConditions />
            </Route>
            <Route exact path={ROUTES.PRIVACY_NOTICE}>
              <PrivacyNotice />
            </Route>
            <Route path={ROUTES.COOKIES_POLICY}>
              <CookiesPolicy />
            </Route>
            <Route exact path={ROUTES.SEF_TESTS}>
              <TestsListScreen
                showCompleteYourDetailsDialog={showCompleteYourDetailsDialog}
                showSnackBar={showSnackBar}
                showPopup={showPopup}
              />
            </Route>
            <Route exact path={ROUTES.SEF_TEST}>
              <SEFTestScreen
                showSnackBar={showSnackBar}
                showPopup={showPopup}
                snackBarVisible={openSnackBar}
              />
            </Route>
            <Route path={ROUTES.MY_STATISTICS}>
              <MyStatisticsScreen
                showSnackBar={showSnackBar}
                showPopup={showPopup}
              />
            </Route>
            <Route exact path={ROUTES.EVENTS}>
              <EventsListingScreen
                showSnackBar={showSnackBar}
                showPopup={showPopup}
                showCompleteYourDetailsDialog={showCompleteYourDetailsDialog}
              />
            </Route>
            <Route exact path={ROUTES.EVENT}>
              <EventDetailsScreen
                showSnackBar={showSnackBar}
                showPopup={showPopup}
                showCompleteYourDetailsDialog={showCompleteYourDetailsDialog}
              />
            </Route>
            <Route exact path={ROUTES.EVENT_ATTENDEES}>
              <EventAttendeesScreen
                showSnackBar={showSnackBar}
                showPopup={showPopup}
              />
            </Route>
            <Route path={ROUTES.PROFILE}>
              <UserProfile showSnackBar={showSnackBar} />
            </Route>
            <Route path={ROUTES.DOCUMENTS}>
              <Document />
            </Route>
            <Route
              path={ROUTES.NEWS_FEED}
              render={() => {
                return (
                  <>
                    <div className="card border-0 shadow ">
                      <div className="card-body">
                        <p>News Feed Coming Soon</p>
                      </div>
                    </div>
                  </>
                );
              }}
            />
            <Route
              path={ROUTES.GROUP_CHAT}
              render={() => {
                return (
                  <>
                    <div className="card border-0 shadow ">
                      <div className="card-body">
                        <p>Group Chat Coming Soon</p>
                      </div>
                    </div>
                  </>
                );
              }}
            />
            <Redirect to={ROUTES.SEF_TESTS} />
          </Switch>
        </>
      )}
    </HomeScreenHOC>
  );
};
