import React, { useState, useEffect, useMemo } from "react";
import {
  useDispatch
} from "react-redux";
import {
  reset
} from "redux-form";

import { useMultiStepWithUserRole } from "hooks/multi-step-with-user-role";
import { useFormDataFetching } from "hooks/fetch-form-data";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  BackgroundRacingFlags
} = require(`components/${PLATFORM}/atoms/background-racing-flags`);
const { Logo } = require(`components/${PLATFORM}/atoms/logo`);
const { Loader } = require(`components/${PLATFORM}/atoms/loader`);
const { RenderForm } = require(`components/${PLATFORM}/forms/render-form`);
const { FormLoader } = require(`components/${PLATFORM}/atoms/form-loader`);
const {
  FormProgressBar
} = require(`components/${PLATFORM}/atoms/form-progress-bar`);
const {
  SnackbarWrapper
} = require(`components/${PLATFORM}/molecules/snackbar-wrapper`);

const { prepareUserInfo } = require(`helpers/${PLATFORM}`);

export const CompleteProfile = ({
  initiateCompleteProfile,
  getUserInfo,
  updateUserInfoLoading,
  updateUserInfoMsg,
  formLoading,
  formData,
  userDataLoading,
  userData,
  closeModal
}) => {
  const dispatch = useDispatch();
  const {
    formHeadings,
    activeStep,
    stepsInfo,
    goToNextStep,
    goToPrevStep
  } = useMultiStepWithUserRole();
  const {
    userTypes,
    FIACategorisations,
    bloodGroups,
    relationships
  } = useFormDataFetching();

  const [openSnackBar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    return () => {
      dispatch(reset("signup"));
    };
  }, []);

  const handleInitialValues = useMemo(() => {
    var initialFilledData;
    if (userData) {
      let profileData = userData;
      let {
        firstName,
        lastName,
        email,
        city,
        postalCode,
        championship,
        nationality,
        address
      } = profileData;
      let filteredData = formData.userTypes.find(
        userType => userType.key === profileData.role
      );

      initialFilledData = {
        firstName,
        lastName,
        email,
        city,
        postalCode,
        championship,
        nationality:{label: nationality, value: nationality},
        hno: address?.hno,
        street: address?.street
      };

      return {
       ...initialFilledData,
        role: filteredData
          ? { label: filteredData.value, value: filteredData.key }
          : ""
      };
    }
    return {};
  }, [userData, formData]);

  const finalSubmitHandler = values => {
    const finalData = prepareUserInfo(values);
    initiateCompleteProfile(
      finalData,
      () => {
        closeModal();
      },
      () => {
        setOpenSnackbar(true);
      }
    );
  };

  return (
    <>
      {updateUserInfoLoading ? <Loader /> : null}
      <div className={"container"}>
        <div className={"login-signup-container"}>
          <div className="title_form text-center pt-4 pt-md-5">
            <h2>Complete Your Details</h2>
          </div>
          {formLoading || userDataLoading ? (
            <FormLoader />
          ) : (
            <>
              <FormProgressBar
                stepsInfo={stepsInfo}
                formHeadings={formHeadings}
              />
              <div className="front-form">
                <div className="log_reg_form">
                  <SnackbarWrapper
                    visible={openSnackBar}
                    onClose={() => setOpenSnackbar(false)}
                    variant={"error"}
                    message={updateUserInfoMsg}
                  />
                  <RenderForm
                    activeStep={activeStep}
                    disableRoleField={true}
                    disableEmailField={true}
                    hidePasswordField={true}
                    initialValues={handleInitialValues}
                    goToNextStep={goToNextStep}
                    goToPrevStep={goToPrevStep}
                    userTypes={userTypes}
                    FIACategorisations={FIACategorisations}
                    bloodGroups={bloodGroups}
                    relationships={relationships}
                    finalButtonLabel={"SAVE & PROCEED"}
                    finalSubmitHandler={finalSubmitHandler}
                    hideTermsConditions={true}
                    hideSubscriptionUpdates={true}
                    hidePrivacyNotice={true}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
