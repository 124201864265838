export const STRINGS = {
  EMAIL_KEY: "email",
  EMAIL_LABEL: "Email",
  EMAIL_PLACEHOLDER: "Eg: example@gmail.com",

  PASSWORD_KEY: "password",
  PASSWORD_LABEL: "Password",
  PASSWORD_PLACEHOLDER: "Password",

  REMEMBER_ME_KEY: "persistAuth",
  REMEMBER_ME_TEXT: "Remember me",

  FORGOT_PASSWORD_LINK_TEXT: "Forgot password?",

  BUTTON_LABEL_LOGIN: "LOG IN"
};

export const VALIDATION_MESSAGES = {
  EMAIL_REQUIRED: "Email is required",
  EMAIL_INVALID: "Email is invaid",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_INVALID: "Password is weak"
};
