import { countries } from "countries-list";

export const getCountryData = () => {
  let nations = [];
  let callingCodes = [];
  for (const key in countries) {
    let data = countries[key];

    nations.push({
      label: data.name,
      value: data.name
    });
    callingCodes.push({
      label: `+${data.phone} ${data.emoji} (${data.name})`,
      value: `+${data.phone}`
    });
  }
  return {
    nations: nations,
    callingCodes: callingCodes
  };
};
