import React from "react";
import "./style.scss";

export const BackgroundRacingFlags = () => {
  return (
    <>
      <div className="top_rht_bg"></div>
      <div className="btm_rht_bg"></div>
    </>
  );
};
