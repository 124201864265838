import { getRequest } from "helpers/sef";
import { put, takeLatest } from "redux-saga/effects";
import {
  startTestStart,
  startTestPass,
  startTestFail,
  START_TEST_INITIATE,
  logoutInitiate,
  TEST_DOCUMENT,
  startTestLoader,
  stopTestLoader
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* startTestRequestSaga(action) {
  yield put(startTestStart());
  try {
    const response = yield postRequest({
      API: `${URL.TEST_REQUEST}`,
      DATA: {
        championshipId: action.championshipId
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(startTestPass(response.data.data, response.data.msg));
      yield action.handleSuccess();
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(startTestFail(response.data.msg));
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put(startTestFail("Something went wrong"));
    yield action.handleError("Something went wrong");
  } finally {
  }
}

function* testDocument (action) {
  yield put(startTestLoader());
  try {
    const response = yield getRequest({
      API: `${URL.TEST_DOCUMENT}/${action.id}`,
      DATA: {
        championshipId: action.championshipId
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(stopTestLoader());
      yield action.handleSuccess(response.data.data, response.data.msg);
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(stopTestLoader());
      yield put(logoutInitiate());
    } else {
      yield put(stopTestLoader());
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put(startTestFail("Something went wrong"));
    yield put(stopTestLoader());
    yield action.handleError("Something went wrong");
  } finally {
  }
}

export function* watchStartTestRequest() {
  yield takeLatest(START_TEST_INITIATE, startTestRequestSaga);
  yield takeLatest(TEST_DOCUMENT, testDocument)
}
