import React from "react";
import next from "assets/images/arrow_nxt.svg";

export const InputSubmit = ({
  handleNext,
  handleBack,
  buttonLabel,
  backButtonLabel,
  containerStyle,
  buttonStyle,
  config,
  immediateDivStyle
}) => {
  return (
    <div className="row">
      <div className="col-md-12 text-center text-sm-right mt-1">
        <div className={`form-group ${containerStyle.join(" ")}`}>
          < div className={
            `d-flex flex-column flex-md-row ${immediateDivStyle ? immediateDivStyle : 'justify-content-sm-end'}`
          } >
            {!!backButtonLabel && <button
              type='button'
              onClick={handleBack}
              className="btn btn-lg btn-outline mb-2 mb-md-0 mr-0 mr-md-3"
            >
              {backButtonLabel}{" "}
            </button>}

            <button

              onClick={handleNext}
              {...config}
              type="submit"
              className={`btn btn-lg btn-primary ${buttonStyle}`}
            >
              {buttonLabel}{" "}
              <i className="float-right ml-2">
                <img src={next} alt="" width="24px" />
              </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
