import { START_TEST_START, START_TEST_PASS, START_TEST_FAIL, TEST_START_LOADER, TEST_STOP_LOADER } from "../actions";

const initialState = {
  data: null,
  loading: false,
  status: false,
  msg: ""
};

const startTestRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_TEST_START: {
      return {
        ...state,
        loading: true
      };
    }

    case START_TEST_PASS: {
      return {
        ...state,
        data: action.data,
        status: true,
        loading: false,
        msg: action.msg
      };
    }

    case START_TEST_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }
    case TEST_START_LOADER: {
      return {
        ...state,
        loading: true
      }
    }
    case TEST_STOP_LOADER: {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return state;
    }
  }
};

export default startTestRequestReducer;
