import { connect } from "react-redux";

import { CompleteProfile } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  updateUserInfoInitiate,
  getUserInfoInitiate
} = require(`../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    updateUserInfoLoading: state.updateUserInfo.loading,
    updateUserInfoMsg: state.updateUserInfo.msg,
    formLoading: state.getFormData.loading,
    userDataLoading: state.userInfo.loading,
    userData: state.userInfo.data,
    formData: state.getFormData.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initiateCompleteProfile: (info, handleSuccess, handleError) =>
      dispatch(updateUserInfoInitiate(info, handleSuccess, handleError)),
    getUserInfo: () => dispatch(getUserInfoInitiate())
  };
};

export const CompleteProfileScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteProfile);
