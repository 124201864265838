export const UPDATE_USER_INFO_INITIATE = "UPDATE_USER_INFO_INITIATE";
export const UPDATE_USER_INFO_START = "UPDATE_USER_INFO_START";
export const UPDATE_USER_INFO_PASS = "UPDATE_USER_INFO_PASS";
export const UPDATE_USER_INFO_FAIL = "UPDATE_USER_INFO_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPLOAD_PROFILE_PIC = "UPLOAD_PROFILE_PIC";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT"
export const SAVE_UPLOAD_DOCUMENT = "SAVE_UPLOAD_DOCUMENT"
export const UNSECURE_UPLOAD_DOCUMENT ="UNSECURE_UPLOAD_DOCUMENT"

export const updateUserInfoInitiate = (info, handleSuccess, handleError) => {
  return {
    info: info,
    type: UPDATE_USER_INFO_INITIATE,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const updateUserInfoStart = () => {
  return {
    type: UPDATE_USER_INFO_START
  };
};

export const updateUserInfoPass = passMsg => {
  return {
    type: UPDATE_USER_INFO_PASS,
    msg: passMsg
  };
};
export const updateUserInfoFail = errorMsg => {
  return {
    type: UPDATE_USER_INFO_FAIL,
    msg: errorMsg
  };
};

export const changePassword = (data, handleSuccess, handleError) => {
  return {
    type: CHANGE_PASSWORD,
    handleSuccess,
    handleError,
    data
  }
}
export const uploadProfilePic = (data, handleSuccess, handleError) => {
  return {
    type: UPLOAD_PROFILE_PIC,
    handleSuccess,
    handleError,
    data,
  };
};
export const uploadDocument = (data, handleSuccess, handleError) => {
  return {
    type: UPLOAD_DOCUMENT ,
    handleSuccess,
    handleError,
    data,
  }
}
export const unSecureUploadDocument = (data, handleSuccess, handleError) => {
  return {
    type: UNSECURE_UPLOAD_DOCUMENT,
    handleSuccess,
    handleError,
    data,
  }
}
export const saveUploadDocument = (data, handleSuccess, handleError) => {
  return {
    type: SAVE_UPLOAD_DOCUMENT,
    handleSuccess,
    handleError,
    data,
  }
}
