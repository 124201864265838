import { put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  updateUserInfoStart,
  updateUserInfoPass,
  updateUserInfoFail,
  UPDATE_USER_INFO_INITIATE,
  setUserProfileCompleted,
  CHANGE_PASSWORD,
  UPLOAD_PROFILE_PIC,
  UPLOAD_DOCUMENT,
  SAVE_UPLOAD_DOCUMENT,
  getUserInfoInitiate,
  UNSECURE_UPLOAD_DOCUMENT
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { putRequest, postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* updateUserInfoSaga(action) {
  yield put(updateUserInfoStart());
  try {
    var response = yield putRequest({
      API: `${URL.UPDATE_USER_INFO}`,
      DATA: {
        ...action.info
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(updateUserInfoPass());
      yield put(setUserProfileCompleted(true));
      yield action.handleSuccess();
    } else {
      yield put(updateUserInfoFail(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(updateUserInfoFail("Something went wrong"));
    yield action.handleError();
  }
}

function* changePasswordSaga(action) {
  yield put({type: `${action.type}_REQUEST`});
  try {
    var response = yield postRequest({
      API: `${URL.CHANGE_PASSWORD}`,
      DATA: {
        ...action.data,
      },
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put({type: `${action.type}_SUCCESS`});
      yield action.handleSuccess(response.data.msg);
    } else {
      yield put({type: `${action.type}_FAILURE`});
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put({type: `${action.type}_FAILURE`});
    yield action.handleError('Something went wrong');
  }
}

function* uploadProfilePicSaga(action) {
  yield put({type: `${action.type}_REQUEST`});
  try {
    var response = yield putRequest({
      API: `${URL.PROFILE_PIC_UPLOAD}`,
      DATA: {
        ...action.data,
      },
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put({type: `${action.type}_SUCCESS`});
      yield action.handleSuccess(response.data.msg);
    } else {
      yield put({type: `${action.type}_FAILURE`});
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put({type: `${action.type}_FAILURE`});
    yield action.handleError('Something went wrong');
  }
}

function* uploadDocument(action) {
  yield put({type: `${action.type}_REQUEST`});
  try {
    let formData = new FormData()
    formData.append("file", action.data)
    yield put(updateUserInfoStart())
    var response = yield postRequest({
      API: `${URL.UPLOAD_DOCUMENT}`,
      DATA: formData,
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put({type: `${action.type}_SUCCESS`});
      yield put(updateUserInfoPass(response.data.msg))
      yield action.handleSuccess(response.data,response.data.msg);
    } else {
      yield put({type: `${action.type}_FAILURE`});
      yield put(updateUserInfoFail(response.data.msg))
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put({type: `${action.type}_FAILURE`});
    yield put(updateUserInfoFail(response?.data?.msg))
    yield action.handleError('Something went wrong');
  }
}
function* unSecureUploadDocument(action) {
  yield put({ type: `${action.type}_REQUEST` });
  try {
    let formData = new FormData()
    formData.append("file", action.data)
    yield put(updateUserInfoStart())
    var response = yield postRequest({
      API: `${URL.UNSECURE_UPLOAD_DOCUMENT}`,
      DATA: formData,
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put({ type: `${action.type}_SUCCESS` });
      yield put(updateUserInfoPass(response.data.msg))
      yield action.handleSuccess(response.data, response.data.msg);
    } else {
      yield put({ type: `${action.type}_FAILURE` });
      yield put(updateUserInfoFail(response.data.msg))
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put({ type: `${action.type}_FAILURE` });
    yield put(updateUserInfoFail(response.data.msg))
    yield action.handleError('Something went wrong');
  }
}

function* saveUploadDocument(action) {
  yield put({type: `${action.type}_REQUEST`});
  try {
    yield put(updateUserInfoStart())
    var response = yield putRequest({
      API: `${URL.SAVE_DOCUMENT}`,
      DATA: {...action.data},
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put({type: `${action.type}_SUCCESS`});
      yield put(updateUserInfoPass(response.data.msg))
      yield put(getUserInfoInitiate())
      yield action.handleSuccess(response.data,response.data.msg);
    } else {
      yield put({type: `${action.type}_FAILURE`});
      yield put(updateUserInfoFail(response.data.msg))
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put({type: `${action.type}_FAILURE`});
    yield put(updateUserInfoFail(response.data.msg))
    yield action.handleError('Something went wrong');
  }
}

export function* wathchUpdateUserInfo() {
  yield takeEvery(UPDATE_USER_INFO_INITIATE, updateUserInfoSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(UPLOAD_PROFILE_PIC, uploadProfilePicSaga);
  yield takeLatest(UPLOAD_DOCUMENT, uploadDocument);
  yield takeLatest( SAVE_UPLOAD_DOCUMENT, saveUploadDocument);
  yield takeLatest(UNSECURE_UPLOAD_DOCUMENT, unSecureUploadDocument);
}
