// simple count down timer
import { useState, useEffect, useMemo } from "react";
let interval = null;
// initialTime in sec
export const useCountDownTimer = (initialTime, onTimeOver) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    interval = setInterval(() => {
      setTime(time => time - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getTime = useMemo(() => {
    let hours = Math.floor(time / 3600);
    let secondsLeft = time % 3600;
    let minutes = Math.floor(secondsLeft / 60);
    let seconds = secondsLeft % 60;
    return [hours, minutes, seconds];
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(interval);
      onTimeOver(getTime);
    }
  }, [time, getTime]);

  return getTime;
};
