import { put, takeLatest, delay } from "redux-saga/effects";
import {
  fetchEventsListStart,
  fetchEventsListPass,
  fetchEventsListFail,
  FETCH_EVENTS_LIST_INITIATE,
  logoutInitiate
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* fetchEventsSaga(action) {
  yield put(fetchEventsListStart());
  //yield delay(1000);

  try {
    const response = yield postRequest({
      API: `${URL.EVENT_LIST_WITH_ATTENDEES}`,
      DATA: {
        page: action.page,
        limit: action.limit
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(fetchEventsListPass(response.data.data));
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(fetchEventsListFail(response.data.msg));
      yield action.handleError(response.data.msg, false);
    }
  } catch (error) {
    yield put(fetchEventsListFail("Something went wrong"));
    yield action.handleError("Something went wrong", false);
  } finally {
  }
}

export function* watchEventsFetching() {
  yield takeLatest(FETCH_EVENTS_LIST_INITIATE, fetchEventsSaga);
}
