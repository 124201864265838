import React, { useState, useEffect } from "react";

import { Stat } from "./stat";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

const { CustomTable } = require(`components/${PLATFORM}/molecules/table`);

export const MyStatistics = ({
  data,
  loading,
  status,
  msg,
  fetchStats,
  showSnackBar
}) => {
  const setExpandList = useState(false)[1];

  useEffect(() => {
    fetchStats(
      () => undefined,
      msg => {
        showSnackBar(msg, "error");
      }
    );
  }, []);

  const toggleCollapsibleList = index => {
    data[index].isExpanded
      ? (data[index].isExpanded = false)
      : (data[index].isExpanded = true);
    setExpandList(value => !value);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h3 className="title-text">Statistics</h3>
          <div className="card border-0 shadow height-vh">
            <div className="card-body p-0">
              <CustomTable
                columns={[
                  { label: "Championship", isSortable: false },
                  { label: "Attempts", isSortable: false },
                  { label: "Percentage", isSortable: false },
                  { label: "Action", isSortable: false }
                ]}
                renderBody={() =>
                  data.map((item, idx) => (
                    <Stat
                      item={item}
                      key={idx}
                      toggleCollapsibleList={toggleCollapsibleList}
                      idx={idx}
                    />
                  ))
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
