import React from "react";
import ReactSelect from "react-select";

export const Select = ({
  label,
  asterisk,
  config,
  meta: { touched, error },
  options,
  widthStyle,
  input,
  isSearchable,
  placeholde,
  isDisabled
}) => {
  widthStyle = widthStyle ? widthStyle : "col-md-6";
  const validationSpan =
    touched && error ? (
      <span className="error_msg text-danger">{error}</span>
    ) : null;

  return (
    <div className={widthStyle}>
      {label && (
        <label>
          {label}
          {asterisk && <sup className="sup_error text-danger">*</sup>}
        </label>
      )}

      <div className="form-group">
        <ReactSelect
          isDisabled={isDisabled}
          {...input}
          {...config}
          options={options}
          isSearchable={isSearchable}
          placeholder={placeholde}
          onChange={input.onChange}
          onBlur={event => event.preventDefault()}
        />
        {validationSpan}
      </div>
    </div>
  );
};
