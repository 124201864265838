import { put, takeLatest } from "redux-saga/effects";
import {
  fetchEventDetailsStart,
  fetchEventDetailsPass,
  fetchEventDetailsFail,
  FETCH_EVENT_DETAILS_INITIATE,
  logoutInitiate
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* getEventDetailsSaga(action) {
  yield put(fetchEventDetailsStart());
  try {
    const response = yield postRequest({
      API: `${URL.TEST_REQUEST}`,
      DATA: {
        championshipId: action.championshipId
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(fetchEventDetailsPass(response.data.data));
      yield action.handleSuccess();
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(fetchEventDetailsFail(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(fetchEventDetailsFail("Something went wrong"));
    yield action.handleError();
  } finally {
  }
}

export function* watchEventDetailsFetching() {
  yield takeLatest(FETCH_EVENT_DETAILS_INITIATE, getEventDetailsSaga);
}
