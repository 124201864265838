import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./styles.scss";

import { Test } from "./test";
import { Modal } from "components/sef/atoms/modal";
import { INSTRUCTION_CHECKBOX } from "shared/sef/constants";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  retryTestRequestInitiate
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

export const TestsList = ({
  fetchTestsList,
  testsListLoading,
  testsListMsg,
  testsListData,
  showSnackBar,
  showPopup,
  showCompleteYourDetailsDialog,
  getTestDocument
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const retryRequestLoading = useSelector(
    state => state.retryTestRequest.loading
  );
  const loading = useSelector(state => state.startTestRequest.loading);

  const userId = useSelector(state => state.common.id);
  const isProfileCompleted = useSelector(
    (state) => state.common.isProfileCompleted
  );
  const userData = useSelector(state => state?.userInfo?.data)
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState([])
  useEffect(() => {
    fetchTestsList(
      () => {},
      msg => {
        showSnackBar(msg, "error");
      }
    );
  }, []);
  const onTestClicked = (
    championshipId,
    championshipName,
    testInstructions,
    blockStatus
  ) => {
    let neededDocument = []
    getTestDocument(championshipId,(data, message)=> {
      neededDocument = data?.missingDocuments
      if(data?.missingDocuments?.length){
        setShowUploadDocumentModal(neededDocument)
      }else {
        if (!isProfileCompleted) {
         showCompleteYourDetailsDialog()
       }
       else {
         if (blockStatus === 1) {
         history.push({
           pathname: `/sef-tests/${championshipId}`,
           state: {
             championshipName: championshipName,
             testInstructions: testInstructions
           }
         });
       } else if (blockStatus === 2) {
         showPopup(
           true,
           "You are blocked by the race director to attemp test. Would you like send unblock request?",
           "Unblock Request",
           "Confirm",
           "Cancel",
           () =>
             dispatch(
               retryTestRequestInitiate(
                 championshipId,
                 msg => showSnackBar(msg, "success"),
                 msg => showSnackBar(msg, "error")
               )
             ),
   
           () => undefined,
           true
         );
       } else if (blockStatus === 3) {
         showSnackBar(
           "Your retry request has already been sent to Team SEF. Please wait till it is approved",
           "error"
         );
       }
       }
      }
    }, msg => {
        // showSnackBar(msg, "error");
    })
  };

  const onCloseDocumentModal = () => {
    setShowUploadDocumentModal([])
  }
  console.log(showUploadDocumentModal,"showUploadDocumentModal");
  const onClickContinue = () => {
    history.push('/documents',{
      documentNeeded: showUploadDocumentModal
    })
  }

  return (
    <>
      {testsListLoading || retryRequestLoading || loading ? (
        <Loader />
      ) : (
        <>
          {testsListData.length > 0 && (
            <p className="mb-1">
              We have selected these tests for your upcoming championships.
            </p>
          )}
          <div className="card border-0 shadow ">
            <div className="card-body">
              {testsListData.length > 0 ? (
                <h4 className="card-title">Tests</h4>
              ) : (
                <p>No tests are available</p>
              )}

              <div className="row test-blocks">
                {testsListData.map((item, idx) => (
                  <Test key={idx} item={item} onTestClicked={onTestClicked} getTestDocument={getTestDocument} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        modalVisibility={showUploadDocumentModal.length}
        toggleDialogModal={()=> onCloseDocumentModal()}
        onRejection={()=> onCloseDocumentModal()}
        newClass={"document-modal"}
      >
         
        <div className="modal-body p-4 text-center">
          <div className="text-center">
            <h2 class="h3">Document to Upload</h2>
            <span>Please submit below documents to take this test.</span>
          </div>

          <div className="row pt-4 justify-content-center">{
              INSTRUCTION_CHECKBOX.map(item => (
                showUploadDocumentModal?.includes(item.value) && <span className="col-md-3 mt-2">{item.label}</span>
              ))
            }</div>
            </div>
            <div className="modal-footer">
              <div className="btn_group justify-content-center w-100">
              <button className="btn btn-lg btn-secondary" onClick={()=> onCloseDocumentModal()}>Cancel</button>
              <button className="btn btn-lg btn-primary" onClick={()=> onClickContinue()}>continue</button>
            </div>
            </div>
        
      </Modal>
    </>
  );
};
