export const STRINGS = {
  NEXT_BUTTON_TEXT: "CONTINUE",

  FIRST_NAME_KEY: "firstName",
  FIRST_NAME_LABEL: "First Name",
  FIRST_NAME_PLACEHOLDER: "Enter your first name",

  LAST_NAME_KEY: "lastName",
  LAST_NAME_LABEL: "Last Name",
  LAST_NAME_PLACEHOLDER: "Enter your last name",

  EMAIL_KEY: "email",
  EMAIL_LABEL: "Email",
  EMAIL_PLACEHOLDER: "E.g. antonio@gmail.com",

  PASSWORD_KEY: "password",
  PASSWORD_LABEL: "Password",
  PASSWORD_PLACEHOLDER: "Password",

  NATIONALITY_KEY: "nationality",
  NATIONALITY_LABEL: "Nationality",
  NATIONALITY_PLACEHOLDER: "Select",

  CITY_KEY: "city",
  CITY_LABEL: "City",
  CITY_PLACEHOLDER: "Enter City name",

  HOUSE_NO_KEY: "hno",
  HOUSE_NO_LABEL: "House No.",
  HOUSE_NO_PLACEHOLDER: "Enter house no.",

  STREET_KEY: "street",
  STREET_LABEL: "Street",
  STREET_PLACEHOLDER: "Enter street",

  POSTAL_CODE_KEY: "postalCode",
  POSTAL_CODE_LABEL: "Postal Code",
  POSTAL_CODE_PLACEHOLDER: "Enter postal code",

  BIRTH_DATE_KEY: "dob",
  BIRTH_DATE_LABEL: "D.O.B (Date of Birth)",
  BIRTH_DATE_PLACEHOLDER: "",

  CHAMPIONSHIP_KEY: 'championship',
  CHAMPIONSHIP_LABEL: "I am a part of championship (Championship name)",
  CHAMPIONSHIP_PLACEHOLDER: "Enter championship name",

  PHONE_NUMBER_KEY: "phone",
  PHONE_NUMBER_LABEL: "Phone Number",
  PHONE_NUMBER_PLACEHOLDER: "Enter phone number",

  PHONE_CODE_KEY: "code",

  USER_TYPE_KEY: "role",
  USER_TYPE_LABEL: "User Type",
  USER_TYPE_PLACEHOLDER: "Select",

  OTHER_USER_TYPE_KEY: 'otherUserType',
  OTHER_USER_TYPE_LABEL: "I am a (User type)",
  OTHER_USER_TYPE_PLACEHOLDER: 'Enter user type',

  TERMS_CONDITIONS_CHECKBOX_NAME: "agree",
  TERMS_CONDITIONS_CHECKBOX_TEXT: "I agree to the SEF Race Control Terms of Use",

  SEF_UPDATES_SUBSCRIPTION_NAME: 'isConsent',
  SEF_UPDATES_SUBSCRIPTION_TEXT: 'Subscribe for all SEF updates',

  SEF_PRIVACY_NOTICE_NAME: 'privacyNotice',
  SEF_PRIVACY_NOTICE_TEXT: 'I agree to the processing of my personal data in accordance with the SEF Privacy Notice'
};

export const VALIDATION_MESSAGES = {
  FIRST_NAME_REQUIRED: "First Name is required",
  LAST_NAME_REQUIRED: 'Last Name is required',
  NAME_INVALID: "Name cannot have digits or special characters (except period)",
  EMAIL_REQUIRED: "Email is required",
  EMAIL_INVALID: "Email is invaid",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_INVALID: "Password is weak",
  NATIONALITY_REQUIRED: "Nationality is required",
  CITY_REQUIRED: "City is required",
  HOUSE_NO_REQUIRED: "House no. is required",
  STREET_REQUIRED: 'Street is required',
  POSTAL_CODE_REQUIRED: 'Postal code is required',
  CHAMPIONSHIP_REQUIRED: 'Championship is required',
  BIRTH_DATE_REQUIRED: "Date of birth is required",
  PHONE_NUMBER_REQUIRED: "Phone number is required",
  USER_TYPE_REQUIRED: "User type is required",
  PHONE_NUMBER_CODE_REQUIRED: "Phone code is required",
  BIRTH_DATE_INVALID: "Age must be greater than 18",
  PHONE_NUMBER_MIN_LENGTH: "Phone number cannot be less than 6 digits",
  AGREE_TO_TERMS_CONDITIONS_REQUIRED: "Please accept the Terms and Conditions",
  AGREE_TO_PRIVACY_NOTICE_REQUIRED: 'Please accept the Privacy Notice'
};
