import React from "react";

const Heading = ({ text, style, headingType }) => {
  switch (headingType) {
    case 3: {
      return (
        <h3 className={style ? style : "font-weight-bold mb-3"}>{text}</h3>
      );
    }
    case 6: {
      return <h6 className={style ? style : "mb-5"}>{text}</h6>;
    }
    default: {
      return (
        <h4 className="font-weight-regular text-uppercase mt-4 mb-3">{text}</h4>
      );
    }
  }
};

export default Heading;
