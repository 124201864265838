import React from "react";
import { useHistory } from "react-router-dom";

import { CustomTabs } from "../tab-drawer";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

// tab value 0 for login 1 for signup
export const LoginSignupTab = ({ tabValue }) => {
  const history = useHistory();
  return (
    <CustomTabs
      tabsItems={[{ label: "LOG IN" }, { label: "SIGN UP" }]}
      value={tabValue}
      handleTabChange={val =>
        val ? history.replace(ROUTES.SIGNUP) : history.replace(ROUTES.LOGIN)
      }
      tabContainerStyle={""}
      activeTabStyle={""}
      inActiveTabStyle={""}
    ></CustomTabs>
  );
};
