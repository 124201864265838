import React from "react";
import Select from "@material-ui/core/Select";
import "react-dropdown/style.css";

import "./styles.scss";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { CustomPagination } = require(`components/${PLATFORM}/atoms/pagination`);

const LIMIT_OPTIONS = [
  {
    label: 5,
    value: 5
  },
  {
    label: 10,
    value: 10
  },
  {
    label: 15,
    value: 15
  },
  {
    label: 20,
    value: 20
  },
  {
    label: 25,
    value: 25
  }
];

export const PaginationBar = ({
  currentPage,
  totalPages,
  onPageChange,
  limit,
  itemsCount,
  onLimitChange,
  className,
  disable = false,
  placeholder = "Select"
}) => {
  return (
    <div className="text-center px-0 px-sm-3">
      <div className="pagi-list d-inline-flex flex-column flex-md-row align-items-center">
        <div className={"drop-down arrow_after"}>
          <label> No. of Rows</label>
          <Select
            native
            disabled={disable}
            value={limit}
            variant="outlined"
            className={className}
            size="medium"
            onChange={onLimitChange}
            inputProps={{
              placeholder: placeholder
            }}
          >
            {LIMIT_OPTIONS.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </div>
        {totalPages !== itemsCount && (
          <CustomPagination
            limit={limit}
            currentPage={currentPage}
            totalPages={totalPages}
            itemsCount={itemsCount}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};
