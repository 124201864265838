export const FETCH_EVENT_DETAILS_INITIATE = "FETCH_EVENT_DETAILS_INITIATE";
export const FETCH_EVENT_DETAILS_START = "FETCH_EVENT_DETAILS_START";
export const FETCH_EVENT_DETAILS_PASS = "FETCH_EVENT_DETAILS_PASS";
export const FETCH_EVENT_DETAILS_FAIL = "FETCH_EVENT_DETAILS_FAIL";
export const SET_EVENT_DETAILS = "SET_EVENT_DETAILS";
export const UPDATE_EVENT_DETAILS = "UPDATE_EVENT_DETAILS";

export const fetchEventDetailsInitiate = (
  eventId,
  handleSuccess,
  handleError
) => {
  return {
    type: FETCH_EVENT_DETAILS_INITIATE,
    eventId: eventId,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const fetchEventDetailsStart = () => {
  return {
    type: FETCH_EVENT_DETAILS_START
  };
};

export const fetchEventDetailsPass = (data, passMsg) => {
  return {
    type: FETCH_EVENT_DETAILS_PASS,
    data: data,
    msg: passMsg
  };
};
export const fetchEventDetailsFail = errorMsg => {
  return {
    type: FETCH_EVENT_DETAILS_FAIL,
    msg: errorMsg
  };
};

export const setEventDetails = data => {
  return {
    type: SET_EVENT_DETAILS,
    data: data
  };
};

export const updateEventDetails = data => {
  return {
    type: UPDATE_EVENT_DETAILS,
    data: data
  };
};
