import React from "react";

export const Input = ({
  value,
  input,
  asterisk,
  label,
  meta: { touched, error },
  config,
  widthStyle
}) => {
  widthStyle = widthStyle ? widthStyle : "col-md-6";
  const validationSpan =
    touched && error ? (
      <span className="error_msg text-danger">{error}</span>
    ) : null;
  return (
    <div className={widthStyle}>
      <div className="form-group">
        {label && (
          <label>
            {label}
            {asterisk && <sup className="sup_error text-danger">*</sup>}
          </label>
        )}
        <input className="form-control" {...input} {...config} />
        {validationSpan}
      </div>
    </div>
  );
};
