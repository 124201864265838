import { STRINGS } from "./strings";

import { ROUTES } from "./routes";

import {
  HOME_ICON,
  HOME_SELECTED,
  EVENT_ICON,
  EVENT_SELECTED,
  SEF_TESTS_ICON,
  SEF_TESTS_SELECTED,
  CHAT_ICON,
  CHAT_SELECTED,
  STATISTICS_ICON,
  STATISTICS_SELECTED,
  COOKIE_POLICY_ICON,
} from "./images";

export const USER_ROLE_ITEMS = [
  // {
  //   label: "All",
  //   value: 1
  // },
  {
    label: "Driver",
    value: 2
  },
  {
    label: "Pitcrew",
    value: 3
  },
  {
    label: "Marshal",
    value: 4
  },
  {
    label: "Media",
    value: 5
  },
  {
    label: "Security",
    value: 6
  }
];

export const FORMS = {
  PERSONAL: 0,
  MEDICAL: 1,
  VEHICLE: 2,
  FANS: 3,
  OTHERS: 4
};

export const USER_ROLE_KEYS = {
  Driver: 2,
  Pitcrew: 3,
  Marshal: 4,
  Media: 5,
  Security: 6
};

export const USER_TYPE = {
  NONE: "NONE",
  DRIVER: "Driver",
  PITCREW: "Pitcrew",
  MARSHAL: "Marshal",
  MEDIA: "Media",
  SECURITY: "Security"
};

export const FORM_INFO_OF_EACH_USER_TYPE = {
  NONE: {
    FORM_HEADINGS: ["Personal Details", "Medical Details", "Vehicle Details"]
  },
  Driver: {
    FORM_HEADINGS: ["Personal Details", "Medical Details", "Vehicle Details"],
    FORMS: [FORMS.PERSONAL, FORMS.MEDICAL, FORMS.VEHICLE]
  },
  "Pit crew": {
    FORM_HEADINGS: ["Personal Details", "Medical Details", "Vehicle Details"],
    FORMS: [FORMS.PERSONAL, FORMS.MEDICAL, FORMS.VEHICLE]
  },
  Marshal: {
    FORM_HEADINGS: ["Personal Details", "Medical Details", "Vehicle Details"],
    FORMS: [FORMS.PERSONAL, FORMS.MEDICAL, FORMS.VEHICLE]
  },
  Media: {
    FORM_HEADINGS: ["Personal Details", "Medical Details", "Vehicle Details"],
    FORMS: [FORMS.PERSONAL, FORMS.MEDICAL, FORMS.VEHICLE]
  },
  Security: {
    FORM_HEADINGS: ["Personal Details", "Medical Details", "Vehicle Details"],
    FORMS: [FORMS.PERSONAL, FORMS.MEDICAL, FORMS.VEHICLE]
  }
};

export const STATUS_CODE = {
  SUCCESSFUL: 200,
  UNAUTHORIZED: 401
};

export const REGEX = {
  // eslint-disable-next-line no-useless-escape
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD_REGEX: "",
  NAME_REGX: /^[A-Z.a-z ]+$/,
  // eslint-disable-next-line no-useless-escape
  ALPHA_NUMERIC_REGEX: /^[a-zA-Z0-9\.]*$/
};

export const DRAWER_ITEMS = [
  {
    tag: STRINGS.NEWS_FEED,
    alt: "drawer-icon",
    routeUrl: ROUTES.NEWS_FEED,
    selectedIcon: HOME_SELECTED,
    unSelectedIcon: HOME_ICON
  },
  {
    tag: STRINGS.EVENTS,
    alt: "drawer-icon",
    routeUrl: ROUTES.EVENTS,
    selectedIcon: EVENT_SELECTED,
    unSelectedIcon: EVENT_ICON
  },
  {
    tag: STRINGS.SEF_TESTS,
    alt: "drawer-icon",
    routeUrl: ROUTES.SEF_TESTS,
    selectedIcon: SEF_TESTS_SELECTED,
    unSelectedIcon: SEF_TESTS_ICON
  },
  {
    tag: STRINGS.GROUP_CHAT,
    alt: "drawer-icon",
    routeUrl: ROUTES.GROUP_CHAT,
    selectedIcon: CHAT_SELECTED,
    unSelectedIcon: CHAT_ICON
  },
  {
    tag: STRINGS.MY_STATISTICS,
    alt: "drawer-icon",
    routeUrl: ROUTES.MY_STATISTICS,
    selectedIcon: STATISTICS_SELECTED,
    unSelectedIcon: STATISTICS_ICON
  },


  // {
  //   tag: STRINGS.COOKIES_POLICY,
  //   alt: 'drawer-icon',
  //   routeUrl: ROUTES.COOKIES_POLICY,
  //   selectedIcon: COOKIE_POLICY_ICON,
  //   unSelectedIcon: COOKIE_POLICY_ICON
  // }
];

export const INSTRUCTION_CHECKBOX = [
  { label: "Driving Licence" , value:2, frontUrl: "", backUrl: ""},
  { label: "Insurance" , value:3, frontUrl: "", backUrl: ""},
  { label: "Passport" , value:1, frontUrl: "", backUrl: ""},
  { label: "Covid Test" , value:4, frontUrl: "", backUrl: ""},
  { label: "Additional Data" , value:5, frontUrl: "", backUrl: ""},
]
