import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import "./style.scss";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const MultipleSelect = ({
  dataItems = [],
  labelText,
  dropDataSet = () => {}
}) => {
  const classes = useStyles();
  // const [userTypes, setUserType] = useState([dataItems[0].label]);
  // const [userTypes, setUserType] = useState(dataItems.map(item => item.label));
  // const [selectedValue, setSelectedValues] = useState([dataItems[0].value]);
  const [userTypes, setUserType] = useState([{ label: "All" }]);
  const [selectedValue, setSelectedValues] = useState([1]);

  const handleChangeMultiple = event => {
    let selectedValues = event.target.value;
    console.log("selected values", selectedValues);
    let keys = [];
    let labels = [];
    for (let i = 0; i < selectedValues.length; ++i) {
      keys.push(selectedValues[i].value);
      labels.push(selectedValues[i].label);
    }
    if (labels.includes("All")) {
      selectedValues.shift();
      setUserType(selectedValues);
      keys.shift();
    } else if (labels.length === 0) {
      setUserType([{ label: "All" }]);
    } else {
      setUserType(selectedValues);
    }
    setSelectedValues(keys);
    dropDataSet(keys);

    // if (event.target.value.length === 0) {
    //   setUserType(["All"]);
    // } else {
    //   setUserType(labels.filter(user => user !== "All"));
    // }
    // let temp = [...userTypes];
    // let tempValues = [...selectedValue];
    // let value = event.target.value[event.target.value.length - 1];
    // if (userTypes.includes(dataItems[value - 2].label)) {
    //   temp.splice(temp.indexOf(dataItems[value - 2].label), 1);
    //   tempValues.splice(tempValues.indexOf(dataItems[value - 2].value), 1);
    // } else {
    //   temp.push(dataItems[value - 2].label);
    //   tempValues.push(dataItems[value - 2].value);
    // }
    // if (temp.includes("All")) {
    //   temp = ["All", "Driver", "Pitcrew", "Marshal", "Media", "Security"];
    // }
    // if (tempValues.includes(1)) {
    //   tempValues = [1, 2, 3, 4, 5, 6];
    //}
    // if (temp.includes("All")) temp.shift();
    // setUserType(temp);
    // setSelectedValues(tempValues);
    // // dropDataSet(tempValues.includes(1) ? [] : tempValues);
    // if (tempValues[0] === 1) tempValues.shift();
    // dropDataSet(tempValues);
  };

  return (
    <div className={"drop-down arrow_after"}>
      <label>{labelText}</label>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={userTypes}
          onChange={handleChangeMultiple}
          input={<Input />}
          renderValue={selected => selected.map(item => item.label).join(",")}
          // renderValue={selected => {
          //   console.log(selected);
          //   if (selected.includes("All") && selected.length === 1) {
          //     return "All";
          //   } else return selected.join(", ");
          // }}
          // renderValue={selected => {
          //   console.log("selected", selected);
          //   // if (selected.includes("All") && selected.length === 1) {
          //   //   console.log("here");
          //   //   return "All";
          //   // }
          //   if (selected.length === 0) {
          //     console.log("here");
          //     return "All";
          //   } else {
          //     return selected.join(", ");
          //   }
          // }}
          MenuProps={MenuProps}
        >
          {dataItems.map((item, index) => {
            return (
              <MenuItem
                key={item.label}
                value={item}
                // disabled={item.label !== "All" && userTypes.includes("All")}
              >
                <Checkbox checked={selectedValue.includes(item.value)} />
                <ListItemText primary={item.label} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
