export const LOGIN_INITIATE = "LOGIN_INITIATE";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_PASS = "LOGIN_PASS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const loginInitiate = (loginDetails, handleSuccess, handleError) => {
  return {
    type: LOGIN_INITIATE,
    email: loginDetails.email,
    password: loginDetails.password,
    rememberMe: loginDetails.persistAuth,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const loginStart = () => {
  return {
    type: LOGIN_START
  };
};

export const loginPass = passMsg => {
  return {
    type: LOGIN_PASS,
    msg: passMsg
  };
};
export const loginFail = errorMsg => {
  return {
    type: LOGIN_FAIL,
    msg: errorMsg
  };
};
