import React from "react";

export const CollapsibleList = ({ test, idx }) => {
  return (
    <div className="col-md-12">
      <span> {`Attempt ${idx + 1}`}</span>
      <span> {`${test.percentage}%`}</span>
    </div>
  );
};
