import { all, fork } from "redux-saga/effects";

import { watchLogin } from "./login";
import { wathchSignup } from "./signup";
import { watchForgotPassword } from "./forgot-password";
import { watchformDataFetching } from "./fetch-form-data";
import { watchLogout } from "./logout";
import { watchTestsListFetching } from "./fetch-tests-list";
import { watchStartTestRequest } from "./start-test";
import { watchSubmitTestRequest } from "./submit-test";
import { watchUserInfoFetching } from "./get-user-info";
import { wathchUpdateUserInfo } from "./update-user-info";
import { watchStatsFetching } from "./fetch-stats";
import { watchEventsFetching } from "./fetch-events";
import { watchEventDetailsFetching } from "./fetch-event-details";
import { watchEventAttendeesFetching } from "./fetch-event-attendees";
import { watchEventChecking } from "./event-check-in";
import { watchRetryTestRequestRequest } from "./retry-test-request";

export function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(wathchSignup),
    fork(watchForgotPassword),
    fork(watchformDataFetching),
    fork(watchLogout),
    fork(watchTestsListFetching),
    fork(watchStartTestRequest),
    fork(watchSubmitTestRequest),
    fork(watchUserInfoFetching),
    fork(wathchUpdateUserInfo),
    fork(watchStatsFetching),
    fork(watchEventsFetching),
    fork(watchEventDetailsFetching),
    fork(watchEventAttendeesFetching),
    fork(watchEventChecking),
    fork(watchRetryTestRequestRequest)
  ]);
}
