module.exports = `<style>
	.privacy_notice {margin:20px 0; }
	.privacy_notice p{font-size: 16px;}
	.privacy_notice .top_head{width: 100%; float: left; text-align: center; margin-bottom: 1rem;}
	.privacy_notice .top_head h1{line-height: 30px; font-size: 1.8rem;}
	.privacy_notice li, .privacy_notice p{line-height: 30px; width: 100%; float: left;}
	.privacy_notice ul {list-style-type: circle; padding-top: 0.6em;}
	.privacy_notice ul > li {display: table; margin: 0.4em 0; position: relative; padding-left: 25px;}
	.privacy_notice h4, .privacy_notice h2{ display:block; margin: 0.6em 0;}
	.privacy_notice ul > li::before {content: ""; position: absolute; left: 0; padding:5px; border-radius: 50px;top: 7px; background: #000;}
	.privacy_notice a{color: #f22736;font-weight: #700;}
	@media(max-width: 768px){
		.privacy_notice .top_head h1{font-size: 1.2rem;}
	}
	</style>

<div class="privacy_notice">
	<div class="container">
		<div class="row">
			<div class="top_head">
				<h1>SEF RACE CONTROL MOBILE APPLICATION PRIVACY NOTICE </h1>
			</div>
			<p>SEF Racing Limited (<strong>“SEF”</strong>) is a nonprofit organization focused on raising safety awareness in motorsport. </p> 
			<p>In this Privacy Notice, the <strong>“Company” “we”, or“our”</strong> means:</p>
			<p>SEF Racing Limited, a limited company registered in England and Wales under company number 11341871 whose registered office is at 239 Kensington High Street 1st Floor, London, England, W8 6SN. </p>
			<p>SEF respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data when you install the SEF mobile application (the “App”) and tells you about your privacy rights and how the law protects you.</p>
			<h4>CONSENT TO INSTALLATION OF THE APP</h4>
			<p>Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data. This information is provided in our <a href="https://sefracing.com/privacy-policy">Privacy Policy</a> and <a href="https://sefracing.com/privacy-policy">Terms of Use</a> and it is important that you read that information.</p>
			<p>By installing this App and responding “Yes”, you provide your consent to our processing of your personal data (including your name, contact details, financial and device information) as further described in the Privacy Policy</p>
			<p><strong>YES</strong> I consent to the installation of the App for the purposes of accessing the SEF portal (the “Portal”)</p>
			<p><strong>NO</strong> I do not consent to the installation of the App. </p>
			<h4>HOW YOU CAN WITHDRAW CONSENT</h4>
			<p>Once you provide consent by selecting "YES", you may change your mind and withdraw consent at any time by contacting us at <a href="mailto:pa@seanedwardsfoundation.com">pa@seanedwardsfoundation.com</a> but that will not affect the lawfulness of any processing carried out before you withdraw your consent. </p>
			<h4>CONSENT TO PROCESSING LOCATION DATA </h4>
			<p><strong>YES</strong> I consent to processing of my Location Data ([including details of my current location disclosed by GPS technology) so that location-enabled services are activated to provide access to the most relevant information on the Portal.</p>
			<p><strong>NO </strong>I do not consent to processing of my Location Data and location-enabled services are disabled in my settings.</p>
			<h4>INTRODUCTION </h4>
			<p>This policy together with our Privacy Policy and Terms of Use applies to your use of:
				<ul>
					<li>the SEF App, once you have downloaded or streamed a copy of the App onto your mobile telephone or handheld device (the “Device”).</li>
					<li>Any of the services accessible through the App (the “Services”)  that are available on the App or the Portal or any of our other sites (each, a “Site”), unless the Terms of Use states that a separate privacy policy applies to a particular Service, in which case that privacy policy only applies. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. </li>
					<li>This App is not intended for children and we do not knowingly collect data relating to children. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.</li>
				</ul>
			</p>
			<h4>IMPORTANT INFORMATION AND WHO WE ARE</h4>
			<p>SEF Racing Limited is the controller and is responsible for your personal data. 
			We have appointed a data privacy manager. If you have any questions about the Privacy Policy, please contact them using the details set out below.</p>
			<h4>Contact details</h4>
			<p>Our full details are:
				<ul>
					<li>Full name of legal entity: SEF Racing Limited </li>
					<li>Name of the data privacy manager: Lyndsey Cook </li>
					<li>Email address: <a href="mailto:pa@seanedwardsfoundation.com">pa@seanedwardsfoundation.com</a></li>
					<li>Postal address:  96 Kensington High Street, London W8 4SG, United Kingdom.</li>
				</ul>
			</p>
			<p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues or other competent supervisory authority of an EU member state if the App is downloaded outside the United Kingdom.</p>
			<h4>CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES</h4>
			<p>We keep our Privacy Notice and Privacy Policy under regular review. </p>
			<p>This version was last updated on 9 June 2020 and It may change and if it does, these changes will be posted on this page and, where appropriate, notified to you when you next start the App. The new policy may be displayed on-screen and you may be required to read and accept the changes to continue your use of the App or the Services.</p>
			<p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during our relationship with you. </p>
			<h4>THIRD PARTY LINKS</h4>
			<p>Our Sites may, from time to time, contain links to and from the websites of our partner networks, advertisers and sponsors. Please note that these websites and any services that may be accessible through them have their own privacy policies and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as Contact and Location Data. </p>
			<p>Please check these policies before you submit any personal data to these websites or use these services.</p>
			<p>For full details of the data we collect about you, how it is used and collected, and your rights in relation to such data, please refer to our <a href="https://sefracing.com/privacy-policy">Privacy Policy</a> </p>
		</div>
	</div>
</div>`;
