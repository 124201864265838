import React, { useCallback, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";

import { useFormDataFetching } from "hooks/fetch-form-data";

import "./styles.scss";

import { usePagination } from "hooks/pagination";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  SnackbarWrapper
} = require(`components/${PLATFORM}/molecules/snackbar-wrapper`);
const { Loader } = require(`components/${PLATFORM}/atoms/loader`);
const { MultipleSelect } = require(`components/${PLATFORM}/cells/multi-select`);
const {
  PaginationBar
} = require(`components/${PLATFORM}/molecules/pagination-bar`);

const { USER_ROLE_ITEMS } = require(`shared/${PLATFORM}/constants`);

export const EventAttendees = ({
  attendees,
  getAttendees,
  attendeesMetaData,
  attendeesLoading,
  attendeesMsg
}) => {
  const { id } = useParams();
  const history = useHistory();

  const [filteredRoleKeys, setFilteredRoleKeys] = useState([]);
  const [
    currentPage,
    limit,
    setCurrentPage,
    onPageChange,
    onLimitChange
  ] = usePagination(1, 5);
  const userId = useSelector(state => state.common.id);
  const isFetchingUserRoles = useSelector(state => state.getFormData.loading);
  const { mainUserTypes } = useFormDataFetching();

  const getEventAttendees = useCallback(() => {
    getAttendees(
      id,
      currentPage,
      limit,
      filteredRoleKeys,
      () => "handle success",
      () => "handle error"
    );
  }, [id, currentPage, limit, filteredRoleKeys, getAttendees]);

  useEffect(() => getEventAttendees(), [getEventAttendees]);

  return (
    <>
      {attendeesLoading || isFetchingUserRoles ? <Loader /> : null}
      
        <>
          <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
            <h5>
              <label onClick={() => history.goBack()}>Description</label>{" "}
              <span>Attendees</span>
            </h5>
          </div>

          <div className="card border-0 shadow height-vh ">
            <div className="card-body p-0">
              <div className="table_filter">
                <div className="d-flex justify-content-between table-listing">
                  <MultipleSelect
                    labelText={"User Type: "}
                    withCheckbox={true}
                    dataItems={mainUserTypes}
                    dropDataSet={values => {
                      setFilteredRoleKeys(values);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              {attendees.length !== 0 && (
                <div className="table-responsive comon-table">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Role</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attendees.map((attendee, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              <i className="mr-2">
                                <img
                                  src={attendee.profileUrl}
                                  width="60"
                                  className="img-xs rounded-circle"
                                  alt="avatar"
                                />
                              </i>
                              <span className="user_name">
                                {attendee.name}
                                {userId === attendee._id ? "(You)" : null}
                              </span>
                            </TableCell>
                            <TableCell>
                              {
                                mainUserTypes.find(
                                  (role, idx) => role.value === attendee.role
                                )?.label
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              {attendees.length === 0 && (
                <p className="text-center">No Attendees Found</p>
              )}
              {attendees.length !== 0 && (
                <PaginationBar
                  currentPage={currentPage}
                  totalPages={attendeesMetaData.total}
                  onPageChange={onPageChange}
                  limit={limit}
                  itemsCount={attendees.length}
                  onLimitChange={onLimitChange}
                />
              )}
            </div>
          </div>
        </>
      
    </>
  );
};
