import { connect } from "react-redux";

import { SEFTest } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  startTestInitiate,
  submitTestInitiate
} = require(`../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    startTestData: state.startTestRequest.data,
    testResult: state.submitTestRequest.data,
    testsListData: state.getTestsList.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitTest: (answers, handleSuccess, handleError) =>
      dispatch(submitTestInitiate(answers, handleSuccess, handleError))
  };
};

export const SEFTestScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(SEFTest);
