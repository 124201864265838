const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const getDate = timeStamp => {
  let stringDate = "";
  const dateObj = new Date(timeStamp);
  stringDate += dateObj.getDate() + "-";
  stringDate += MONTHS[dateObj.getMonth()] + "-";
  stringDate += dateObj.getFullYear();
  return stringDate;
};

export const getDataTime = timeStamp => {
  let stringDate = "";
  let stringTime = "";
  // let am_pm_string = " AM";
  const dateObj = new Date(timeStamp);
  let date = dateObj.getDate();
  date = date >= 10 ? date : "0" + date;
  let month = dateObj.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  stringDate += date + "-";
  // stringDate += MONTHS[dateObj.getMonth()] + "-";
  stringDate += month + "-";
  stringDate += dateObj.getFullYear();
  // if (dateObj.getHours() >= 12) {
  //   am_pm_string = " PM";
  // }

  // 12 hour format
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours >= 10 ? hours : "0" + hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  stringTime = hours + ":" + minutes + " " + ampm;
  // 12 hour format

  return {
    date: stringDate,
    time: stringTime
  };
};
