import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

const {
  defaultConfig: { PLATFORM, LOCATION, VERSION }
} = require("config/default");

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const {
  SignUpScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/signup`);
const {
  ForgotPasswordScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/forgot-password`);
const {
  LoginScreen
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/login`);
const {
  PrivacyPolicy
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/privacy-policy/`);
const { TermsAndConditions } = require(`views/sef/us/1.0/terms-and-conditions`);
const { PrivacyNotice } = require(`views/sef/us/1.0/privacy-notice`);
const { CookiesPolicy } = require(`views/sef/us/1.0/cookies-policy`);
const { AuthHoc } = require(`components/sef/hoc/auth`);

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.PRIVACY_POLICY}>
        <PrivacyPolicy />
      </Route>
      <Route path={ROUTES.LOGIN}>
        <AuthHoc>{(showPopup) => <LoginScreen showPopup={showPopup}/>}</AuthHoc>
      </Route>
      <Route path={ROUTES.SIGNUP} component={SignUpScreen} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
      <Route path={ROUTES.TERMS_CONDITIONS}>
        <TermsAndConditions/>
      </Route>
      <Route path={ROUTES.PRIVACY_NOTICE}>
        <PrivacyNotice/>
      </Route>
      <Route path={ROUTES.COOKIES_POLICY}>
        <CookiesPolicy/>
      </Route>
      <Redirect to={ROUTES.LOGIN} />
    </Switch>
  );
};
