import {
  RETRY_TEST_REQUEST_START,
  RETRY_TEST_REQUEST_PASS,
  RETRY_TEST_REQUEST_FAIL
} from "../actions";

const initialState = {
  loading: false
};

const retryTestRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRY_TEST_REQUEST_START: {
      return {
        ...state,
        loading: true
      };
    }

    case RETRY_TEST_REQUEST_PASS: {
      return {
        ...state,
        loading: false
      };
    }

    case RETRY_TEST_REQUEST_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default retryTestRequestReducer;
