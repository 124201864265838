export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  COMPLETE_PROFILE: "/complete-profile",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  NEWS_FEED: "/news-feed",
  EVENTS: "/events",
  EVENT: "/events/:id",
  EVENT_ATTENDEES: "/events/:id/attendees",
  SEF_TESTS: "/sef-tests",
  SEF_TEST: "/sef-tests/:id",
  GROUP_CHAT: "/group-chat",
  MY_STATISTICS: "/my-statistics",
  LOGOUT: "/logout",
  PROFILE: "/profile",
  TERMS_CONDITIONS: '/terms-and-conditions',
  PRIVACY_NOTICE: '/privacy-notice',
  COOKIES_POLICY: '/cookies-policy',
  DOCUMENTS: '/documents'
};

export const PAGE_TITLES = {
  LOGIN: "SEF LOGIN",
  SIGNUP: "SEF SIGNUP",
  COMPLETE_PROFILE: "SEF COMPLETE PROFILE",
  RESET_PASSWORD: "SEF RESET PASSWORD",
  FORGOT_PASSWORD: "SEF FORGOT PASSWORD",
  DASHBOARD: "SEF DASHBOARD",
  ABOUT_US: "SEF ABOUT US",
  CONTACT_US: "SEF CONTACT US",
  PRIVACY_POLICY: "SEF PRIVACY POLICY",
  NEWS_FEED: "SEF NEWS FEED",
  EVENTS: "SEF EVENTS",
  SEF_TESTS: "SEF TESTS",
  SEF_TEST: "SEF TEST",
  GROUP_CHAT: "SEF GROUP CHAT",
  MY_STATISTICS: "SEF MY STATISTICS",
  LOGOUT: "SEF LOGOUT",
  PROFILE: "SEF PROFILE"
};
