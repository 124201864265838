import React from "react";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { FORMS } = require(`shared/${PLATFORM}/constants`);

const { getCountryData } = require(`helpers/${PLATFORM}`);

const {
  PersonalInfoReduxForm
} = require(`components/${PLATFORM}/forms/personal-info`);
const {
  MedicalInfoReduxForm
} = require(`components/${PLATFORM}/forms/medical-info`);
const {
  VehicleInfoReduxForm
} = require(`components/${PLATFORM}/forms/vehicle-info`);

let data = getCountryData();
let callingCodes = data.callingCodes;
let countries = data.nations;

export const RenderForm = ({
  activeStep,
  disableRoleField,
  disableEmailField,
  hidePasswordField,
  initialValues,
  goToNextStep,
  goToPrevStep,
  userTypes,
  FIACategorisations,
  bloodGroups,
  relationships,
  finalButtonLabel,
  finalSubmitHandler,
  hideTermsConditions,
  hideSubscriptionUpdates,
  hidePrivacyNotice
}) => {
  return (
    <>
      {activeStep === 0 && (
        <PersonalInfoReduxForm
          disableRoleField={disableRoleField}
          disableEmailField={disableEmailField}
          hidePasswordField={hidePasswordField}
          initialValues={initialValues}
          onSubmit={goToNextStep}
          countries={countries}
          telephoneCodes={callingCodes}
          userTypes={userTypes}
          hideTermsConditions={hideTermsConditions}
          hideSubscriptionUpdates={hideSubscriptionUpdates}
          hidePrivacyNotice={hidePrivacyNotice}
        />
      )}
      {activeStep === 1 && (
        <MedicalInfoReduxForm
          onSubmit={goToNextStep}
          handleBack={goToPrevStep}
          bloodGroups={bloodGroups}
          telephoneCodes={callingCodes}
          relationships={relationships}
        />
      )}
      {activeStep === 2 && (
        <VehicleInfoReduxForm
          buttonLabel={finalButtonLabel}
          onSubmit={finalSubmitHandler}
          handleBack={goToPrevStep}
          FIACategorisation={FIACategorisations}
        />
      )}
    </>
  );
};
