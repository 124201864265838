import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { useLocation, Redirect } from "react-router-dom";

import { TestInstructionsScreen } from "./test-instructions";
import { TestScreen } from "./test-screen/screen";
import { TestResultScreen } from "./test-result";

export const SEFTest = ({
  startTestData,
  testsListData,
  submitTest,
  testResult,
  showSnackBar,
  showPopup,
  snackBarVisible
}) => {
  let location = useLocation();

  const [showTestInstructions, setShowTestInstructions] = useState(true);
  const [isTestStarted, startTest] = useState(false);
  const [showTestResult, setShowTestResult] = useState(false);

  const [timeSpent, setTimeSpent] = useState(null);

  const onTestInstructionsRead = () => {
    setShowTestInstructions(false);
    startTest(true);
  };

  const onTestOver = (answers, timeSpent, abnormalSubmit) => {
    setTimeSpent(timeSpent);
    submitTest(
      answers,
      () => {
        ReactDom.unstable_batchedUpdates(() => {
          if (!abnormalSubmit) {
            startTest(false);
            setShowTestResult(true);
          }
        });
      },
      () => {}
    );
  };

  const onRetry = () => {
    setShowTestResult(false);
    setShowTestInstructions(true);
  };

  return (
    <>
      {testsListData.length ? (
        <>
          {showTestInstructions && (
            <TestInstructionsScreen
              testInstructions={location.state.testInstructions}
              onTestInstructionsRead={onTestInstructionsRead}
              showSnackBar={showSnackBar}
            />
          )}
          {isTestStarted && (
            <TestScreen
              onTestOver={onTestOver}
              championshipName={location.state.championshipName}
              testTime={startTestData.testTime}
              testQuestions={startTestData.test}
              totalQuestions={startTestData.test.length}
              showSnackBar={showSnackBar}
              showPopup={showPopup}
              snackBarVisible={snackBarVisible}
            />
          )}
          {showTestResult && (
            <TestResultScreen
              timeSpent={timeSpent}
              onRetry={onRetry}
              testTime={startTestData.testTime}
              championshipName={location.state.championshipName}
              result={testResult}
              showPopup={showPopup}
              showSnackBar={showSnackBar}
            />
          )}
        </>
      ) : (
        <Redirect to="/sef-tests" />
      )}
    </>
  );
};
