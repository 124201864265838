import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { saveUploadDocument, uploadDocument } from 'redux/sef/actions';
import { INSTRUCTION_CHECKBOX } from "shared/sef/constants";
import { CustomFileDrop } from '../custom-filedrop';
import './style.scss';
import mime from 'mime-types'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Loader } from '../loader';
const { Modal } = require(`components/sef/atoms/modal`);

const Document = () => {
    const {state} = useLocation()
    const [showUpload, setShowUpload] = useState([]);
    const [showModal, setShowModal] = useState(null);
    const [imageUrl, setImageUrl] = useState({});
    const [showPreview, setShowPreview] = useState(null);
    const userData = useSelector(state => state?.userInfo?.data);
    const updateUserInfo = useSelector(state => state.updateUserInfo);
    const updateUserInfoLoader = updateUserInfo.loading;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=> {
        let dataToStore = []
        if(state?.documentNeeded?.length) {
            dataToStore = INSTRUCTION_CHECKBOX?.filter(item => state?.documentNeeded.includes(item.value))
        }else {
            dataToStore = INSTRUCTION_CHECKBOX
        }
        setShowUpload(dataToStore)
    },[userData])

    const uploadImage = (data, status, key) => {
        dispatch(uploadDocument(data.file,((data)=> {
            let update = { ...imageUrl }
            update[key] = data.data.url
            setImageUrl(update)
        }), ((err)=> {
            console.log(err,"fail");
        })))
    }

    const onClickSave = () => {
        let data = {
            "documents": []
        }
        let apiData = {};
        apiData['type'] = showModal.value
        imageUrl?.frontUrl && (apiData["frontUrl"] = imageUrl?.frontUrl);
        imageUrl?.backUrl && (apiData["backUrl"] = imageUrl?.backUrl );
        data.documents.push(apiData)
        dispatch(saveUploadDocument(data, (data) => {
            closeModal()
        },(err)=> {
            console.log("fail", err)
        }))
    }

    const closeModal = () => {
        setShowModal(null)
        setImageUrl({})
    }

    const onClickReplace = (value) => {
        let documentData = userData?.documents?.find((useData) => useData?.type === value);
        setShowModal(value)
    }

    return (
        <>
            {updateUserInfoLoader ? <Loader />: null}
        <div className='doc_block'>
            <div className='sef_block_title'>
                <h3 className='title-text mt-4 mb-0 d-flex align-items-center'>
                    <i className='mr-3'><img src={require('../../../../assets/images/icons/doc_icon.svg')} width="" alt="SEF" /></i>
                    {state?.documentNeeded.length ? "Replace Documents" : "Documents"}
                </h3>
            </div>
            <div className='row'>
                {showUpload?.map((item, index) => (
                    <div className='col-md-6'>
                        <div className='card sef_card'>
                            <div className='card_title'>
                                <h5>{item.label}</h5>
                                {userData?.documents?.findIndex((useData) => useData?.type === item.value) >= 0 ? 
                                <span className='f_upload' onClick={() => onClickReplace(item)}><i className='mr-2'><img src={require('../../../../assets/images/icons/upload_icon.svg')} alt="" /></i> Replace</span> : 
                                <span className='f_upload add_ico' onClick={() => setShowModal(item)}><i><img src={require('../../../../assets/images/icons/Plus_icon.svg')} alt="" /></i></span>}
                            </div>
                            <div className='docs_preview row'>

                                {
                                    userData?.documents?.length ? userData?.documents?.map((useData) => (
                                        useData?.type === item.value && <>

                                            {useData?.frontUrl &&
                                                 mime?.lookup(useData?.frontUrl?.split('.')[useData?.frontUrl?.split('.').length-1])?.split('/')[0] === 'image' ?
                                                <div className='col-sm-6'><figure><img src={useData?.frontUrl} className="img-fluid" alt="" /></figure>
                                                    <h6>Front</h6>
                                                </div>
                                                :
                                                <span onClick={() => setShowPreview(useData?.frontUrl)}>
                                                    <i className='mr-3'><img src={require('../../../../assets/icons/doc_icon.svg')} width="" alt="SEF" /></i>
                                                    {useData?.name || "Document"}
                                                    <h6>Front</h6>
                                                </span>}
                                            {useData?.backUrl &&
                                                <>
                                                {useData?.backUrl &&
                                                 mime?.lookup(useData?.backUrl?.split('.')[useData?.backUrl?.split('.').length-1])?.split('/')[0] === 'image' ?
                                                    <div className='col-sm-6'><figure><img src={useData?.backUrl} className="img-fluid" alt="" /></figure>
                                                        <h6>Back</h6>
                                                    </div>
                                                    : <span onClick={() => setShowPreview(useData?.backUrl)}>
                                                        <i className='mr-3'><img src={require('../../../../assets/icons/doc_icon.svg')} width="" alt="SEF" /></i>
                                                        {useData?.name || "Document"}
                                                        <h6>Back</h6>
                                                    </span>}
                                                    </>
                                                }
                                        </>

                                    )): null
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
                {
                state?.documentNeeded?.length ?
                <div className='btn_group mt-3 justify-content-end'>
                    <button className='btn btn-lg btn-primary' onClick={() => history.push("/sef-tests")}>Back</button>
                     <button
                        className={`btn btn-lg btn-primary ${!userData?.documents?.filter((documents) => state?.documentNeeded.includes(documents.type)).length && "disable"}`} onClick={() => history.push("/sef-tests")}>Confirm</button> 
                </div>
                : null
                }
            {/* <div>
            <button onClick={()=> onClickSave()} > save </button>
        </div> */}
            {<Modal
                modalVisibility={showModal}
                toggleDialogModal={() => closeModal()}
                onRejection={() => closeModal()}
                newClass="hello"
            >
                <div className='upload_docs_modal'>
                    <div className='upload_docs_body'>
                        <h3 className='h3 text-center'>
                        Upload Document
                        </h3>
                        <div className='drag_upload'>
                            <h6>{showModal?.label}</h6>
                            <div className='docs_preview row'>
                                <div className='col-md-6'>
                                    {imageUrl?.frontUrl  && 
                                    mime.lookup(imageUrl?.frontUrl?.split('.')[imageUrl?.frontUrl?.split('.').length-1])?.split('/')[0] === 'image'?
                                        <img className="img-fluid" src={imageUrl?.frontUrl} />
                                        : 
                                         imageUrl?.frontUrl && mime.lookup(imageUrl?.frontUrl?.split('.')[imageUrl?.frontUrl?.split('.').length-1])?.split('/')[0] !== 'image'?
                                          <span>
                                        <i className='mr-3'><img src={require('../../../../assets/icons/doc_icon.svg')} width="" alt="SEF" /></i>
                                        {/* {decodeURI(imageUrl?.frontUrl?.split("amazonaws.com/")[1])} */}
                                    </span>
                                        : <CustomFileDrop
                                            handleSubmit={(data, status) => uploadImage(data, status, "frontUrl")}
                                        />}
                                        <h6 class="text-center">Front</h6>
                                </div>
                                <div className='col-md-6'>
                                    {imageUrl?.backUrl && mime.lookup(imageUrl?.backUrl?.split('.')[imageUrl?.backUrl?.split('.').length-1])?.split('/')[0] === 'image'?
                                        <img className="img-fluid" src={imageUrl?.backUrl} />
                                        : 
                                         imageUrl?.backUrl && mime.lookup(imageUrl?.backUrl?.split('.')[imageUrl?.backUrl?.split('.').length-1])?.split('/')[0] !== 'image'?
                                          <span>
                                        <i className='mr-3'><img src={require('../../../../assets/icons/doc_icon.svg')} width="" alt="SEF" /></i>
                                        {/* {decodeURI(imageUrl?.backUrl?.split("amazonaws.com/")[1])} */}
                                        
                                    </span>
                                        : <CustomFileDrop
                                            handleSubmit={(data, status) => uploadImage(data, status, "backUrl")}
                                        />}
                                        <h6 class="text-center">Back</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer doc_footer'>
                        <div className='btn_group'>
                            <button className='btn btn-lg btn-primary' disabled={!Object?.values(imageUrl)?.length} onClick={() => onClickSave()}>Submit</button>
                            <button className='btn btn-lg btn-outline-secondary' onClick={() => closeModal()} >Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>}
            <Modal
                modalVisibility={showPreview?.length}
                toggleDialogModal={() => setShowPreview(null)}
            >
                <div>
                    <iframe src={showPreview} style={{ "width": 600, "height": 500 }} frameborder="0"></iframe>
                </div>
            </Modal>
        </div>
        </>
    )
}

export default Document;