import { connect } from "react-redux";

import { EventsListing } from "./screen.jsx";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  fetchEventsListInitiate,
  fetchEventDetailsInitiate,
  setEventDetails,
  eventCheckInInitiate,
  fetchEventsListReset,
  updateEventsList
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    eventsList: state.getEvents.data.data,
    eventListMetaData: state.getEvents.data.metaData,
    eventsListLoading: state.getEvents.loading,
    eventsListMsg: state.getEvents.msg,
    eventDetailsLoading: state.getEventDetails.loading,
    eventDetailsMsg: state.getEventDetails.msg,
    eventCheckInLoading: state.eventCheckIn.loading,
    eventCheckInStatus: state.eventCheckIn.status,
    eventCheckInMsg: state.eventCheckIn.msg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEventsList: (page, limit, handleSuccess, handleError) =>
      dispatch(
        fetchEventsListInitiate(page, limit, handleSuccess, handleError)
      ),
    getEventDetails: (eventId, handleSuccess, handleError) =>
      dispatch(fetchEventDetailsInitiate(eventId, handleSuccess, handleError)),
    setEventDetails: data => dispatch(setEventDetails(data)),
    checkIn: (eventId, handleSuccess, handleError) =>
      dispatch(eventCheckInInitiate(eventId, handleSuccess, handleError)),
    resetEventsList: () => dispatch(fetchEventsListReset()),
    updateEventsList: data => dispatch(updateEventsList(data))
  };
};

export const EventsListingScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsListing);
