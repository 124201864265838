import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import "./style.scss";

export const Modal = ({
  modalVisibility,
  toggleDialogModal = () => { },
  onRejection = () => { },
  children,
  newClass= "",
}) => {
  return (
    <>
      <Dialog
        open={modalVisibility}
        onClose={toggleDialogModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        class={`MuiDialog-root full-width ${newClass}`}
      >
        <IconButton aria-label="close" onClick={onRejection}>
          <CloseIcon />
        </IconButton>
        {children}
      </Dialog>
    </>
  );
};
