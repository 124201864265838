export const EVENT_CHECK_IN_INITIATE = "EVENT_CHECK_IN_INITIATE";
export const EVENT_CHECK_IN_START = "EVENT_CHECK_IN_START";
export const EVENT_CHECK_IN_PASS = "EVENT_CHECK_IN_PASS";
export const EVENT_CHECK_IN_FAIL = "EVENT_CHECK_IN_FAIL";

export const eventCheckInInitiate = (eventId, handleSuccess, handleError) => {
  return {
    type: EVENT_CHECK_IN_INITIATE,
    eventId: eventId,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const eventCheckInStart = () => {
  return {
    type: EVENT_CHECK_IN_START
  };
};

export const eventCheckInPass = passMsg => {
  return {
    type: EVENT_CHECK_IN_PASS,
    msg: passMsg
  };
};
export const eventCheckInFail = errorMsg => {
  return {
    type: EVENT_CHECK_IN_FAIL,
    msg: errorMsg
  };
};
