import {
  FETCH_FORM_DATA_PASS,
  FETCH_FORM_DATA_FAIL,
  FETCH_FORM_DATA_START
} from "../actions";

const initialState = {
  data: {
    userTypes: [],
    mainUserTypes:[],
    FIACategorisation: [],
    bloodGroups: [],
    relationships: []
  },

  loading: false,
  status: false,
  msg: ""
};

const getFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FORM_DATA_START: {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_FORM_DATA_PASS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        msg: action.msg,
        status: true
      };
    }

    case FETCH_FORM_DATA_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }

    default: {
      return state;
    }
  }
};

export default getFormDataReducer;
