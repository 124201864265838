import React from "react";
import classNames from "classnames";

import tickIcon from "assets/images/tick_icon.png";

export const FormProgressBar = ({ stepsInfo, formHeadings }) => {
  return (
    <ul className="d-flex flex-direction-row justify-content-center multisteps_list">
      {stepsInfo.map((step, idx) => (
        <li
          key={idx}
          className={classNames([
            { "active completed": step.completed },
            { active: step.active }
          ])}
        >
          <i>
            {idx + 1}
            {step.completed ? <img src={tickIcon} alt="tickIcon" /> : null}
          </i>
          <span>{formHeadings[idx]}</span>
        </li>
      ))}
    </ul>
  );
};
