import {
  UPDATE_USER_INFO_START,
  UPDATE_USER_INFO_PASS,
  UPDATE_USER_INFO_FAIL
} from "../actions";

const initialState = {
  status: false,
  loading: false,
  msg: ""
};

const updateUserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO_START: {
      return {
        ...state,
        loading: true
      };
    }

    case UPDATE_USER_INFO_PASS: {
      return {
        ...state,
        loading: false,
        status: true,
        msg: action.msg
      };
    }

    case UPDATE_USER_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }

    default: {
      return state;
    }
  }
};

export default updateUserInfoReducer;
