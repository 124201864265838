import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

export const Checkbox = ({
  customstyle,
  config,
  input,
  meta: { touched, error },
  texts,
}) => {
  const validationSpan =
    touched && error ? (
      <span className="error_msg text-danger">{error}</span>
    ) : null;
  return (
    <div className={`col-md-6 ${customstyle}`}>
      <div className="form-group">
        <label className="form-checkbox">
          <input
            {...input}
            type="checkbox"
            className="form-check-input"
            {...config}
            checked={input.value ? true : false}
          />
          {texts.map((text, idx) =>
            <React.Fragment key={idx}>
              {text.isLink ? (
                <Link to={text.redirectLink} target="_blank">
                  {text.value}
                </Link>
              ) : (
                   text.isBold  ? <strong>{text.value} </strong> : text.value 
                )}
            </React.Fragment>  
          )}
          <span className="checkmark"></span>
        </label>
        {validationSpan}
      </div>
    </div>
  );
};
