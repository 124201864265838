import {
  FETCH_EVENTS_LIST_PASS,
  FETCH_EVENTS_LIST_FAIL,
  FETCH_EVENTS_LIST_START,
  FETCH_EVENTS_LIST_RESET,
  UPDATE_EVENTS_LIST
} from "../actions";

const initialState = {
  data: {
    data: [],
    metaData: {
      total: 0
    }
  },
  loading: false,
  status: false,
  msg: ""
};

const getEventsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_LIST_START: {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_EVENTS_LIST_PASS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        msg: action.msg,
        status: true
      };
    }

    case FETCH_EVENTS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }
    case FETCH_EVENTS_LIST_RESET: {
      return {
        ...state,
        data: {
          data: [],
          metaData: {
            total: 0
          }
        }
      };
    }

    case UPDATE_EVENTS_LIST: {
      return {
        ...state,
        data: {
          ...state.data,
          data: action.data
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default getEventsListReducer;
