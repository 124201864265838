// use this helper functon for updating/submitting user info
export const prepareUserInfo = values => {
  let {
    firstName,
    lastName,
    email,
    nationality,
    city,
    hno,
    street,
    postalCode,
    dob,
    code,
    phone,
    bloodGroup,
    knownBloodAllergies,
    relevantPastHistory,
    issueUnderTreatment,
    existingTUE,
    lastTetanusInjection,
    contactName,
    emergencyCode,
    emergencyPhone,
    relationship,
    category,
    FIACategorisation,
    licenseNumber,
  } = values;

  let finalData = {
    firstName,
    lastName,
    email: email,
    dob: dob,
    phoneNumber: {
      code: code.value,
      phone: phone
    },
    nationality: nationality.label,
    city: city,
    address: {
      hno, street
    },
    postalCode,
    medicalInfo: {
      bloodGroup: bloodGroup.value,
      knownBloodAllergies: knownBloodAllergies,
      relevantPastHistory: relevantPastHistory,
      issueUnderTreatment: issueUnderTreatment,
      existingTUE: existingTUE,
      lastTetanusInjection: lastTetanusInjection?.value
    },
    emergencyInfo: {
      contactName: contactName,
      relationship: relationship.value,
      contactNumber: {
        code: emergencyCode.value,
        phone: emergencyPhone
      }
    },
    vehicleInfo: {
      category: category,
      FIACategorisation: FIACategorisation.value,
      licenseNumber: licenseNumber,
    }
  };
  return finalData;
};
