export const START_TEST_INITIATE = "START_TEST_INITIATE";
export const START_TEST_START = "START_TEST_START";
export const START_TEST_PASS = "START_TEST_PASS";
export const START_TEST_FAIL = "START_TEST_FAIL";
export const TEST_DOCUMENT = "TEST_DOCUMENT";
export const TEST_START_LOADER = "TEST_START_LOADER";
export const TEST_STOP_LOADER = "TEST_STOP_LOADER"

export const startTestInitiate = (
  championshipId,
  handleSuccess,
  handleError
) => {
  return {
    type: START_TEST_INITIATE,
    championshipId: championshipId,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const startTestStart = () => {
  return {
    type: START_TEST_START
  };
};

export const startTestPass = (data, passMsg) => {
  return {
    type: START_TEST_PASS,
    data: data,
    msg: passMsg
  };
};
export const startTestFail = errorMsg => {
  return {
    type: START_TEST_FAIL,
    msg: errorMsg
  };
};

export const testDocument = (id,handleSuccess,handleError) => {
  return {
    type: TEST_DOCUMENT,
    id,
    handleSuccess,
    handleError
  }
}

export const startTestLoader = () => {
  return {
    type: TEST_START_LOADER,
  }
}

export const stopTestLoader = () => {
  return {
    type: TEST_STOP_LOADER,
  }
}