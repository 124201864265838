export const STRINGS = {
  EMAIL_KEY: "email",
  EMAIL_LABEL: "Email",
  EMAIL_PLACEHOLDER: "Type your email"
};

export const VALIDATION_MESSAGES = {
  EMAIL_REQUIRED: "Email is required",
  EMAIL_INVALID: "Email is invaid"
};
