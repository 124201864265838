import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

import { MenuListComposition } from "../../atoms/drop-down";
import logo from "assets/images/logo.png";
import hamburgerIcon from "assets/icons/hamburger_icon.svg";
import searchIcon from "assets/icons/search_icon.svg";
import notificationIcon from "assets/icons/notifcation_icon.svg";
import dummyProfilePic from "assets/images/pic1.jpg";

export const AppHeader = ({
  hamburgerVisible = false,
  onHamburgerClicked,
  menuItems,
  profilePic
}) => (
  <header className="fixed-top">
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex align-items-center">
        <Link className="navbar-brand brand-logo" to="/sef-tests">
          <img src={logo} width="150" alt="SEF" />
        </Link>
      </div>

      <div className="navbar-menu-wrapper d-flex align-items-center flex-grow-1">
        <ul className="navbar-nav navbar-nav-right ml-auto">
          {hamburgerVisible && (
            <span className="humburger_icon">
              <i>
                <img
                  onClick={e => {
                    onHamburgerClicked(e);
                  }}
                  src={hamburgerIcon}
                  alt="hamburger"
                  width="26"
                />
              </i>
            </span>
          )}
          {/* <li className="nav-item d-none d-md-block">
            <form className="search-form" action="#">
              <i className="fa fa-search">
                {" "}
                <img src={searchIcon} width="18" alt="SEF" />
              </i>
              <input
                type="search"
                className="form-control"
                placeholder="Search Here"
                title="Search here"
              />
            </form>
          </li> */}
          {/* <li className="nav-item dropdown">
            <Link
              className="nav-link count-indicator message-dropdown"
              id="messageDropdown"
              to="/sef-tests"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i>
                <img src={notificationIcon} width="18" alt="SEF" />
              </i>
              <span className="count">7</span>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="messageDropdown"
            >
              <Link to="#" className="dropdown-item py-3">
                <p className="mb-0 font-weight-medium float-left">
                  You have 7 unread mails{" "}
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link to="#" className="dropdown-item preview-item">
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis">Marian Garner </p>
                  <p className="font-weight-light small-text">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </Link>
              <Link to="#" className="dropdown-item preview-item">
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis">David Grey </p>
                  <p className="font-weight-light small-text">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </Link>
              <Link to="#" className="dropdown-item preview-item">
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis">Travis Jenkins </p>
                  <p className="font-weight-light small-text">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </Link>
            </div>
          </li> */}
          <li className="nav-item dropdown d-xl-inline-flex user-dropdown profile-drop">
            <MenuListComposition
              menuItemLabels={menuItems}
            >
              <span> Tom Cruise </span>
              <img
                src={profilePic}
                width="60"
                className="img-xs rounded-circle ml-2"
                alt="SEF"
              />
            </MenuListComposition>
          </li>
        </ul>
      </div>
    </nav>
  </header>
);
