export const FETCH_TESTS_LIST_INITIATE = "FETCH_TESTS_LIST_INITIATE";
export const FETCH_TESTS_LIST_START = "FETCH_TESTS_LIST_START";
export const FETCH_TESTS_LIST_PASS = "FETCH_TESTS_LIST_PASS";
export const FETCH_TESTS_LIST_FAIL = "FETCH_TESTS_LIST_FAIL";

export const fetchTestsListInitiate = (handleSuccess, handleError) => {
  return {
    type: FETCH_TESTS_LIST_INITIATE,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const fetchTestsListStart = () => {
  return {
    type: FETCH_TESTS_LIST_START
  };
};

export const fetchTestsListPass = (data, passMsg) => {
  return {
    type: FETCH_TESTS_LIST_PASS,
    data: data,
    msg: passMsg
  };
};
export const fetchTestsListFail = errorMsg => {
  return {
    type: FETCH_TESTS_LIST_FAIL,
    msg: errorMsg
  };
};
