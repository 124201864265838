import React from "react";
import { reduxForm, Field } from "redux-form";

import validator from "./validator";

import { STRINGS } from "./constants";

import { Input } from "../../atoms/input";
import { Select } from "../../atoms/select";
import { InputSubmit } from "../../atoms/input-submit";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { onSubmitFail } = require(`helpers/${PLATFORM}`);

const VehicleInfoForm = ({
  handleSubmit,
  FIACategorisation,
  handleBack,
  buttonLabel
}) => {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="row">
        <Field
          asterisk={true}
          name={STRINGS.CATEGORY_KEY}
          component={Input}
          label={STRINGS.CATEGORY_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.CATEGORY_PLACEHOLDER,
          }}
        />
        <Field
          asterisk={true}
          name={STRINGS.FIA_CATEGORY_KEY}
          component={Select}
          label={STRINGS.FIA_CATEGORY_LABEL}
          options={FIACategorisation}
          placeholde={STRINGS.FIA_CATEGORY_PLACEHOLDER}
        />
      </div>
      <div className="row">
        <Field
          asterisk={true}
          name={STRINGS.LICENSE_INFO_KEY}
          component={Input}
          label={STRINGS.LICENSE_INFO_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.LICENSE_INFO_PLACEHOLDER,
          }}
        />
      </div>
      <InputSubmit
        handleBack={handleBack}
        buttonLabel={
          buttonLabel ? buttonLabel : STRINGS.CREATE_ACCOUNT_BUTTON_LABEL
        }
        containerStyle={[]}
        backButtonLabel={STRINGS.PREVIOUS_BUTTON_LABEL}
        buttonStyle="float-right"
      />
    </form>
  );
};

export const VehicleInfoReduxForm = reduxForm({
  form: "signup",
  validate: validator,
  onSubmitFail: onSubmitFail,
  destroyOnUnmount: false
})(VehicleInfoForm);
