import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { CollapsibleList } from "./collapsible-list";

import plusIcon from "assets/images/plus.png";
import minusIcon from "assets/images/minus.png";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");
const { CustomTable } = require(`components/${PLATFORM}/molecules/table`);

export const Stat = ({ item, toggleCollapsibleList, idx }) => {
  return (
    <>
      <TableRow>
        <TableCell>{item.championship}</TableCell>
        <TableCell>{item.totalAttempts}</TableCell>
        <TableCell>{`${item.latestScore}%`}</TableCell>
        <TableCell>
          {item.totalAttempts > 1 && (
            <i
              className="colapse_btn"
              onClick={() => toggleCollapsibleList(idx)}
            >
              <img
                src={item.isExpanded ? minusIcon : plusIcon}
                alt="action-icon"
              />
            </i>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        {item.isExpanded && (
          <TableCell colSpan="4" className="py-0">
            <CustomTable
              renderBody={() => (
                <TableRow>
                  <TableCell align="left" colSpan="4">
                    <div className="total_attempts">
                      <div className="row">
                        {item.tests.map((test, idx) => (
                          <CollapsibleList key={idx} test={test} idx={idx} />
                        ))}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
};
