export const FETCH_EVENTS_LIST_INITIATE = "FETCH_EVENTS_LIST_INITIATE";
export const FETCH_EVENTS_LIST_START = "FETCH_EVENTS_LIST_START";
export const FETCH_EVENTS_LIST_PASS = "FETCH_EVENTS_LIST_PASS";
export const FETCH_EVENTS_LIST_FAIL = "FETCH_EVENTS_LIST_FAIL";
export const FETCH_EVENTS_LIST_RESET = "FETCH_EVENTS_LIST_RESET";
export const UPDATE_EVENTS_LIST = "UPDATE_EVENTS_LIST";

export const fetchEventsListInitiate = (
  page,
  limit,
  handleSuccess,
  handleError
) => {
  return {
    type: FETCH_EVENTS_LIST_INITIATE,
    handleSuccess: handleSuccess,
    handleError: handleError,
    page: page,
    limit: limit
  };
};

export const fetchEventsListStart = () => {
  return {
    type: FETCH_EVENTS_LIST_START
  };
};

export const fetchEventsListPass = (data, passMsg) => {
  return {
    type: FETCH_EVENTS_LIST_PASS,
    data: data,
    msg: passMsg
  };
};
export const fetchEventsListFail = errorMsg => {
  return {
    type: FETCH_EVENTS_LIST_FAIL,
    msg: errorMsg
  };
};

export const fetchEventsListReset = () => {
  return {
    type: FETCH_EVENTS_LIST_RESET
  };
};
export const updateEventsList = data => {
  return {
    type: UPDATE_EVENTS_LIST,
    data: data
  };
};
