import { connect } from "react-redux";
import { Screen } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require(`config/default`);
const { logoutInitiate, setFirstLogin } = require(`../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    isProfileCompleted: state.common.isProfileCompleted,
    profilePic: state.userInfo.data?.profileUrl,
    isFirstLogin: state.common.isFirstLogin
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: (handleSuccess) => dispatch(logoutInitiate(handleSuccess)),
    setFirstLogin: (value) => dispatch(setFirstLogin(value))
  };
};
export const HomeScreenHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
