import React from "react";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";

import { Input } from "../../atoms/input";
import { InputSubmit } from "../../atoms/input-submit";

import forgotIcon from "assets/images/forgot_icon.png";

import validator from "./validator";

import { STRINGS } from "./constants";

import "./style.scss";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { onSubmitFail } = require(`helpers/${PLATFORM}`);

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const ForgotPasswordForm = ({ handleSubmit }) => {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="form_title text-center">
        <i>
          <img src={forgotIcon} alt="" className="img-fluid" width="150px" />
        </i>
        <h3>Forgot Password?</h3>
        <p>
          Enter your email address, We will send you a link to reset your
          password.
        </p>
      </div>
      <div className="row">
        <Field
          name={STRINGS.EMAIL_KEY}
          component={Input}
          label={STRINGS.EMAIL_LABEL}
          widthStyle="col-md-12"
          config={{
            type: "email",
            placeholder: STRINGS.EMAIL_PLACEHOLDER
          }}
        />
      </div>
      <InputSubmit
        buttonLabel={"SEND"}
        containerStyle={["text-center", "mt-2"]}
        buttonStyle=""
        immediateDivStyle="justify-content-sm-center"
      />

      <div className="text-center">
        <Link className="d-inline-block" to={ROUTES.LOGIN}>
          {"Log In? "}
        </Link>
      </div>
    </form>
  );
};

export const ForgotPasswordReduxForm = reduxForm({
  form: "forgot-password",
  validate: validator,
  onSubmitFail: onSubmitFail
})(ForgotPasswordForm);
