export const FETCH_FORM_DATA_INITIATE = "FETCH_FORM_DATA_INITIATE";
export const FETCH_FORM_DATA_START = "FETCH_FORM_DATA_START";
export const FETCH_FORM_DATA_PASS = "FETCH_FORM_DATA_PASS";
export const FETCH_FORM_DATA_FAIL = "FETCH_FORM_DATA_FAIL";

export const fetchFormDataInitiate = (handleSuccess, handleError) => {
  return {
    type: FETCH_FORM_DATA_INITIATE,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const fetchFormDataStart = () => {
  return {
    type: FETCH_FORM_DATA_START
  };
};

export const fetchFormDataPass = (data, passMsg) => {
  return {
    type: FETCH_FORM_DATA_PASS,
    data: data,
    msg: passMsg
  };
};
export const fetchFormDataFail = errorMsg => {
  return {
    type: FETCH_FORM_DATA_FAIL,
    msg: errorMsg
  };
};
