import { put, takeEvery } from "redux-saga/effects";

import {
  LOGOUT_INITIATE,
  removeToken,
  setUserProfileCompleted,
  setUserInfo,
  setUserId,
  setProfileUrl
} from "../actions";

import { persistor } from "../store";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* logoutSaga({ handleSuccess }) {
  try {
    const response = yield postRequest({
      API: `${URL.LOGOUT}`,
    });
    // if (response.status === STATUS_CODE.SUCCESSFUL) {
      // cleanup
      yield put(removeToken());
      yield put(setUserProfileCompleted(false));
      yield put(setUserInfo(null));
      yield put(setUserId(null));
      yield put(setProfileUrl(null));
      yield handleSuccess();
    // }
  } catch {}
}
export function* watchLogout() {
  yield takeEvery(LOGOUT_INITIATE, logoutSaga);
}
