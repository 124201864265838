import { put, takeLatest, delay } from "redux-saga/effects";
import {
  fetchEventAttendeesStart,
  fetchEventAttendeesPass,
  fetchEventAttendeesFail,
  FETCH_EVENT_ATTENDEES_INITIATE,
  logoutInitiate
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* fetchEventAttendeesSaga(action) {
  yield put(fetchEventAttendeesStart());

  let data = {
    eventId: action.eventId,
    page: action.page,
    limit: action.limit,
    filterRole: action.filterRole
  };
  if (action.filterRole.length === 0) {
    delete data.filterRole;
  }
  try {
    const response = yield postRequest({
      API: `${URL.EVENT_ATTENDEES}`,
      DATA: data
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(fetchEventAttendeesPass(response.data.data));
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(fetch(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(fetchEventAttendeesFail("Something went wrong"));
    yield action.handleError();
  } finally {
  }
}

export function* watchEventAttendeesFetching() {
  yield takeLatest(FETCH_EVENT_ATTENDEES_INITIATE, fetchEventAttendeesSaga);
}
