import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

const {
  defaultConfig: { PLATFORM, VERSION }
} = require("./config/default");

const { store, persistor } = require(`./redux/${PLATFORM}/store`);

const { RootRouter } = require(`./routes/${PLATFORM}/${VERSION}/root-router`);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RootRouter />
      </PersistGate>
    </Provider>
  );
}

export default App;
