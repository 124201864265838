import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { QuestionType } from "./question-types";
import { QuestionOptions } from "./question-options";

import { useCountDownTimer } from "hooks/timer";
import { useForceUpdate } from "hooks/forceUpdate";

import "./styles.scss";

let timeSpent = {
  minutes: 0,
  seconds: 0
};

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { InputSubmit } = require(`components/${PLATFORM}/atoms/input-submit`);
const { Loader } = require(`components/${PLATFORM}/atoms/loader`);
const { CustomPrompt } = require(`components/${PLATFORM}/cells/custom-prompt`);

const NUMERIC_TO_ALPHABET = ["zero", "one", "two", "three", "four", "five"];

let testSubmitted = false;
let answers = [];

export const TestScreen = ({
  testTime,
  testQuestions,
  onTestOver,
  totalQuestions,
  championshipName,
  submitTestLoading,
  showSnackBar,
  showPopup,
  snackBarVisible
}) => {
  const location = useLocation();

  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [totalAnswersSelected, setTotalAnswersSelected] = useState(0);
  const [setForceUpdate] = useForceUpdate();
  
  const currentQuestion = testQuestions[currentQuestionIdx];

  const [hours, minutes, seconds] = useCountDownTimer(testTime, timeArr =>
    showPopup(
      false,
      "Your test has been submitted.",
      "Time's Up",
      "Confirm",
      undefined,
      () =>
        onTestOver(
          {
            championshipId: location.pathname.split("/").pop(),
            answers: answers
          },
          {
            minutes: timeArr[1],
            seconds: timeArr[2]
          }
        ),
      undefined,
      false
    )
  );

  useEffect(() => {
    onbeforeunload = () => {
      if (!testSubmitted) {
        onTestOver(
          {
            championshipId: location.pathname.split("/").pop(),
            answers: answers
          },
          timeSpent,
          true
        );
      }
      return "";
    };
    return () => {
      onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestionIdx]);

  useEffect(() => {
    if (snackBarVisible) {
      window.scrollTo(0, 0);
    }
  }, [snackBarVisible]);

  useEffect(() => {
    return () => {
      if (!testSubmitted) {
        onTestOver(
          {
            championshipId: location.pathname.split("/").pop(),
            answers: answers
          },
          timeSpent,
          true
        );
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      answers = [];
    };
  }, []);

  const onOptionClicked = optionIdx => {
    const clickedOption = currentQuestion.options[optionIdx];
    const clickedOptionStatus = clickedOption.isSelected;
    // single answer correct question type
    if (currentQuestion.totalAnswers === 1) {
      if (clickedOptionStatus) {
        clickedOption.isSelected = false;
        setTotalAnswersSelected(0);
      } else {
        if (totalAnswersSelected) {
          let prevSelectedOption = currentQuestion.options.find(
            option => option.isSelected
          );
          prevSelectedOption.isSelected = false;
          clickedOption.isSelected = true;
          setForceUpdate(true);
        } else {
          clickedOption.isSelected = true;
          setTotalAnswersSelected(1);
        }
      }
    }
    // more than one answer correct question type
    else {
      if (
        totalAnswersSelected < currentQuestion.totalAnswers ||
        clickedOptionStatus
      ) {
        clickedOption.isSelected = !clickedOptionStatus;
        setTotalAnswersSelected(
          totalAnswersSelected =>
            totalAnswersSelected + (!clickedOptionStatus ? 1 : -1)
        );
      }
    }
  };

  const onSubmit = () => {
    if (totalAnswersSelected < currentQuestion.totalAnswers) {
      showSnackBar(
        `Oops! Choose ${
          NUMERIC_TO_ALPHABET[
            currentQuestion.totalAnswers - totalAnswersSelected
          ]
        } more answer to continue.`,
        "error"
      );
    } else {
      const selectedAnswerIds = currentQuestion.options
        .filter(option => option.isSelected)
        .map(option => option.id);

      answers.push({
        questionId: currentQuestion._id,
        answer: selectedAnswerIds
      });
      if (currentQuestionIdx === totalQuestions - 1) {
        testSubmitted = true;
        onTestOver(
          {
            championshipId: location.pathname.split("/").pop(),
            answers: answers
          },
          {
            minutes: minutes,
            seconds: seconds
          }
        );
      } else {
        setCurrentQuestionIdx(prevIdx => prevIdx + 1);
        setTotalAnswersSelected(0);
      }
    }
  };

  return (
    <>
      {submitTestLoading ? <Loader /> : null}
      <CustomPrompt
        dialogContent="You are leaving the test area.Your test will be automatically submitted"
        dialogTitle="Warning"
        confirmButtonTitle="Confirm"
        rejectButtonTitle="Cancel"
      />
      <h3 className="title-text">{championshipName}</h3>
      <div className="card border-0 shadow ">
        <div className="card-body">
          <div className="question-ans mb-3">
            <div className="question-numbers mb-2">
              <p>{`Questions ${currentQuestionIdx + 1}/${totalQuestions}`}</p>
              <span className="time-block">
                {`${hours >= 10 ? hours : `0` + hours}:${
                  minutes >= 10 ? minutes : `0` + minutes
                }:${seconds >= 10 ? seconds : `0` + seconds}`}
              </span>
            </div>
            <p>
              <strong>{currentQuestion.question}</strong>
            </p>

            <p>{`Please select ${
              NUMERIC_TO_ALPHABET[currentQuestion.totalAnswers]
            } answer${
              currentQuestion.totalAnswers > 1 ? "s" : ""
            } from the below options`}</p>
          </div>
          <QuestionType
            questionTypeKey={currentQuestion.questionType}
            url={currentQuestion.questionUrl}
            currentQuestionIdx={currentQuestionIdx}
          />
          <div className="col-md-12">
            <div className="row select-ans">
              {currentQuestion.options.map((item, idx) => (
                <QuestionOptions
                  item={item}
                  idx={idx}
                  key={idx}
                  onOptionClicked={onOptionClicked}
                />
              ))}
            </div>
          </div>
          <div className="my-3 my-md-5">
            <InputSubmit
              buttonLabel={"CONTINUE"}
              containerStyle={[]}
              immediateDivStyle="justify-content-sm-start"
              config={{
                disabled: !totalAnswersSelected,
                onClick: onSubmit
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
