import { LOGIN_START, LOGIN_PASS, LOGIN_FAIL } from "../actions";

const initialState = {
  status: false,
  loading: false,
  msg: ""
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return {
        ...state,
        loading: true
      };
    }

    case LOGIN_PASS: {
      return {
        ...state,
        loading: false,
        status: true,
        msg: action.msg
      };
    }

    case LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }

    default: {
      return state;
    }
  }
};

export default loginReducer;
