import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";

import { useMultiStep } from "../multi-step";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  USER_TYPE,
  FORM_INFO_OF_EACH_USER_TYPE
} = require(`shared/${PLATFORM}/constants`);

export const useMultiStepWithUserRole = () => {
  const {
    activeStep,
    setTotalSteps,
    stepsInfo,
    goToStep,
    goToNextStep,
    goToPrevStep
  } = useMultiStep(3); // FORM_INFO_OF_EACH_USER_TYPE[USER_TYPE.NONE].FORM_HEADINGS.length
  const selector = formValueSelector("signup");
  const selectedUserType = selector(
    useSelector(state => state),
    "role"
  );
  useEffect(() => {
    setTotalSteps(3); // FORM_INFO_OF_EACH_USER_TYPE[selectedUserType?.label || USER_TYPE.NONE].FORM_HEADINGS.length
  }, [setTotalSteps, selectedUserType]);

  let formHeadings = ["Personal Details", "Medical Details", "Other Details"]; // FORM_INFO_OF_EACH_USER_TYPE[selectedUserType?.label || USER_TYPE.NONE].FORM_HEADINGS;

  return {
    selectedUserType: selectedUserType,
    formHeadings: formHeadings,
    activeStep: activeStep,
    stepsInfo: stepsInfo,
    goToStep: goToStep,
    goToNextStep: goToNextStep,
    goToPrevStep: goToPrevStep
  };
};
