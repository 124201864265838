module.exports = `<style>
	.terms_of_use {margin:20px 0;}
   .terms_of_use .top_head{text-align: center; width: 100%;float: left; margin-bottom: 1rem;}
   .terms_of_use .top_head h1{font-size: 1.8rem; line-height: 30px;}
   .terms_of_use li, .terms_of_use p{line-height: 30px;}
   .terms_of_use ul {counter-reset: section 0;list-style-type: none;padding-left: 15px; padding-right: 15px;}
   .terms_of_use ul > li {counter-increment: section 1; display: table; margin: 0.4em 0;}
   .terms_of_use ul > li::before {content: counters(section, ".") ". "; display: table-cell; padding-right: 0.6em;}
   .terms_of_use ul > li > ol {counter-reset: section 0; list-style-type: none;padding-left: 0; margin: 0.6em 0;}
   .terms_of_use ul > li > ol > li {counter-increment: section 1; display: table; margin-bottom: 0.6em;}
   .terms_of_use ul > li > ol > li::before {content: counters(section, ".") ". "; display: table-cell; padding-right: 0.6em;}
   .terms_of_use ul > li > ol > li > ol{counter-reset: section 0; list-style-type: none;padding-left: 0; margin: 0.6em 0;}
   .terms_of_use ul > li > ol > li > ol > li {counter-increment: section 1; display: table; margin-bottom: 0.2em;}
   .terms_of_use ul > li > ol > li > ol > li::before {content: counters(section, ".") ". "; display: table-cell; padding-right: 0.6em;}
  .terms_of_use a{color: #f22736;font-weight: #700; }
  .terms_of_use ul > li h4 { margin: 1rem 0;}
  @media(max-width: 768px){
.terms_of_use .top_head h1{font-size: 1.2rem;}
  }
  
  
  </style>
<div class="terms_of_use">
<div class="container">
<div class="row">
	<div class="top_head">
		<h1>SEF RACE CONTROL<br /> PLATFORM TERMS OF USE</h1>
    </div>
	
	<ul>
		<li>
			<h4>Introduction</h4>
			<ol>
				<li>These terms of use (together with the other documents referred to in them, which are collectively referred to here as the &ldquo;<strong>Terms of Use</strong>&rdquo;), tell you the terms on which you may access the SEF Race Control platform and any mobile application made available by us (the &ldquo;<strong>App</strong>&rdquo;), and associated services (together the &ldquo;<strong>Service</strong>&rdquo;, as further described in section 4 below).</li>
				<li>The Service is provided by SEF Racing Limited (&ldquo;<strong>SEF</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo;). We are a limited company registered in England and Wales under company number 11341871 whose registered office is at 239 Kensington High Street 1st Floor, London, England, W8 6SN.</li>
				<li>These Terms of Use constitute a legal agreement between SEF Racing Limited and you as an Authorised User (as defined in sections 2.1 and 2.2 below) of the Service. If you do not agree to abide by these terms of use, you may not access the Service.</li>
		   </ol>
		
		</li>
		<li>
		<h4>Access to the Service</h4>
			<ol>
				<li>Only authorised users of the Service (&ldquo;<strong>Authorised Users</strong>&rdquo;) may access and use the Service. In order to become an Authorised User, you must sign up as set out below. You may be invited to become an Authorised User by an organisation with administrator rights over your use of the Service (an &ldquo;<strong>Administrator</strong>&rdquo;).</li>
				<li>Authorised Users may use the Service in a number of roles (&ldquo;<strong>Roles</strong>&rdquo;) as provided from time to time. The Roles may include:
					<ol>
						<li>motor racing drivers participating in races or other events operated by a Race Championship (&ldquo;<strong>Drivers</strong>&rdquo;);</li>
						<li>journalists, media and press representatives attending races or other events operated by a Race Championship (&ldquo;<strong>Media</strong>&rdquo;); </li>
						<li>race marshalls at races or other events operated by a Race Championship (&ldquo;<strong>Marshalls</strong>&rdquo;); </li>
						<li>motor racing fans and spectators using the Service solely for private recreational purposes (&ldquo;<strong>Fans</strong>&rdquo;); and</li>
						<li>other users not falling into the Roles above (&ldquo;<strong>Other</strong>&rdquo;). 
						</li>
						<p>&ldquo;<strong>Race Championship</strong>&rdquo; means an organisation responsible for a motor racing championship, competition, league or other event, which acts as an Administrator.</p>
					</ol>
				</li>
				<li>SEF may remove, amend or add Roles from time to time in its discretion. </li>
				<li>When you sign up as an Authorised User, you must provide the information requested in relation to the applicable Role. </li>
				<li>Authorised Users must be over the age of 18 to use the Service. </li>
				<li>Authorised Users may not on-supply any elements of the Service to third parties by any method unless expressly permitted.</li>
				<li>You must ensure that any information you give us is true, correct and up to date and you agree that we will not be liable to you for any consequences of you providing inaccurate information. In addition, you agree that we may terminate your access as an Authorised User if you do provide incorrect information. Fake user profiles are not permitted.</li>
				<li>The Service may be accessed globally. However, wherever you access and use the Service from, you agree that you will only use the Service in a manner consistent with these Terms of Use and any and all applicable local, national and international laws and regulations. To the extent that your use of the Service is not legal in your territory, you may not use it.</li>
				<li>You are responsible for making all arrangements necessary for you to have access to the Service.</li>
			</ol>
		</li>
		<li>
		<h4>Downloading and using the App</h4>
		<ol>
			   <li>In return for agreeing to comply with these Terms of Use you may download or stream a copy of the App onto an applicable device and view, use and display the App and the Service on such device in accordance with these Terms of Use. If you download or stream the App onto any device not owned by you, you must have the owner's permission to do so. You will be responsible for complying with these Terms of Use, whether or not you own the device.</li>
			   <li>From time to time we may automatically update the App and change the Service to improve performance, change functionality, reflect changes to the operating system or address security issues. Alternatively we may ask you to update the App for these reasons. If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App and the Service. The App will always work with the current or previous version of the operating system (as it may be updated from time to time) and match the description of it provided to you when you downloaded it.</li>
			   <li>In relation to your use of the App you agree that you will:
				   <ol>
					  <li>not rent, lease, sub-license, loan, provide, or otherwise make available, the App or Service in any form, in whole or in part to any person without prior written consent from us;</li>
					  <li>not copy the App or Service, except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;</li>
					  <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, nor permit the App or the Service or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Service on devices as permitted in these terms; and</li>
					  <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Service nor attempt to do any such things, except to the extent permitted by law (including sections 50B and 296A of the Copyright, Designs and Patents Act 1988).</li>
				   </ol>
				</li>
		</ol>
		</li>
		<li>
		<h4>Protection of Authorised User account and password</h4>
			<ol>
			  <li>You must treat any Authorised User account log in, username, password or any other piece of information provided as part of our security procedures, as confidential. You must not disclose it to any third party or authorise others to use your Authorised User account.</li>
			  <li>You may not transfer your Authorised User account to any other person or entity. If you know or suspect that anyone other than you has obtained your username or password and/or has accessed your Authorised User account, you must immediately notify us by sending an email to pa@seanedwardsfoundation.com.</li>
			  <li>You are responsible for the acts and omissions of any third parties who use your Authorised User identification code or password to access your Authorised User account, whether fraudulent or not, and you agree to reimburse us on demand for any loss we may suffer as a result of such use.</li>
			  <li>We have the right to disable any Authorised User identification code or password, whether chosen by you or allocated by us, at any time if, in our reasonable opinion, you have failed to comply with any of the provisions of these Terms of Use.</li>
			</ol>
		</li>
		<li>
		<h4>The Service</h4>
			<ol>
			  <li>The Service consists of access to the SEF platform and associated services made available from time to time. The Service available to you will depend on your applicable Role and any applicable Administrator, but may include:
				  <ol>
					 <li>access to Driver information and other Submitted Information (defined in section 8.3 below), according to your Role and as permitted by SEF in accordance with our Privacy Policy; and</li>
					 <li>access to safety-related and other educational content including online tests and training programmes (&ldquo;<strong>Educational Content</strong>&rdquo;).</li>
				  </ol>
			  </li>
			  <li>On condition of you agreeing to abide by these Terms of Use, we hereby grant you a non-transferable, non-exclusive licence while you remain an Authorised User to access the Service including Educational Content and Submitted Information in accordance with these Terms of Use.</li>
			</ol>
		</li>
		<li>
		<h4>Privacy Policy</h4>
			<ol>
			  <li>Our Privacy Policy, available here (<a href="https://sefracing.com/privacy-policy">https://sefracing.com/privacy-policy</a>) sets out the terms on which we as data controller process any personal data we collect from you or that you provide to us. <span class="Apple-converted-space">&nbsp; Our Cookies Policy is available here (<a href="https://sefracing.com/cookies-policy">https://sefracing.com/cookies-policy</a>).</span></li>
			  <li>We may use certain data (only if effectively anonymised) that we collect from our App, for the purposes of analysis, research and development of the Service.&nbsp;</li>
			  <li>You warrant that all data provided by you is accurate to the best of your knowledge.</li>
			</ol>
		</li>
		<li>
		<h4>Data Processing </h4>
			 <ol>
				<li>Definitions
					<ol>
					   <li>&ldquo;<strong>Controller</strong>&rdquo;, &ldquo;<strong>Processor</strong>&rdquo;, &ldquo;<strong>Data</strong> <strong>Subject</strong>&rdquo;, &ldquo;<strong>Personal</strong> <strong>Data</strong>&rdquo;, &ldquo;<strong>Personal</strong> <strong>Data</strong> <strong>Breach</strong>&rdquo;, &ldquo;<strong>processing</strong>&rdquo; and &ldquo;<strong>appropriate</strong> <strong>technical and organisational measures</strong>&rdquo;: as defined in the Data Protection Legislation; and</li>
					   <li>&ldquo;<strong>Data Protection Legislation</strong>&rdquo;: all legislation relating to personal data and all other regulatory requirements in force from time to time which apply to a party relating to the use of Personal Data (including, without limitation, the privacy of electronic communications).</li>
					</ol>
				</li>
				<li>Authorised Users will comply with all applicable requirements of the Data Protection Legislation. This section is in addition to, and does not relieve, remove or replace, your obligations or rights under the Data Protection Legislation. </li>
				<li>Without affecting the generality of section 7.2, SEF shall, in relation to any Personal Data in respect of which we are a data processor on behalf of an Authorised User as data controller, and subject to these Terms of Use:
					<ol>
					   <li>process such Personal Data only on the instructions of the data controller (which instructions may be delivered and received through the data controller&rsquo;s use of the Service) unless SEF is required by Data Protection Legislation or these Terms of Use to otherwise process such Personal Data, in which case, SEF shall promptly notify the data controller before performing that processing unless prohibited from so notifying the data controller;</li>
					   <li>ensure that it has in place appropriate technical and organisational measures to protect against unauthorised or unlawful processing of such Personal Data and against accidental loss or destruction of, or damage to, such Personal Data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measures; </li>
					   <li>ensure that all personnel who have access to and/or process such Personal Data are obliged to keep the such Personal Data confidential;</li>
					   <li>not transfer any such Personal Data outside of the UK or the European Economic Area unless such transfer is safeguarded through one of the methods specified in the Data Protection Legislation; </li>
					   <li>assist the data controller, at the data controller&rsquo;s cost, in responding to any request from a Data Subject and in ensuring compliance with its obligations under the Data Protection Legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;</li>
					   <li>notify the data controller without undue delay on becoming aware of a Personal Data Breach;</li>
					   <li>at the written direction of the data controller, delete such Personal Data unless required by Applicable Law to store such Personal Data; and</li>
					   <li>maintain complete and accurate records and information to demonstrate its compliance with this section 7 and allow for audits by the data controller or the data controller&rsquo;s designated auditor and immediately inform the data controller if, in the opinion of SEF, an instruction infringes the Data Protection Legislation.</li>
					</ol>
				</li>
				<li>The data controller consents to SEF appointing third parties listed on SEF&rsquo;s privacy policy as a third-party processor of such Personal Data under these Terms of Use. SEF confirms that it has entered or (as the case may be) will enter with such third-party processor into a written agreement substantially on that third party's standard terms of business and in either case which SEF confirms reflect and will continue to reflect the requirements of the Data Protection Legislation. As between data controller and SEF, SEF shall remain liable for the acts or omissions of such third-party processor appointed by it pursuant to this section 7.4.</li>
			</ol>
		</li>
		<li>
		<h4>Intellectual Property Rights</h4>
		   <ol>
			   <li>We (or our licensors) are the owner or the licensee of all intellectual property rights whatsoever (and wherever existing in the world, together &ldquo;<strong>IP Rights</strong>&rdquo;) subsisting in or in relation to the Service including but not limited to IP Rights in the Educational Content and any software or content used in the provision of the Service (including the App). All such rights are reserved and you have no rights in, or to, the Service including the Educational Content and the App other than the rights to use it in accordance with these Terms of Use.</li>
			   <li>In particular, you acknowledge that the Educational Content is protected by copyright owned by SEF or its licensors and you agree that you will only access Educational Content for your personal use and will not reproduce, modify, download or distribute Educational Content to third parties, whether in whole or part (and irrespective of whether such Educational Content is provided free of charge or are paid), without our prior written consent.</li>
			   <li>You will retain ownership of the copyright subsisting in any information, data or content submitted by you (&ldquo;<strong>Submitted Information</strong>&rdquo;) and you hereby grant to us (or, as relevant, agree to procure the grant to us of) a licence to use such Submitted Information in accordance with these Terms of Use and our Privacy Policy, including in order to provide the Service. You also acknowledge and agree that we may, in accordance with our Privacy Policy, use Submitted Information in order to:
				   <ol>
					   <li>improve and develop the Service;</li>
					   <li>suggest new features to you and other Authorised Users; and</li>
					   <li>display it to other Authorised Users and Administrators in accordance with our Privacy Policy.</li>
				   </ol>
			   </li>
			   <li>You are under no obligation to provide feedback, improvements or other suggestions (&ldquo;<strong>Feedback</strong>&rdquo;) that might improve the Service, however if you do so, you acknowledge that SEF is free to use such Feedback in any way and that SEF shall own any and all intellectual property rights subsisting in or arising in relation to such Feedback as it may be incorporated into the Service.</li>
		   </ol>
		</li>
		<li>
		<h4>Reliance on our service</h4>
		   <ol>
			   <li>In making use of the Service you acknowledge and agree that:
				   <ol>
				   <li>the Service (including any Educational Content) is for general utility, educational, informational and guidance purposes only and is not tailored to any individual Authorised User;</li>
				   <li>the Service (including any Educational Content and Submitted Information) is provided in an &ldquo;as-is&rdquo; and &ldquo;no-liability&rdquo; basis; </li>
				   <li>unless we expressly state otherwise, we make no representations or warranties that the Educational Content is correct, complete, relevant, accurate or up-to-date, or that it meets any specific requirements, regulations or other standards, and we take no liability in that regard;</li>
				   <li>we have no control over the accuracy or completeness of Submitted Information and make no representations or warranties and have no liability whatsoever in that regard;</li>
				   <li>we do not have direct control over the activities of Administrators who may control aspects of your access to the Service;</li>
				   <li>you are responsible for ensuring that your use of the Service (including the Educational Content and Submitted Information) is appropriate for any particular circumstances relevant to you;</li>
				   <li>any use of or reliance by you on any content and information provided in the course of using the Service (including but not limited to Educational Content and Submitted Information) is entirely at your own risk, and we take no responsibility whatsoever for the consequences of such use and in particular no guarantee is made by us that such use will benefit you or provide any particular results; </li>
				   <li>we do not guarantee that the Service will always be available or that access to it will be uninterrupted; and</li>
				   <li>we use reasonable endeavours to remove bugs or viruses but we do not guarantee that the Service will be secure or free from bugs or viruses. You should use your own virus protection software. </li>
				   </ol>
				</li>
		   </ol>
		</li>
		<li>
		<h4>Limitation of our liability</h4>
		   <ol>
			   <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; or for fraud or fraudulent misrepresentation. </li>
			   <li>Subject to section 10.1, to the extent permissible by law, we are not liable for any loss or damage whatsoever suffered in the use of the Service, whether direct or indirect, consequential or special, and including but not limited to loss of business, loss of profit, damage to goodwill or loss of data.<br /> </li>
		   </ol>
		</li>
		<li>
		<h4>Prohibited Uses of the Service</h4>
		   <ol>
				<li>You may use the Service only for lawful purposes. You may not use the Service:
				   <ol>
						<li>in any way that breaches any applicable local, national or international law or regulation; or</li>
						<li>in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
				   </ol>
				</li>
				<li>You also agree:
					<ol>
					<li>not to (and not to attempt to) modify, copy, disclose, distribute or re-sell any part of the Service (including any software used in the provision of the Service and including any Educational Content) in contravention of these Terms of Use;</li>
					<li>not to impersonate any other Authorised Users; and</li>
					<li>not to access without authority, penetrate, interfere with, damage or disrupt (or attempt to do any of the same): (i) the accounts of other Authorised Users; (ii) any part of the Service or its security measures; (iii) any equipment or network on which the Service is stored; or (iv) any software used in the provision of the Service.</li>
					</ol>
				</li>
			</ol>
		</li>
		<li>
		<h4>Interactive Services and Content Standards</h4>
			<ol>
			   <li>We may from time to time provide interactive services on the Service including, without limitation blogging functionality, discussion groups, messaging services and commenting functionality (together "<strong>Interactive Services</strong>").</li>
			   <li>Where we do provide any Interactive Services, we will provide information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).</li>
			   <li>We will do our best to assess any possible risks for users from third parties when they use any Interactive Service provided on the Service, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any Interactive Service we provide on the Service, and we expressly exclude our liability for any loss or damage arising from the use of any Interactive Service by a user in contravention of our content standards, whether the service is moderated or not. </li>
			   <li>Where we do moderate an Interactive Service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise.</li>
			   <li>These content standards below (&ldquo;<strong>Content</strong> <strong>Standards</strong>&rdquo;) apply to any and all Submitted Information and to any Interactive Services associated with it. </li>
			   <li>The Content Standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Submitted Information as well as to its whole.</li>
			   <li>SEF will determine, in its discretion, whether any Submitted Information breaches the Content Standards below.</li>
			   <li>Submitted Information must:
				   <ol>
					   <li>Be accurate (where it states facts).</li>
					   <li>Be genuinely held (where it states opinions).</li>
					   <li>Comply with the law applicable in England and Wales and in any country from which it is posted.</li>
				   </ol>
				</li>
				<li>Submitted Information must not:
				   <ol>
					   <li>Be defamatory of any person.</li>
					   <li>Be obscene, offensive, hateful or inflammatory.</li>
					   <li>Bully, insult, intimidate or humiliate.</li>
					   <li>Promote sexually explicit material.</li>
					   <li>Promote violence.</li>
					   <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
					   <li>Infringe any copyright, database right or trade mark of any other person.</li>
					   <li>Be likely to deceive any person.</li>
					   <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
					   <li>Promote any illegal activity.</li>
					   <li>Be in contempt of court.</li>
					   <li>Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.</li>
					   <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
					   <li>Impersonate any person, or misrepresent your identity or affiliation with any person.</li>
					   <li>Give the impression that the Submitted Information emanates from SEF or any other Authorised User, if this is not the case.</li>
					   <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.</li>
					   <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.</li>
						<li>Contain any advertising or promote any services or web links to other sites.</li>
				   </ol>
				</li>
			</ol>
		</li>
		<li>
		<h4>Ceasing to be an Authorised User</h4>
		   <ol>
			   <li>If you wish to stop being an Authorised User of the Service, please contact us by email at <a href="mailto:pa@seanedwardsfoundation.com"><span class="s3">pa@seanedwardsfoundation.com</a>. </li>
			   <li>We will determine, in our discretion, whether there has been a breach of these Terms of Use (including in particular the Content Standards above). If such a breach has occurred, we may take such action as we deem appropriate, including all or any of the following actions:
				   <ol>
					   <li>immediate, temporary or permanent withdrawal of any relevant Authorised User account and/or the Authorised User&rsquo;s right to use the Service;</li>
					   <li>immediate, temporary or permanent removal of any Submitted Information;</li>
					   <li>the issue of a warning to you; and</li>
					   <li>legal action against you.</li>
				   </ol>
			   </li>
			   <li>If you cease being an Authorised User for any reason you must immediately cease using the Service and must not attempt to access it.</li>
		   </ol>
		</li>
		<li>
		<h4>Changes to these Terms of Use and the Service</h4>
		   <ol>
			   <li>You will be asked to read and accept these Terms of Use at the time you first access the Service.</li>
			   <li>We may revise these Terms of Use at any time and in the event of such a revision, you may be asked to read and accept the revised Terms of Use at the next time you access the Service.</li>
			   <li>We may update the Service from time to time, and may change the content and/or functionality provided through the Service at any time.</li>
			   <li>No variation to these Terms of Use may be made save by a variation notified by us as described above, or else in writing signed by you and us.</li>
		   </ol>
		</li>
		<li>
		<h4>Third Party Rights</h4>
		   <ol>
				<li>A person who is not a party to these Terms of Use shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these Terms of Use, but this does not affect any right or remedy of a third party which exists, or is available, apart from that Act.</li>
		   </ol>
		</li>
	   <li>
	   <h4>Applicable Law</h4>
		   <ol>
				<li>These Terms of Use, their subject matter and their formation, are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</li>
		   </ol>
	   </li>
	   <li>
	   <h4>Trade Marks</h4>
		   <ol>
				<li> &ldquo;SEF&rdquo;, &ldquo;SET&rdquo; and the SEF logo are registered trade marks of SEF Racing Limited. Other names, marks or logos used on the Service may be trade marks of SEF Racing Limited or its licensors. You are not permitted to use them without our approval, unless they are part of material you are using as permitted under these terms.</li>
		   </ol>
		</li>
	   <li>
	   <h4>Contact Us and Complaints</h4>
		   <ol>
				<li>To contact us, including with any comments or complaints regarding the Service please email <a href="mailto:pa@seanedwardsfoundation.com"><span class="s3">pa@seanedwardsfoundation.com</a>.</li>
		   </ol>
		</li>
	</ul>


</div>`;
