import React from "react";
import ReactPlayer from "react-player";
import Img from "react-image";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  Audio
} = require(`components/${PLATFORM}/atoms/custom-audio-player-with-hooks/Audio`);

const QUESTION_TYPE = {
  TEXT: 1,
  IMAGE: 2,
  AUDIO: 3,
  VIDEO: 4
};

export const QuestionType = ({
  questionTypeKey = 1,
  url,
  currentQuestionIdx
}) => {
  switch (questionTypeKey) {
    case QUESTION_TYPE.IMAGE: {
      return (
        <div className="col-md-6 col-12 video-image p-0 mb-3">
          <Img src={url} key={currentQuestionIdx} />
        </div>
      );
    }
    case QUESTION_TYPE.VIDEO: {
      return (
        <div className="col-md-6 col-12 video-image p-0 mb-3">
          <ReactPlayer
            key={currentQuestionIdx}
            playing={true}
            controls={true}
            className="react-player"
            url={url}
          />
        </div>
      );
    }
    case QUESTION_TYPE.AUDIO: {
      return <Audio sourceUrl={url} key={{ currentQuestionIdx }} />;
    }
    default: {
      return null;
    }
  }
};
