import { STRINGS, VALIDATION_MESSAGES } from "./constants";
const {
  defaultConfig: { PLATFORM }
} = require("config/default");
const { REGEX } = require(`shared/${PLATFORM}/constants`);
const validator = values => {
  const errors = {};
  if (!values[STRINGS.FIRST_NAME_KEY]) {
    errors[STRINGS.FIRST_NAME_KEY] = VALIDATION_MESSAGES.FIRST_NAME_REQUIRED;
  } else if (!REGEX.NAME_REGX.test(values[STRINGS.FIRST_NAME_KEY])) {
    errors[STRINGS.FIRST_NAME_KEY] = VALIDATION_MESSAGES.NAME_INVALID;
  }

  //  if (!values[STRINGS.HOUSE_NO_KEY]) {
  //    errors[STRINGS.HOUSE_NO_KEY] = VALIDATION_MESSAGES.HOUSE_NO_REQUIRED;
  //  }
  if (!values[STRINGS.STREET_KEY]) {
    errors[STRINGS.STREET_KEY] = VALIDATION_MESSAGES.STREET_REQUIRED;
  }
  if (!values[STRINGS.POSTAL_CODE_KEY]) {
    errors[STRINGS.POSTAL_CODE_KEY] = VALIDATION_MESSAGES.POSTAL_CODE_REQUIRED;
  }
  if (values[STRINGS.USER_TYPE_KEY]?.label !== 'Others') {
    if (!values[STRINGS.CHAMPIONSHIP_KEY]) {
      errors[STRINGS.CHAMPIONSHIP_KEY] = VALIDATION_MESSAGES.CHAMPIONSHIP_REQUIRED;
    }
  }

  if (!values[STRINGS.LAST_NAME_KEY]) {
    errors[STRINGS.LAST_NAME_KEY] = VALIDATION_MESSAGES.LAST_NAME_REQUIRED;
  } else if (!REGEX.NAME_REGX.test(values[STRINGS.LAST_NAME_KEY])) {
    errors[STRINGS.LAST_NAME_KEY] = VALIDATION_MESSAGES.NAME_INVALID;
  }

  if (!values[STRINGS.EMAIL_KEY]) {
    errors[STRINGS.EMAIL_KEY] = VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (!REGEX.EMAIL_REGEX.test(values[STRINGS.EMAIL_KEY])) {
    errors[STRINGS.EMAIL_KEY] = VALIDATION_MESSAGES.EMAIL_INVALID;
  }

  if (!values[STRINGS.NATIONALITY_KEY]) {
    errors[STRINGS.NATIONALITY_KEY] = VALIDATION_MESSAGES.NATIONALITY_REQUIRED;
  }

  if (!values[STRINGS.CITY_KEY]) {
    errors[STRINGS.CITY_KEY] = VALIDATION_MESSAGES.CITY_REQUIRED;
  }

  if (!values[STRINGS.ADDRESS_KEY]) {
    errors[STRINGS.ADDRESS_KEY] = VALIDATION_MESSAGES.ADDRESS_REQUIRED;
  }

  if (!values[STRINGS.BIRTH_DATE_KEY]) {
    errors[STRINGS.BIRTH_DATE_KEY] = VALIDATION_MESSAGES.BIRTH_DATE_REQUIRED;
  }
  // } else if (moment(values[STRINGS.BIRTH_DATE_KEY]) > moment(new Date()).subtract(18, 'years')) {
  //     errors[STRINGS.BIRTH_DATE_KEY] =
  //         VALIDATION_MESSAGES.BIRTH_DATE_INVALID;
  // }

  if (!values[STRINGS.PHONE_CODE_KEY]) {
    errors[STRINGS.PHONE_CODE_KEY] =
      VALIDATION_MESSAGES.PHONE_NUMBER_CODE_REQUIRED;
  }

  if (!values[STRINGS.PHONE_NUMBER_KEY]) {
    errors[STRINGS.PHONE_NUMBER_KEY] =
      VALIDATION_MESSAGES.PHONE_NUMBER_REQUIRED;
  } else if (values[STRINGS.PHONE_NUMBER_KEY].length < 6) {
    errors[STRINGS.PHONE_NUMBER_KEY] =
      VALIDATION_MESSAGES.PHONE_NUMBER_MIN_LENGTH;
  }

  if (!values[STRINGS.USER_TYPE_KEY]) {
    errors[STRINGS.USER_TYPE_KEY] = VALIDATION_MESSAGES.USER_TYPE_REQUIRED;
  }

  if (!values[STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME]) {
    errors[STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME] = VALIDATION_MESSAGES.AGREE_TO_TERMS_CONDITIONS_REQUIRED
  }

  if (!values[STRINGS.SEF_PRIVACY_NOTICE_NAME]) {
    errors[STRINGS.SEF_PRIVACY_NOTICE_NAME] =
      VALIDATION_MESSAGES.AGREE_TO_PRIVACY_NOTICE_REQUIRED;
  }

  return errors;
};

export default validator;
