export const SUBMIT_TEST_INITIATE = "SUBMIT_TEST_INITIATE";
export const SUBMIT_TEST_START = "SUBMIT_TEST_START";
export const SUBMIT_TEST_PASS = "SUBMIT_TEST_PASS";
export const SUBMIT_TEST_FAIL = "SUBMIT_TEST_FAIL";

export const submitTestInitiate = (answers, handleSuccess, handleError) => {
  return {
    type: SUBMIT_TEST_INITIATE,
    answers: answers,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const submitTestStart = () => {
  return {
    type: SUBMIT_TEST_START
  };
};

export const submitTestPass = (data, passMsg) => {
  return {
    type: SUBMIT_TEST_PASS,
    data: data,
    msg: passMsg
  };
};
export const submitTestFail = errorMsg => {
  return {
    type: SUBMIT_TEST_FAIL,
    msg: errorMsg
  };
};
