import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import { SignUp } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { signupInitiate } = require(`../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    signupLoading: state.signup.loading,
    signupMsg: state.signup.msg,
    formLoading: state.getFormData.loading,
    formStatus: state.getFormData.status,
    formValues: getFormValues("signup")(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUpUser: (info, handleSuccess, handleError) =>
      dispatch(signupInitiate(info, handleSuccess, handleError))
  };
};

export const SignUpScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
