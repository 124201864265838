import React, { useState, useEffect } from "react";
import { useHistory, Prompt } from "react-router-dom";

import { DecisionPopup } from "../../atoms/decision-popup";

export const CustomPrompt = ({
  dialogContent,
  dialogTitle,
  confirmButtonTitle,
  rejectButtonTitle
}) => {
  const history = useHistory();
  const [popupVisible, setPopupVisible] = useState(false);
  const [location, setLocation] = useState(null);
  const [allowNavigation, setAllowNavigation] = useState(false);
  useEffect(() => {
    if (allowNavigation) {
      history.push(location);
    }
  }, [allowNavigation, history, location]);
  return (
    <>
      <Prompt
        message={location => {
          setPopupVisible(true);
          setLocation(location.pathname);
          return allowNavigation;
        }}
      />
      <DecisionPopup
        twoButtons={true}
        modalVisibility={popupVisible}
        dialogContent={dialogContent}
        dialogTitle={dialogTitle}
        confirmButtonTitle={confirmButtonTitle}
        rejectButtonTitle={rejectButtonTitle}
        toggleDialogModal={() => setPopupVisible(false)}
        onConfirmation={() => setAllowNavigation(true)}
        onRejection={() => setPopupVisible(false)}
      />
    </>
  );
};
