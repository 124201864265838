import React, { useEffect } from "react";

import nextIcon from "assets/images/arrow_nxt.svg";

export const Test = ({ item, onTestClicked, getTestDocument }) => {
  return (
    <>
      <div className="col-md-6">
        <div className="card-block">
          <h3 className="card-title">{item.championship.name}</h3>
          <div className="d-flex justify-content-between align-items-end card_attempts">
            <ul className="atttempt_list">
              <li>Attempts - {item.attempt}</li>
              <li>Percentage - {item.percentage}%</li>
            </ul>

            <button
              onClick={() =>
                onTestClicked(
                  item.championship._id,
                  item.championship.name,
                  item.instructions,
                  item.blockStatus
                )
              }
              className="btn btn-md btn-primary"
            >
              {item.attempt > 0 ? "RETAKE" : "BEGIN"}
              <i className="float-right ml-2">
                <img src={nextIcon} width="18" alt="SEF" />
              </i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
