import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { usePagination } from "hooks/pagination";

import { Event } from "./event/screen";
import { EmptyList } from "./empty-list";

import { useSelector } from "react-redux";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

const {
  PaginationBar
} = require(`components/${PLATFORM}/molecules/pagination-bar`);

export const EventsListing = ({
  eventsList,
  eventListMetaData,
  eventsListLoading,
  eventsListMsg,
  getEventsList,
  eventDetailsLoading,
  eventDetailsMsg,
  getEventDetails,
  setEventDetails,
  checkIn,
  eventCheckInLoading,
  eventCheckInStatus,
  eventCheckInMsg,
  resetEventsList,
  updateEventsList,
  showSnackBar,
  showCompleteYourDetailsDialog
}) => {
  const history = useHistory();

  const profileUrl = useSelector((state) => state.userInfo.data?.profileUrl);
  const isProfileCompleted = useSelector(state => state.common.isProfileCompleted);

  const [
    currentPage,
    limit,
    setCurrentPage,
    onPageChange,
    onLimitChange
  ] = usePagination(1, 5);

  const onEventClicked = event => {
    setEventDetails(event);
    history.push(`${ROUTES.EVENTS}/${event._id}`);
  };
  const getlist = useCallback(() => {
    getEventsList(
      currentPage,
      limit,
      () => "handle success",
      (msg, status) => {
        showSnackBar(msg, "error");
      }
    );
  }, [getEventsList, currentPage, limit]);
  useEffect(() => getlist(), [getlist]);
  useEffect(() => {
    return () => resetEventsList();
  }, []);
  const onEventCheckIn = (eventId, index) => {
    checkIn(
      eventId,
      (msg, status) => {
        eventsList[index].attendees.unshift({
          profileUrl: profileUrl
        });
        let event = {
          ...eventsList[index],
          isAttended: true,
          total_attendees: eventsList[index].total_attendees + 1,
          attendees: eventsList[index].attendees
        };
        eventsList[index] = event;
        updateEventsList(eventsList);
        showSnackBar(msg, "success");
      },
      (msg, status) => {
        showSnackBar(msg, "error");
      }
    );
  };
  return (
    <>
      {eventsListLoading || eventDetailsLoading || eventCheckInLoading ? (
        <Loader />
      ) : null}

      <>
        <div className="card-title">
          <h5>Events</h5>
        </div>
        <div className="card border-0 shadow ">
          <div className="card-body">
            {eventsList.length === 0 && !eventsListLoading ? (
              <EmptyList />
            ) : (
              <>
                <div className="row test-blocks">
                  {eventsList?.map((event, idx) => (
                    <Event
                      index={idx}
                      key={idx}
                      event={event}
                      onEventClicked={onEventClicked}
                      onEventCheckIn={onEventCheckIn}
                      isProfileCompleted={isProfileCompleted}
                      showCompleteYourDetailsDialog={showCompleteYourDetailsDialog}
                    />
                  ))}
                </div>

                {eventsList.length !== 0 && (
                  <PaginationBar
                    currentPage={currentPage}
                    totalPages={eventListMetaData.total}
                    onPageChange={onPageChange}
                    limit={limit}
                    itemsCount={eventsList.length}
                    onLimitChange={onLimitChange}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
};
