import React from "react";
import classNames from "classnames";

import optionSelectedIcon from "assets/images/selected.svg";

export const QuestionOptions = ({ item, onOptionClicked, idx }) => {
  return (
    <div
      className={classNames("column", {
        option_selected: item.isSelected
      })}
      onClick={() => onOptionClicked(idx)}
    >
      {`${String.fromCharCode(65 + idx)}. ${item.option}`}
      <span>
        {item.isSelected ? <img src={optionSelectedIcon} alt="tick" /> : null}
      </span>
    </div>
  );
};
