import { put, takeLatest } from "redux-saga/effects";
import {
  retryTestRequestStart,
  retryTestRequestPass,
  retryTestRequestFail,
  RETRY_TEST_REQUEST_INITIATE,
  logoutInitiate
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* retryTestRequestSaga(action) {
  yield put(retryTestRequestStart());
  try {
    const response = yield postRequest({
      API: `${URL.RETRY_TEST_REQUEST}`,
      DATA: {
        championshipId: action.championshipId
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(retryTestRequestPass(response.data.data, response.data.msg));
      yield action.handleSuccess(response.data.msg);
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(retryTestRequestFail(response.data.msg));
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put(retryTestRequestFail("Something went wrong"));
    yield action.handleError("Something went wrong");
  } finally {
  }
}

export function* watchRetryTestRequestRequest() {
  yield takeLatest(RETRY_TEST_REQUEST_INITIATE, retryTestRequestSaga);
}
