import { connect } from "react-redux";
import { testDocument } from "redux/sef/actions";
import { TestsList } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  fetchTestsListInitiate
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    testsListLoading: state.getTestsList.loading,
    testsListMsg: state.getTestsList.msg,
    testsListData: state.getTestsList.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTestsList: (handleSuccess, handleError) =>
      dispatch(fetchTestsListInitiate(handleSuccess, handleError)),
    getTestDocument: (id,handleSuccess, handleError) => 
    dispatch(testDocument(id,handleSuccess, handleError))
  };
};

export const TestsListScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestsList);
