import { put, takeLatest } from "redux-saga/effects";
import {
  submitTestStart,
  submitTestPass,
  submitTestFail,
  SUBMIT_TEST_INITIATE
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* submitTestRequestSaga(action) {
  yield put(submitTestStart());

  try {
    const response = yield postRequest({
      API: `${URL.TEST_SUBMIT}`,
      DATA: {
        ...action.answers
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(submitTestPass(response.data.data));
      yield action.handleSuccess();
    } else {
      yield put(submitTestFail(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(submitTestFail("Something went wrong"));
    yield action.handleError();
  } finally {
  }
}

export function* watchSubmitTestRequest() {
  yield takeLatest(SUBMIT_TEST_INITIATE, submitTestRequestSaga);
}
