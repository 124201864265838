// import countryTelephoneCode, { countries } from "country-telephone-code";
// import { getNames, getCodes } from "country-list";

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [currentYear];
  for (let i = currentYear; i >= currentYear - 30; --i) {
    years.push({
      label: i,
      value: "" + i
    });
  }
  return years;
};

// export const prepareCountryTelephoneCodes = () => {
//   return countries.map(country => {
//     return {
//       label: `+${countryTelephoneCode(country)}`,
//       value: countryTelephoneCode(country)
//     };
//   });
// };

// export const prepareCountryList = () => {
//   const countryNames = getNames();
//   const countryCodes = getCodes();
//   const countryOptions = countryNames.map((countryName, index) => {
//     return {
//       label: countryName,
//       value: countryCodes[index]
//     };
//   });
//   return countryOptions;
// };
