import React, { useState } from "react";
import { useParams, useHistory, Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import tickIcon from "assets/icons/tick_icon.png";
import nextIcon from "assets/images/arrow_nxt.svg";

import cityIcon from "assets/icons/city_icon.svg";
import countryIcon from "assets/icons/country_flag.svg";
import dateIcon from "assets/icons/date_icon.svg";
import locationIcon from "assets/icons/location_icon.svg";
import timeIcon from "assets/icons/time-icon.svg";
import attendanceIcon from "assets/icons/attendance_icon.svg";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const { getDataTime } = require(`helpers/${PLATFORM}/`);

export const EventDetails = ({
  eventDetails,
  checkIn,
  viewAllAttendees,
  eventCheckInLoading,
  eventCheckInStatus,
  eventCheckInMsg,
  updateEventDetails,
  showSnackBar,
  showCompleteYourDetailsDialog
}) => {
  const { id } = useParams();
  const history = useHistory();

  const profileUrl = useSelector((state) => state.userInfo.data?.profileUrl);
  const isProfileCompleted = useSelector((state) => state.common.isProfileCompleted);

  return (
    <>
      {eventDetails ? (
        <>
          {eventCheckInLoading ? <Loader /> : null}

          <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
            <h5>
              <label onClick={() => history.goBack()}>Events </label>{" "}
              <span>Description</span>
            </h5>
          </div>
          <div className="card border-0 shadow ">
            <div className="card-body">
              <h3 className="card-title">{eventDetails.name}</h3>
              {/* {eventDetails.desc.map((text, idx) => (
                <p>{text}</p>
              ))} */}
              <p>{eventDetails.desc}</p>

              <hr className="clearfix" />

              <div className="block_tile mt-4">
                <div className="row flex-wrap flex-column flex-md-row">
                  <div className="col form-group">
                    <h6>
                      <i className="mr-2">
                        <img src={dateIcon} width="" alt="SEF" />
                      </i>{" "}
                      Date
                    </h6>
                    <span>{getDataTime(eventDetails.dateTime).date}</span>
                  </div>

                  <div className="col form-group">
                    <h6>
                      <i className="mr-2">
                        <img src={timeIcon} alt="sef" />
                      </i>{" "}
                      Time
                    </h6>
                    <span>{getDataTime(eventDetails.dateTime).time}</span>
                  </div>

                  <div className="col form-group">
                    <h6>
                      <i className="mr-2">
                        <img src={countryIcon} alt="sef" />
                      </i>{" "}
                      Country
                    </h6>
                    <span>{eventDetails.country}</span>
                  </div>

                  <div className="col form-group">
                    <h6>
                      <i className="mr-2">
                        <img src={cityIcon} alt="sef" />
                      </i>{" "}
                      City
                    </h6>
                    <span>{eventDetails.city}</span>
                  </div>

                  <div className="col form-group">
                    <h6>
                      <i className="mr-2">
                        <img src={locationIcon} alt="sef" />
                      </i>{" "}
                      Location
                    </h6>
                    <span>{eventDetails.circuit}</span>
                  </div>
                </div>
              </div>

              <div className="block_tile mt-3">
                <h6>
                  <i className="mr-2">
                    <img src={attendanceIcon} alt="sef" />
                  </i>{" "}
                  {`Attendees (${eventDetails.totalAttendees})`}
                </h6>

                <div className="d-flex align-items-center justify-content-start flex-row flex-wrap attend_detail">
                  {eventDetails.attendees.map((attendee, idx) => (
                    <figure key={idx}>
                      <img
                        src={attendee.profileUrl}
                        width="60"
                        className="rounded-circle"
                        alt="avatar"
                      />
                    </figure>
                  ))}
                  {eventDetails.totalAttendees > 0 ? (
                    <Link to={`${ROUTES.EVENTS}/${id}/attendees`}>See all</Link>
                  ) : null}
                </div>
              </div>

              <hr />

              <button
                disabled={
                  eventDetails.testQualified
                    ? new Date(
                        Date.parse(new Date(eventDetails.dateTime)) -
                          eventDetails.startCheckInTime * 60 * 60 * 1000
                      ).getTime() >= new Date().getTime()
                      ? true
                      : false
                    : true
                }
                className="btn btn-lg  btn-primary"
                onClick={() => {
                  if (!eventDetails.isAttended) {
                    if (!isProfileCompleted) {
                      showCompleteYourDetailsDialog();
                    }
                    else {
                      checkIn(
                        id,
                        (msg) => {
                          eventDetails.attendees.unshift({
                            profileUrl: profileUrl,
                          });
                          updateEventDetails({
                            ...eventDetails,
                            isAttended: true,
                            totalAttendees: eventDetails.totalAttendees + 1,
                            attendees: eventDetails.attendees,
                          });
                          showSnackBar(msg, "success");
                        },
                        (msg) => showSnackBar(msg, "error")
                      );
                    }
                    
                  }
                }}
              >
                {eventDetails.isAttended ? "ATTENDING" : "CHECK-IN"}
                <i
                  className={`${
                    eventDetails.isAttended ? "float-left" : "float-right"
                  } ml-2`}
                >
                  <img
                    src={eventDetails.isAttended ? tickIcon : nextIcon}
                    width="24px"
                    alt="avatar"
                  />
                </i>
              </button>
            </div>
          </div>
        </>
      ) : (
        <Redirect to={ROUTES.EVENTS} />
      )}
    </>
  );
};
