import { STRINGS, VALIDATION_MESSAGES } from "./constants";
const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { REGEX } = require(`shared/${PLATFORM}/constants`);

const validator = values => {
  const errors = {};
  if (!values[STRINGS.EMAIL_KEY]) {
    errors[STRINGS.EMAIL_KEY] = VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (!REGEX.EMAIL_REGEX.test(values[STRINGS.EMAIL_KEY].toLowerCase())) {
    errors[STRINGS.EMAIL_KEY] = VALIDATION_MESSAGES.EMAIL_INVALID;
  }
  return errors;
};

export default validator;
