export const SIGNUP_INITIATE = "SIGNUP_INITIATE";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_PASS = "SIGNUP_PASS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const signupInitiate = (info, handleSuccess, handleError) => {
  return {
    info: info,
    type: SIGNUP_INITIATE,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const signupStart = () => {
  return {
    type: SIGNUP_START
  };
};

export const signupPass = passMsg => {
  return {
    type: SIGNUP_PASS,
    msg: passMsg
  };
};
export const signupFail = errorMsg => {
  return {
    type: SIGNUP_FAIL,
    msg: errorMsg
  };
};
