module.exports = `<style>
.privacy_notice {margin: 20px 0;}
	.privacy_notice .top_head{width: 100%; float: left; text-align: center; margin-bottom: 1rem;}
	.privacy_notice .top_head h1{line-height: 30px; font-size: 1.8rem;}
	.privacy_notice li, .privacy_notice p{line-height: 30px; font-size: 16px;}
	.privacy_notice ul {list-style-type: circle;}
	.privacy_notice ul > li {display: table; margin: 0.4em 0;}
	.privacy_notice ul > li::before {content: "●"; display: table-cell;  padding-right: 0.6em; color: #000;}
	.privacy_notice h4{margin: 0.6rem 0;}
	@media (max-width: 768px){
.privacy_notice .top_head h1{font-size: 1.2rem;}
	}
	</style>

<div class="privacy_notice">
	<div class="container">
		<div class="row">
			<div class="top_head">
				<h1>WELCOME TO SEF RACE CONTROL COOKIE POLICY.</h1>
			</div>
			<p>SEF Race Control platform (the <strong>Site</strong>) uses cookies to distinguish you from other users of our Site This helps us to provide you with a good experience when you browse our Site and also allows us to improve our Site.</p>		
			<p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
			<p>We use the following cookies:
			<ul>
				<li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our Site They include, for example, cookies that enable you to log into secure areas of our Site, use a shopping cart or make use of e-billing services.</li>
				<li><strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our Site works, for example, by ensuring that users are finding what they are looking for easily.</li>
				<li><strong>Functionality cookies.</strong> These are used to recognise you when you return to our Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
				<li><strong>Targeting cookies.</strong> These cookies record your visit to our Site, the pages you have visited and the links you have followed. We will use this information to make our Site and the advertising displayed on it more relevant to your interests. </li>
				<li><strong>Cookies that send information to other companies</strong>These are cookies that are set on a Site by our partner companies (e.g. or advertisers). They may use the data collected from these cookies to anonymously target advertising to you on other websites, based on your visit to this Site. This is known as a <strong>“Third Party’’</strong> cookie.</li>
			</ul>
			</p>
			<p><h4>Change Cookie Setting</h4>you can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Site.</p>
			<p><h4>Legal Notice Update</h4>We reserve the right to make any changes and corrections to this notice. Please refer to this page from time to time to review these and new additional information.</p>
		</div>
	</div>
</div>`;
