import React, { useState } from "react";
import SimpleCrypto from "simple-crypto-js";
import { useFeedbackMessages } from 'hooks/feedback-messages';

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { LoginReduxForm } = require(`components/${PLATFORM}/forms/login`);
const {
  SnackbarWrapper
} = require(`components/${PLATFORM}/molecules/snackbar-wrapper`);
const {
  BackgroundRacingFlags
} = require(`components/${PLATFORM}/atoms/background-racing-flags`);
const { Logo } = require(`components/${PLATFORM}/atoms/logo`);
const {
  LoginSignupTab
} = require(`components/${PLATFORM}/atoms/login-signup-tab`);
const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

export const Login = ({ signInUser, status, loading, msg, history, showPopup, rememberMe, email, encryptedPass  }) => {
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const handleInitialValues = React.useMemo(() => {
    if (rememberMe) { 
      const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_REMEMBER_ME_SECRET_KEY);
      const decipherText = simpleCrypto.decrypt(encryptedPass).toString();
      return {
        password: decipherText,
        email: email,
        persistAuth: true
      }
    }
    else {
      return {
        password: undefined,
        email: undefined,
        persistAuth: false,
      };
      
    }
  },[]);
  const onFormSubmit = values => {
    signInUser(
      values,
      (msg) => {
        showPopup(
          false,
          msg,
          "Registered Successfully",
          "Okay",
          null,
          () => undefined,
          () => undefined,
          false
        );
      },
      () => {
        setOpenSnackbar(true);
      }
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <BackgroundRacingFlags />
      <div className={"container"}>
        <Logo />
        <div className={"login-signup-container"}>
          <LoginSignupTab history={history} tabValue={0} />
          <div className="front-form">
            <div className="log_reg_form">
              <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={status ? "success" : "error"}
                message={msg}
              />
              <LoginReduxForm onSubmit={onFormSubmit} initialValues={handleInitialValues} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
