import React from "react";

import "./style.scss";

import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

export const DatePickerInput = ({
  asterisk,
  value,
  input,
  label,
  meta: { touched, error },
  config,
  widthStyle
}) => {
  widthStyle = widthStyle ? widthStyle : "col-md-6";
  const validationSpan =
    touched && error ? (
      <span className="error_msg text-danger">{error}</span>
    ) : null;
  return (
    <div className={widthStyle}>
      <div className="form-group">
        {label && (
          <label>
            {label} {asterisk && <sup className="sup_error text-danger">*</sup>}
          </label>
        )}

        {/* <input className="form-control" {...input} {...config} /> */}
        {/* <Calendar
                    {...input}
                /> */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid>
            <KeyboardDatePicker
              helperText=""
              onKeyDown={e => e.preventDefault()}
              error={false}
              placeholder="DD-MM-YYYY"
              className="form-control"
              {...input}
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={input.value}
              maxDate={new Date()}
              // onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </Grid>
          {validationSpan}
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

//className = "form-control"
