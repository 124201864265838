import React from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AuthRoutes } from "../auth-routes";
import { ProtectedRoutes } from "../protected-routes";

import { PageTitles } from "../page-titles";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { updateAuthToken } = require(`helpers/${PLATFORM}`);

const MainRouter = ({ userToken, isProfileCompleted }) => {
  updateAuthToken(userToken);

  return (
    <BrowserRouter>
      <PageTitles />
      {userToken ? (
        <ProtectedRoutes isProfileCompleted={isProfileCompleted} />
      ) : (
        <AuthRoutes />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.common.userToken,
    isProfileCompleted: state.common.isProfileCompleted
  };
};

const mapDispatchToProps = state => {
  return {};
};

export const RootRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainRouter);
