import {
  SET_TOKEN,
  REMOVE_TOKEN,
  SET_USER_PROFILE_COMPLETED,
  SET_USER_ID,
  SET_PROFILE_URL,
  SET_FIRST_LOGIN,
  SET_REMEMBER_ME,
  SET_EMAIL,
  SET_ENCRYPTED_PASSWORD
} from "../actions";

const initialState = {
  userToken: null,
  isProfileCompleted: false,
  id: null,
  profileUrl: null,
  isFirstLogin: false,
  email: null,
  rememberMe: false,
  encryptedPass: null
};
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      return {
        ...state,
        userToken: action.userToken
      };
    }
    case REMOVE_TOKEN: {
      return {
        ...state,
        userToken: null
      };
    }

    case SET_USER_PROFILE_COMPLETED: {
      return {
        ...state,
        isProfileCompleted: action.isProfileCompleted
      };
    }
    case SET_USER_ID: {
      return {
        ...state,
        id: action.id
      };
    }

    case SET_PROFILE_URL: {
      return {
        ...state,
        profileUrl: action.profileUrl
      };
    }
      
    case SET_FIRST_LOGIN: {
      return {
        ...state,
        isFirstLogin: action.isFirstLogin
      };
    }
    
    case SET_EMAIL: { 
      return {
        ...state,
        email: action.email
      }
    }
      
    case SET_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: action.rememberMe
      }
    }
    
    case SET_ENCRYPTED_PASSWORD: {
      return {
        ...state,
        encryptedPass: action.encryptedPassword,
      };
    }  
      
    default: {
      return state;
    }
  }
};

export default commonReducer;
