import React from "react";
import { reduxForm, Field } from "redux-form";

import { STRINGS } from "./constants";

import { Select } from "../../atoms/select";
import { Input } from "../../atoms/input";
import Heading from "../../atoms/heading";
import { InputSubmit } from "../../atoms/input-submit";

import validator from "./validator";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { onSubmitFail } = require(`helpers/${PLATFORM}`);

const { getYears } = require(`helpers/${PLATFORM}`);

const MedicalInfoForm = ({
  handleSubmit,
  handleBack,
  bloodGroups,
  telephoneCodes,
  relationships
}) => {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="row">
        <Field
          asterisk={true}
          name={STRINGS.BLOOD_GROUP_KEY}
          component={Select}
          label={STRINGS.BLOOD_GROUP_LABEL}
          options={bloodGroups}
        />
        <Field
          name={STRINGS.BLOOD_ALLERGIES_KEY}
          component={Input}
          label={STRINGS.BLOOD_ALLERGIES_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.BLOOD_ALLERGIES_PLACEHOLDER
          }}
        />
      </div>

      <div className="row">
        <Field
          name={STRINGS.MEDICAL_HISTORY_KEY}
          component={Input}
          label={STRINGS.MEDICAL_HISTORY_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.MEDICAL_HISTORY_PLACEHOLDER
          }}
        />
        <Field
          name={STRINGS.OTHER_ISSUES_KEY}
          component={Input}
          label={STRINGS.OTHER_ISSUES_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.OTHER_ISSUES_PLACEHOLDER
          }}
        />
      </div>
      <div className="row">
        <Field
          name={STRINGS.TUE_KEY}
          component={Input}
          label={STRINGS.TUE_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.TUE_PLACEHOLDER
          }}
        />
        <Field
          asterisk={false}
          name={STRINGS.LAST_TETANUS_INJECTION_YAER_KEY}
          component={Select}
          label={STRINGS.LAST_TETANUS_INJECTION_YAER_LABEL}
          placeholde={STRINGS.LAST_TETANUS_INJECTION_YAER_PLACEHOLDER}
          options={getYears()}
        />
      </div>

      <Heading text={STRINGS.EMERGENCY_HEADING_TEXT} />
      <div className="row">
        <Field
          asterisk={true}
          name={STRINGS.EMERGENCY_CONTACT_NAME_KEY}
          component={Input}
          label={STRINGS.EMERGENCY_CONTACT_NAME_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.EMERGENCY_CONTACT_NAME_PLACEHOLDER
          }}
        />
        <div className="col-md-6">
          <label>
            {STRINGS.EMERGENCY_CONTACT_NUMBER_LABEL}{" "}
            <sup className="sup_error text-danger">*</sup>
          </label>
          <div className="form-row">
            <Field
              name={STRINGS.EMERGENCY_CONTACT_NUMBER_CODE_KEY}
              component={Select}
              options={telephoneCodes}
              widthStyle="col-md-4"
            />
            <Field
              name={STRINGS.EMERGENCY_CONTACT_NUMBER_KEY}
              component={Input}
              normalize={val => {
                if (!isNaN(val)) {
                  return val;
                }
              }}
              config={{
                type: "tel",
                placeholder: STRINGS.EMERGENCY_CONTACT_NUMBER_PLACEHOLDER,
                maxLength: 15,
                minLength: 6
              }}
              widthStyle="col-md-8"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <Field
          asterisk={true}
          name={STRINGS.EMERGENCY_CONTACT_RELATION_KEY}
          component={Select}
          placeholde={STRINGS.EMERGENCY_CONTACT_RELATION_PLACEHOLDER}
          label={STRINGS.EMERGENCY_CONTACT_RELATION_LABEL}
          options={relationships}
        />
      </div>

      <InputSubmit
        handleBack={handleBack}
        key={2}
        config={{
          type: "button"
        }}
        buttonLabel={STRINGS.CONTINUE_BUTTON_LABEL}
        containerStyle={[]}
        backButtonLabel={STRINGS.PREVIOUS_BUTTON_LABEL}
        buttonStyle="float-sm-right"
      />
    </form>
  );
};

export const MedicalInfoReduxForm = reduxForm({
  form: "signup",
  validate: validator,
  onSubmitFail: onSubmitFail,
  destroyOnUnmount: false
})(MedicalInfoForm);
