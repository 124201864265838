import React from "react";

import logo from "assets/images/logo.png";

export const Logo = () => {
  return (
    <div className="header-main">
      <span className="logo d-inline-block">
        <img src={logo} alt="SEF" className="img-fluid" width="200px" />
      </span>
    </div>
  );
};

// export default Logo;
