import { connect } from "react-redux";
import { ForgotPassword } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  forgotPasswordInitiate
} = require(`../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    status: state.forgotPassword.status,
    loading: state.forgotPassword.loading,
    msg: state.forgotPassword.msg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestForgotPassword: (email, handleSuccess, handleError) =>
      dispatch(forgotPasswordInitiate(email, handleSuccess, handleError))
  };
};

export const ForgotPasswordScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
