import {
    STRINGS,
    VALIDATION_MESSAGES
} from './constants';
const validator = values => {
    const errors = {};
    if (!values[STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME]) {
        errors[STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME] =
            VALIDATION_MESSAGES.AGREE_TO_TERMS_CONDITIONS_REQUIRED;
    }

    return errors;
};

export default validator;