export const FETCH_EVENT_ATTENDEES_INITIATE = "FETCH_EVENT_ATTENDEES_INITIATE";
export const FETCH_EVENT_ATTENDEES_START = "FETCH_EVENT_ATTENDEES_START";
export const FETCH_EVENT_ATTENDEES_PASS = "FETCH_EVENT_ATTENDEES_PASS";
export const FETCH_EVENT_ATTENDEES_FAIL = "FETCH_EVENT_ATTENDEES_FAIL";

export const fetchEventAttendeesInitiate = (
  eventId,
  page,
  limit,
  filterRole,
  handleSuccess,
  handleError
) => {
  return {
    type: FETCH_EVENT_ATTENDEES_INITIATE,
    eventId: eventId,
    page: page,
    limit: limit,
    filterRole: filterRole,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const fetchEventAttendeesStart = () => {
  return {
    type: FETCH_EVENT_ATTENDEES_START
  };
};

export const fetchEventAttendeesPass = (data, passMsg) => {
  return {
    type: FETCH_EVENT_ATTENDEES_PASS,
    data: data,
    msg: passMsg
  };
};
export const fetchEventAttendeesFail = errorMsg => {
  return {
    type: FETCH_EVENT_ATTENDEES_FAIL,
    msg: errorMsg
  };
};
