import { connect } from "react-redux";
import { Login } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { loginInitiate } = require(`../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    loading: state.login.loading,
    status: state.login.status,
    msg: state.login.msg,
    rememberMe: state.common.rememberMe,
    email: state.common.email,
    encryptedPass: state.common.encryptedPass,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signInUser: (info, handleSuccess, handleError) =>
      dispatch(loginInitiate(info, handleSuccess, handleError))
  };
};

export const LoginScreen = connect(mapStateToProps, mapDispatchToProps)(Login);
