import React from "react";

import noAttendeesAvatar from "assets/icons/no-attendees-icon.png";
import calendarIcon from "assets/icons/calendar_icon.png";
import locationIcon from "assets/icons/location_icon.png";
import tickIcon from "assets/icons/tick_icon.png";
import blueEllipse from "assets/icons/ellipse_blue.png";
import "./style.scss";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { getDataTime } = require(`helpers/${PLATFORM}/`);

export const Event = ({ event, onEventClicked, onEventCheckIn, index, isProfileCompleted, showCompleteYourDetailsDialog}) => {
  return (
    <>
      <div
        className="col-md-6"
        onClick={e => {
          onEventClicked({
            _id: event._id,
            name: event.name,
            desc: event.description,
            dateTime: event.dateTime,
            country: event.country,
            city: event.city,
            circuit: event.circuit,
            attendees: event.attendees,
            totalAttendees: event.total_attendees,
            isAttended: event.isAttended,
            startCheckInTime: event.startCheckInTime,
            testQualified: event.testQualified
          });
        }}
      >
        <div className="card-block">
          <h3 className="card-title text-capitalize">{event.name}</h3>
          <div className="row event_list">
            <div className="col-md-12">
              <ul>
                <li>
                  <i>
                    <img src={locationIcon} alt="location"></img>
                  </i>
                  <span>{event.circuit}</span>
                </li>
                <li>
                  <i>
                    <img src={calendarIcon} alt="calendar"></img>
                  </i>
                  <span>{getDataTime(event.dateTime).date}</span>
                </li>
              </ul>

              <div className="attend_users d-flex">
                {event.total_attendees === 0 ? (
                  <div className="d-flex align-items-center attend_users_list">
                    <figure>
                      <img
                        src={noAttendeesAvatar}
                        width="60"
                        className="img-xs rounded-circle"
                        alt="avatar"
                      />
                    </figure>
                    <span> 0 Attendees</span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center flex-row attend_users_list">
                    {event.attendees.map((attendee, idx) =>
                      idx <= 2 ? (
                        <figure key={idx}>
                          <img
                            src={attendee.profileUrl}
                            width="60"
                            className="img-xs rounded-circle"
                            alt="avatar"
                          />
                        </figure>
                      ) : null
                    )}
                    {event.total_attendees > 3 ? (
                      <span className={"plus-attendees rounded-circle"}>
                        {`${event.total_attendees - 3}`}
                        <i>+</i>
                      </span>
                    ) : null}
                    <span>Attending</span>
                  </div>
                )}

                <button
                  disabled={
                    event.testQualified
                      ? new Date(
                          Date.parse(new Date(event.dateTime)) -
                            event.startCheckInTime * 60 * 60 * 1000
                        ).getTime() >= new Date().getTime()
                        ? true
                        : false
                      : true
                  }
                  className="btn btn-md btn-primary"
                  onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    if (!event.isAttended) {
                      if (!isProfileCompleted) {
                        showCompleteYourDetailsDialog();
                       }
                      else {
                        onEventCheckIn(event._id, index);
                      }
                      
                    }
                  }}
                >
                  {event.isAttended ? "ATTENDING" : "CHECK-IN"}
                  {event.isAttended ? (
                    <i className="float-left mr-2">
                      <img src={tickIcon} width="18" alt="SEF" />
                    </i>
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
