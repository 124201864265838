import {
  FETCH_EVENT_ATTENDEES_START,
  FETCH_EVENT_ATTENDEES_PASS,
  FETCH_EVENT_ATTENDEES_FAIL
} from "../actions";

const initialState = {
  data: {
    data: [],
    metaData: {
      total: 0
    }
  },
  loading: false,
  status: false,
  msg: ""
};

const getEventAttendeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_ATTENDEES_START: {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_EVENT_ATTENDEES_PASS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        msg: action.msg,
        status: true
      };
    }

    case FETCH_EVENT_ATTENDEES_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }

    default: {
      return state;
    }
  }
};

export default getEventAttendeesReducer;
