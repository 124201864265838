import { put, takeLatest } from "redux-saga/effects";
import {
  signupStart,
  signupPass,
  signupFail,
  SIGNUP_INITIATE
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequestNoAuth } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* signupSaga(action) {
  yield put(signupStart());
  try {
    var response = yield postRequestNoAuth({
      API: `${URL.REGISTER}`,
      DATA: {
        ...action.info
      }
    });
    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(signupPass(response.data.msg));
      yield action.handleSuccess();
    } else {
      yield put(signupFail(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(signupFail("Something went wrong"));
    yield action.handleError();
  }
}

export function* wathchSignup() {
  yield takeLatest(SIGNUP_INITIATE, signupSaga);
}
