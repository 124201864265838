import React, { useState, useEffect } from "react";
import { dispatch, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { AppHeader } from "../../atoms/app-header";
import { DecisionPopup } from "../../atoms/decision-popup";
import { SnackbarWrapper } from "../../molecules/snackbar-wrapper";

import { useFeedbackMessages } from "hooks/feedback-messages";

import noPic from "assets/icons/no-pic.jpg";

import "./style.scss";
import { setUserProfileCompleted } from "redux/sef/actions";

const {
  defaultConfig: { PLATFORM, LOCATION, VERSION },
} = require("config/default");

const { DRAWER_ITEMS } = require(`shared/${PLATFORM}/constants`);

const { AppDrawer } = require(`components/${PLATFORM}/atoms/app-drawer`);

const { ROUTES } = require(`shared/${PLATFORM}/constants`);

const { Modal } = require(`components/sef/atoms/modal`);

const {
  CompleteProfileScreen,
} = require(`views/${PLATFORM}/${LOCATION}/${VERSION}/complete-profile`);

const { getUserInfoInitiate } = require(`../../../../redux/sef/actions`);

export const Screen = ({ logoutUser, children, isProfileCompleted, profilePic, isFirstLogin, setFirstLogin }) => {
  const {
    popupVisible,
    popupData,
    openSnackBar,
    snackBarData,
    showPopup,
    showSnackBar
  } = useFeedbackMessages();
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(true);
  const [completeProfileFormVisible, setCompleteProfileFormVisible] = useState(false);
  

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1024) {
        setDrawerVisible(false);
        return;
      } else if (window.innerWidth >= 1024) {
        setDrawerVisible(true);
        return;
      }
    });
  });

  useEffect(() => {
    dispatch(getUserInfoInitiate());
  },[])

  let history = useHistory();
  const onClickAction = routeUrl => {
    routeUrl === "/logout"
      ? showPopup(
          true,
          "Are you sure you want to log out ?",
          "Log Out",
          "Log Out",
          "Cancel",
          () => logoutUser(() => history.replace("/login")),
          () => undefined,
          true
        )
      : history.push(routeUrl);
  };

  const showCompleteYourDetailsDialog = () => { 
    showPopup(
      true,
      'Kindly complete all your details first to proceed.',
      'Complete Your Details',
      'Proceed',
      'Cancel',
      () => setCompleteProfileFormVisible(true),
      () => undefined,
      true
    );
  };

  const showChangePasswordDialog = () => { 
    showPopup(
      true,
      "You can change your password from the profile screen. Do you wish to change now?",
      "Change Password?",
      "Change",
      "Cancel",
      () => { history.push('/profile'); setFirstLogin(true) },
      () => { setFirstLogin(true) },
      true
    );
  };

  useEffect(() => {
    if (!isFirstLogin) {
      showChangePasswordDialog();
    }
  },[])

  useEffect(() => {
    if((history.location.pathname === '/sef-tests' || history.location.pathname === '/events') && !isProfileCompleted && isFirstLogin)
     showCompleteYourDetailsDialog();
  }, [history.location.pathname]); 

  return (
    <div id="screen-hoc-container" className="screen-hoc-container">
      <AppHeader
        profilePic={profilePic ? profilePic : noPic}
        hamburgerVisible={
          drawerVisible ? (window.innerWidth < 1025 ? true : false) : true
        }
        onHamburgerClicked={(e) => {
          setDrawerVisible(!drawerVisible);
          e.stopPropagation();
        }}
        menuItems={[
          {
            label: "Profile",
            routeUrl: ROUTES.PROFILE,
            onClick: onClickAction,
          },
          // {
          //   label: "About Us",
          //   routeUrl: ROUTES.ABOUT_US,
          //   onClick: onClickAction,
          // },
          // {
          //   label: "Contact Us",
          //   routeUrl: ROUTES.CONTACT_US,
          //   onClick: onClickAction,
          // },
          {
            label: "Terms of Use",
            routeUrl: ROUTES.TERMS_CONDITIONS,
            onClick: onClickAction,
          },
          {
            label: "Privacy Policy",
            routeUrl: ROUTES.PRIVACY_POLICY,
            onClick: onClickAction,
          },
          {
            label: "Cookie Policy",
            routeUrl: ROUTES.COOKIES_POLICY,
            onClick: onClickAction,
          },
          {
            label: "Log Out",
            routeUrl: ROUTES.LOGOUT,
            onClick: onClickAction,
          },
        ]}
      />
      <AppDrawer
        toggleDrawer={() => {
          setDrawerVisible(!drawerVisible);
        }}
        drawerVisible={drawerVisible}
        drawerId="app-drawer"
        drawerItems={DRAWER_ITEMS}
        // onClickAction={onClickAction}
        onClickAction={(routeUrl) => {
          onClickAction(routeUrl);
          if (window.innerWidth < 1025) setDrawerVisible(false);
        }}
      />
      <div
        id="screen-content"
        className="screen-hoc-content"
        onClick={(e) => {
          window.innerWidth < 1024 && setDrawerVisible(false);
        }}
      >
        <DecisionPopup
          twoButtons={popupData.twoButtons}
          modalVisibility={popupVisible}
          dialogContent={popupData.dialogContent}
          dialogTitle={popupData.dialogTitle}
          confirmButtonTitle={popupData.confirmButtonTitle}
          rejectButtonTitle={popupData.rejectButtonTitle}
          toggleDialogModal={popupData.toggleDialogModal}
          onConfirmation={popupData.onConfirmation}
          onRejection={popupData.onRejection}
        />
        <div className="main-panel d-flex flex-column">
          <div className="content-wrapper">
            <SnackbarWrapper
              visible={openSnackBar}
              onClose={snackBarData.onClose}
              variant={snackBarData.variant}
              message={snackBarData.msg}
            />
            <Modal
              modalVisibility={completeProfileFormVisible}
              onRejection={() => setCompleteProfileFormVisible(false)}
            >
              <CompleteProfileScreen
                closeModal={() => setCompleteProfileFormVisible(false)}
              />
            </Modal>
            {children(
              showPopup,
              showSnackBar,
              openSnackBar,
              showCompleteYourDetailsDialog
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
