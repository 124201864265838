import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { ROUTES, PAGE_TITLES } = require(`shared/${PLATFORM}/constants`);

export const PageTitles = () => {
  const location = useLocation();
  let title = "";
  switch (location.pathname) {
    case ROUTES.LOGIN: {
      title = PAGE_TITLES.LOGIN;
      break;
    }
    case ROUTES.SIGNUP: {
      title = PAGE_TITLES.SIGNUP;
      break;
    }
    case ROUTES.FORGOT_PASSWORD: {
      title = PAGE_TITLES.FORGOT_PASSWORD;
      break;
    }
    case ROUTES.COMPLETE_PROFILE: {
      title = PAGE_TITLES.COMPLETE_PROFILE;
      break;
    }
    case ROUTES.NEWS_FEED: {
      title = PAGE_TITLES.NEWS_FEED;
      break;
    }
    case ROUTES.EVENTS: {
      title = PAGE_TITLES.EVENTS;
      break;
    }
    case ROUTES.SEF_TESTS: {
      title = PAGE_TITLES.SEF_TESTS;
      break;
    }
    case ROUTES.SEF_TEST: {
      title = PAGE_TITLES.SEF_TEST;
      break;
    }
    case ROUTES.GROUP_CHAT: {
      title = PAGE_TITLES.GROUP_CHAT;
      break;
    }
    case ROUTES.MY_STATISTICS: {
      title = PAGE_TITLES.MY_STATISTICS;
      break;
    }
    case ROUTES.PRIVACY_POLICY: {
      title = PAGE_TITLES.PRIVACY_POLICY;
      break;
    }
    default: {
    }
  }
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
