import { put, takeLatest } from "redux-saga/effects";
import {
  forgotPasswordStart,
  forgotPasswordFail,
  forgotPasswordPass,
  FORGOT_PASSWORD_INITIATE
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequestNoAuth } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* forgotPasswordSaga(action) {
  yield put(forgotPasswordStart());
  try {
    const response = yield postRequestNoAuth({
      API: `${URL.FORGOT_PASSWORD}`,
      DATA: { email: action.email }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(forgotPasswordPass(response.data.msg));
      yield action.handleSuccess();
    } else {
      yield put(forgotPasswordFail(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(forgotPasswordFail("Something Went Wrong."));
    yield action.handleError();
  }
}

export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_INITIATE, forgotPasswordSaga);
}
