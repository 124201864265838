import { put, takeLatest } from "redux-saga/effects";
import {
  fetchFormDataStart,
  fetchFormDataPass,
  fetchFormDataFail,
  FETCH_FORM_DATA_INITIATE
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { getRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* formDataFetchingSaga() {
  yield put(fetchFormDataStart());
  try {
    const response = yield getRequest({
      API: `${URL.GET_FORM_DATA}`
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(fetchFormDataPass(response.data.data, response.data.msg));
    } else {
      yield put(fetchFormDataFail(response.data.msg));
    }
  } catch (error) {
    yield put(fetchFormDataFail("Something went wrong"));
  } finally {
  }
}

export function* watchformDataFetching() {
  yield takeLatest(FETCH_FORM_DATA_INITIATE, formDataFetchingSaga);
}
