import { useState } from "react";
export const useMultiStep = initialTotalSteps => {
  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(initialTotalSteps);
  const makeStepsInfo = () => {
    const stepsArr = [];
    for (let idx = 0; idx < totalSteps; ++idx) {
      if (idx < activeStep) {
        stepsArr.push({
          active: false,
          completed: true,
          visited: true
        });
      } else if (idx > activeStep) {
        stepsArr.push({
          active: false,
          completed: false,
          visited: false
        });
      } else {
        stepsArr.push({
          active: true,
          completed: false,
          visited: true
        });
      }
    }
    return stepsArr;
  };

  let stepsInfo = makeStepsInfo();

  const goToStep = step => {
    let stepDifference = step - activeStep;
    //  check for step value (overflow and underflow) && check when stepDifference = 0;
    if (stepDifference === 0 || step < 0 || step >= totalSteps) {
      return;
    }
    let start = 0;
    let end = 0;
    if (stepDifference > 0) {
      start = activeStep;
      end = step;
    } else {
      start = step;
      end = activeStep;
    }
    for (let i = start; i <= end; ++i) {
      if (i === step) {
        stepsInfo[i] = {
          active: true,
          completed: false,
          visited: true
        };
      } else {
        stepsInfo[i] = {
          ...stepsInfo[i],
          active: false,
          completed: start === activeStep ? true : false
        };
      }
    }
    setActiveStep(step);
  };

  return {
    activeStep: activeStep,
    setTotalSteps: setTotalSteps,
    stepsInfo: stepsInfo,
    goToStep: goToStep,
    goToNextStep: () => goToStep(activeStep + 1),
    goToPrevStep: () => goToStep(activeStep - 1)
  };
};
