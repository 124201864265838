import React from "react";

import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";

import progressBarTick from "assets/icons/progressBarTick.svg";
import "./styles.scss";

import { useSelector, useDispatch } from "react-redux";

import { useParams, useHistory } from "react-router-dom";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");
const { InputSubmit } = require(`components/${PLATFORM}/atoms/input-submit`);

const {
  retryTestRequestInitiate
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

export const TestResultScreen = ({
  result,
  onRetry,
  championshipName,
  timeSpent,
  testTime,
  showPopup,
  showSnackBar
}) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();
  const retryRequestLoading = useSelector(
    state => state.retryTestRequest.loading
  );
  let unattempted =
    result.totalQuestions - (result.wrongList.length + result.totalCorrect);
  let testSubmittedAtTime = timeSpent.minutes * 60 + timeSpent.seconds;
  let timeSpentInSeconds = testTime - testSubmittedAtTime;
  return (
    <>
      {retryRequestLoading ? <Loader /> : null}
      <h3 className="title-text">{championshipName}</h3>

      <div className="card border-0 shadow ">
        <div className="card-body text-center height-vh d-flex align-items-center justify-content-center">
          <div className="result_screen">
            <div className="progress-block">
              <CircularProgressbarWithChildren
                className="progress-field"
                value={result.testScore}
                // text={`${88}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  // rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(109, 207, 246)`,
                  textColor: "#f88",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#6DCFF6"
                })}
              >
                <i className="prog_chk">
                  <img src={progressBarTick} alt="tick" />
                </i>
                <div className="prog_result_txt">
                  <label>{`${result.testScore}%`}</label>
                  <span>{`${result.totalCorrect}/${result.totalQuestions}`}</span>
                </div>
                <div className="xp_points">+ 150 XP</div>
              </CircularProgressbarWithChildren>
            </div>
            <div className="question-ans my-4">
              <h5>
                <strong>
                  {result.wrongList.length > 0
                    ? "You have incorrect answers for these questions."
                    : result.totalCorrect === result.totalQuestions &&
                      "Congratulations"}
                </strong>
              </h5>
            </div>
            <ul className="quet-list text-center">
              {result.wrongList.map((item, idx) => (
                <li key={idx}>{`Q${idx + 1}. ${item.question}`}</li>
              ))}
            </ul>
            {result.wrongList.length === 0 && !unattempted && (
              <>
                <p>You have successfully got all the answers correct.</p>
                <p>
                  Total time spent:{" "}
                  <strong>{` ${(timeSpentInSeconds / 60).toFixed(
                    0
                  )}mins/${timeSpentInSeconds % 60}secs`}</strong>
                </p>
              </>
            )}
            {result.totalCorrect !== result.totalQuestions && (
              <div className="mt-3 mt-md-5 ">
                <InputSubmit
                  buttonLabel={"retry"}
                  containerStyle={[]}
                  immediateDivStyle="justify-content-sm-center btn-pos"
                  config={{
                    onClick: () => {
                      if (result.blockStatus === 2) {
                        dispatch(
                          retryTestRequestInitiate(
                            id,
                            () =>
                              showPopup(
                                false,
                                "Your retry request has been sent to Team SEF",
                                "",
                                "OKAY",
                                undefined,
                                () => history.replace("/sef-tests"),
                                false
                              ),
                            msg => showSnackBar(msg, "error")
                          )
                        );
                      } else {
                        onRetry();
                      }
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
