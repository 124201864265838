export const STRINGS = {
  OLD_PASSWORD_KEY: "oldPassword",
  OLD_PASSWORD_LABEL: "Old Password",

  NEW_PASSWORD_KEY: "newPassword",
  NEW_PASSWORD_LABEL: "New Password",

  CONFIRM_PASSWORD_KEY: "confirmPassword",
  CONFIRM_PASSWORD_LABEL: "Confirm New Password",
};

export const VALIDATION_MESSAGES = {
  OLD_PASSWORD_REQUIRED: "Old password is required",
  NEW_PASSWORD_REQUIRED: "New password is required",
  CONFIRM_PASSWORD_REQUIRED: "Please confirm new password",
  PASSWORDS_DONT_MATCH: "Passwords mismatch",
  PASSWORD_MIN_LENGTH: "Password must be of minimum 6 characters"
};
