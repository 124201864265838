export const FETCH_STATS_INITIATE = "FETCH_STATS_INITIATE";
export const FETCH_STATS_START = "FETCH_STATS_START";
export const FETCH_STATS_PASS = "FETCH_STATS_PASS";
export const FETCH_STATS_FAIL = "FETCH_STATS_FAIL";

export const fetchStatsInitiate = (handleSuccess, handleError) => {
  return {
    type: FETCH_STATS_INITIATE,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const fetchStatsStart = () => {
  return {
    type: FETCH_STATS_START
  };
};

export const fetchStatsPass = (data, passMsg) => {
  return {
    type: FETCH_STATS_PASS,
    data: data,
    msg: passMsg
  };
};
export const fetchStatsFail = errorMsg => {
  return {
    type: FETCH_STATS_FAIL,
    msg: errorMsg
  };
};
