import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import arrowUpSvg from "assets/icons/arrow-up.svg";
import arrowDownSvg from "assets/icons/arrow-down.svg";

import "./styles.scss";

// renderBody should return jsx (renderProps concept)
export const CustomTable = ({ columns, renderBody, onSort, noOfRows }) => {
  const [sortType, setSortType] = useState(0); // 1 for ascending and -1 for descending
  const [sortByKey, setSortByKey] = useState(""); // column key

  return (
    <div className="table-responsive comon-table">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns?.map((column, idx) => (
                <TableCell key={idx}>
                  {column?.label}
                  {column?.isSortable && noOfRows > 1 && (
                    <span className="ad_arrows">
                      <img
                        src={arrowUpSvg}
                        alt="arrow-up"
                        onClick={() => {
                          onSort(column.key, 1);
                          setSortType(1);
                          setSortByKey(column.key);
                        }}
                        style={{
                          opacity:
                            sortByKey === column.key && sortType === 1 && 1
                        }}
                      />
                      <img
                        className="down-arrow"
                        src={arrowDownSvg}
                        onClick={() => {
                          onSort(column.key, -1);
                          setSortType(-1);
                          setSortByKey(column.key);
                        }}
                        alt="arrow-down"
                        style={{
                          opacity:
                            sortByKey === column.key && sortType === -1 && 1
                        }}
                      />
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderBody()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
