export const STRINGS = {
  NEWS_FEED: "News Feed",
  EVENTS: "Events",
  SEF_TESTS: "SEF Tests",
  GROUP_CHAT: "Group Chat",
  MY_STATISTICS: "My Statistics",
  LOGOUT: "Log Out",
  USERS_TEST: "Users Test",
  REQUESTS: "Requests",
  BLOCKED_USERS: "Blocked Users",
  CHAT: "Chat",
  COOKIES_POLICY: "Cookies Policy",
  DOCUMENT: "Document"
};
