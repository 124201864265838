import {
  FETCH_EVENT_DETAILS_START,
  FETCH_EVENT_DETAILS_PASS,
  FETCH_EVENT_DETAILS_FAIL,
  SET_EVENT_DETAILS,
  UPDATE_EVENT_DETAILS
} from "../actions";

const initialState = {
  data: null,
  loading: false,
  status: false,
  msg: ""
};

const getEventDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_DETAILS_START: {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_EVENT_DETAILS_PASS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        msg: action.msg,
        status: true
      };
    }

    case FETCH_EVENT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }
    case SET_EVENT_DETAILS: {
      return {
        ...state,
        data: action.data
      };
    }
    case UPDATE_EVENT_DETAILS: {
      return {
        ...state,
        data: action.data
      };
    }

    default: {
      return state;
    }
  }
};

export default getEventDetailsReducer;
