import React from "react";

import emptyEventPng from "assets/images/empty_event.png";

export const EmptyList = () => {
  return (
    <>
      <div className={"row py-4 py-md-4"}>
        <div className="col-md-8 offset-md-2 text-center">
          <figure className="mb-3 mb-md-4">
            <img src={emptyEventPng} alt={"empty-list"} className="img-fluid" />
          </figure>
          <h5 className="mb-3">{"It seems there are no upcoming events."}</h5>
          <p>
            {
              "Upcoming events will show up here, so you can easily view them here later."
            }
          </p>

          <div className="group_btn mt-3 mt-md-4"></div>
        </div>
      </div>
    </>
  );
};
