import { STRINGS, VALIDATION_MESSAGES } from "./constants";
const validator = values => {
  const errors = {};
  if (!values[STRINGS.TEAM_NAME_KEY]) {
    errors[STRINGS.TEAM_NAME_KEY] = VALIDATION_MESSAGES.TEAM_NAME_REQUIRED;
  }

  if (!values[STRINGS.CATEGORY_KEY]) {
    errors[STRINGS.CATEGORY_KEY] = VALIDATION_MESSAGES.ASN_REQUIRED;
  }

  if (!values[STRINGS.FIA_CATEGORY_KEY]) {
    errors[STRINGS.FIA_CATEGORY_KEY] =
      VALIDATION_MESSAGES.FIA_CATEGORISATION_REQUIRED;
  }

  if (!values[STRINGS.LICENSE_INFO_KEY]) {
    errors[STRINGS.LICENSE_INFO_KEY] =
      VALIDATION_MESSAGES.LICENSE_INFORMATION_REQUIRED;
  }

  if (!values[STRINGS.VEHICLE_INFO_KEY]) {
    errors[STRINGS.VEHICLE_INFO_KEY] =
      VALIDATION_MESSAGES.VEHICLE_REGISTRATION_NUMBER_REQUIRED;
  }

  if (!values[STRINGS.INSURANCE_INFO_KEY]) {
    errors[STRINGS.INSURANCE_INFO_KEY] =
      VALIDATION_MESSAGES.INSURANCE_INFORMATION_REQUIRED;
  }

  // if (!values[STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME]) {
  //     errors[STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME] =
  //         VALIDATION_MESSAGES.AGREE_TO_TERMS_CONDITIONS_REQUIRED;
  // }

  return errors;
};

export default validator;
