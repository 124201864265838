import {
  SET_USER_INFO,
  GET_USER_INFO_PASS,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_START
} from "../actions";

const initialState = {
  data: null,
  loading: false,
  status: false,
  msg: ""
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        data: action.info
      };
    }
    case GET_USER_INFO_START: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_USER_INFO_PASS: {
      return {
        ...state,
        loading: false,
        status: true,
        data: action.data,
        msg: action.msg
      };
    }

    case GET_USER_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userInfoReducer;
