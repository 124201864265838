import React from "react";
import { useFeedbackMessages } from "hooks/feedback-messages";
import { DecisionPopup } from "../../atoms/decision-popup";

export const AuthHoc = ({ children }) => {
  const { showPopup, popupData, popupVisible } = useFeedbackMessages();
    return (
      <>
        <DecisionPopup
          twoButtons={popupData.twoButtons}
          modalVisibility={popupVisible}
          dialogContent={popupData.dialogContent}
          dialogTitle={popupData.dialogTitle}
          confirmButtonTitle={popupData.confirmButtonTitle}
          rejectButtonTitle={popupData.rejectButtonTitle}
          toggleDialogModal={popupData.toggleDialogModal}
          onConfirmation={popupData.onConfirmation}
          onRejection={popupData.onRejection}
        />
        {children(
          showPopup,
        )}
      </>
    );
};
