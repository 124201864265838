import {
  EVENT_CHECK_IN_START,
  EVENT_CHECK_IN_PASS,
  EVENT_CHECK_IN_FAIL
} from "../actions";

const initialState = {
  loading: false,
  status: false,
  msg: ""
};

const eventCheckInReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_CHECK_IN_START: {
      return {
        ...state,
        loading: true
      };
    }

    case EVENT_CHECK_IN_PASS: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: true
      };
    }

    case EVENT_CHECK_IN_FAIL: {
      return {
        ...state,
        loading: false,
        msg: action.msg,
        status: false
      };
    }

    default: {
      return state;
    }
  }
};

export default eventCheckInReducer;
