import { put, takeLatest } from "redux-saga/effects";
import {
  fetchTestsListStart,
  fetchTestsListPass,
  fetchTestsListFail,
  FETCH_TESTS_LIST_INITIATE,
  logoutInitiate
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { getRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* fetchTestsListSaga(action) {
  yield put(fetchTestsListStart());
  try {
    const response = yield getRequest({
      API: `${URL.TEST_UPCOMING}`
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(fetchTestsListPass(response.data.data));
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(fetchTestsListFail(response.data.msg));
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield put(fetchTestsListFail("Something went wrong"));
    yield action.handleError("Something went wrong");
  } finally {
  }
}

export function* watchTestsListFetching() {
  yield takeLatest(FETCH_TESTS_LIST_INITIATE, fetchTestsListSaga);
}
