export const RETRY_TEST_REQUEST_INITIATE = "RETRY_TEST_REQUEST_INITIATE";
export const RETRY_TEST_REQUEST_START = "RETRY_TEST_REQUEST_START";
export const RETRY_TEST_REQUEST_PASS = "RETRY_TEST_REQUEST_PASS";
export const RETRY_TEST_REQUEST_FAIL = "RETRY_TEST_REQUEST_FAIL";

export const retryTestRequestInitiate = (
  championshipId,
  handleSuccess,
  handleError
) => {
  return {
    type: RETRY_TEST_REQUEST_INITIATE,
    championshipId: championshipId,
    handleSuccess: handleSuccess,
    handleError: handleError
  };
};

export const retryTestRequestStart = () => {
  return {
    type: RETRY_TEST_REQUEST_START
  };
};

export const retryTestRequestPass = (data, passMsg) => {
  return {
    type: RETRY_TEST_REQUEST_PASS,
    data: data,
    msg: passMsg
  };
};
export const retryTestRequestFail = errorMsg => {
  return {
    type: RETRY_TEST_REQUEST_FAIL,
    msg: errorMsg
  };
};
