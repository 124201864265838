import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { fetchFormDataInitiate } = require(`../../redux/${PLATFORM}/actions`);

export const useFormDataFetching = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.getFormData.data);
  const isFormDataFetched = useSelector(state => state.getFormData.status);
  useEffect(() => {
      if (!isFormDataFetched) {
        // also manage if we fail to fetch form data
      dispatch(fetchFormDataInitiate());
    }
  }, []);
  return {
    userTypes: useMemo(() => {
      return formData.userTypes.map(item => {
        return {
          label: item.value,
          value: item.key
        };
      });
    }, [formData]),
    mainUserTypes: useMemo(() => {
      return formData.mainUserTypes.map((item) => {
        return {
          label: item.value,
          value: item.key,
        };
      });
    }, [formData]),
    FIACategorisations: useMemo(() => {
      return formData.FIACategorisation.map(item => {
        return {
          label: item.value,
          value: item.key
        };
      });
    }, [formData]),
    bloodGroups: useMemo(() => {
      return formData.bloodGroups.map(bloodType => {
        return {
          label: bloodType.value,
          value: bloodType.key
        };
      });
    }, [formData]),
    relationships: useMemo(() => {
      return formData.relationships.map(relation => {
        return {
          label: relation.value,
          value: relation.key
        };
      });
    }, [formData])
  };
};
