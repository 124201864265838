export const LOGOUT_SELECTED = require('assets/images/logout_selected.png');
export const LOGOUT_ICON = require(`assets/images/logout_icon.png`);
export const DASHBOARD_SELECTED = require(`assets/images/dashboard_selected.png`);
export const DASHBOARD_ICON = require(`assets/images/dashboard.png`);
export const HOME_SELECTED = require(`assets/images/feeds_selected.png`);
export const HOME_ICON = require(`assets/images/feeds.png`);
export const CHAMPIONSHIPS_SELECTED = require(`assets/images/championship_selected.png`);
export const CHAMPIONSHIPS_ICON = require(`assets/images/championship.png`);
export const SEF_TESTS_SELECTED = require(`assets/images/test_selected.png`);
export const SEF_TESTS_ICON = require(`assets/images/test.png`);
export const CHAT_SELECTED = require(`assets/images/chat_selected.png`);
export const CHAT_ICON = require(`assets/images/chat.png`);
export const EVENT_SELECTED = require(`assets/images/event_selected.png`);
export const EVENT_ICON = require(`assets/images/event.png`);
export const STATISTICS_SELECTED = require(`assets/images/Stats_active.png`);
export const STATISTICS_ICON = require(`assets/images/Stats.png`);
export const COOKIE_POLICY_ICON = require(`assets/icons/cookie.png`);