import { put, takeLatest } from "redux-saga/effects";
import SimpleCrypto from "simple-crypto-js";
import {
  loginStart,
  loginFail,
  loginPass,
  LOGIN_INITIATE,
  setToken,
  setUserProfileCompleted,
  setUserInfo,
  setUserId,
  setProfileUrl,
  setFirstLogin,
  setEncryptedPassword,
  setEmail,
  setRememberMe,
} from "../actions";
const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequestNoAuth } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* loginSaga(action) {
  yield put(setRememberMe(action.rememberMe));
  yield put(loginStart());
  try {
    const response = yield postRequestNoAuth({
      API: `${URL.LOGIN}`,
      DATA: {
        email: action.email,
        password: action.password
      }
    });
    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(loginPass(response.data.msg));
      yield action.handleSuccess(response.data.msg);
      if (response.data.data) {
      yield put(setFirstLogin(response.data.data.isFirstLoggedIn));
        if (action.rememberMe) {
          yield put(setEmail(action.email));
          const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_REMEMBER_ME_SECRET_KEY);
          yield put(setEncryptedPassword(simpleCrypto.encrypt(action.password)));
        }
        else {
          yield put(setEmail(null));
          yield put(setEncryptedPassword(null));
        }
      yield put(setUserProfileCompleted(response.data.data.isCompleted));
      yield put(setUserInfo(response.data.data));
      yield put(setUserId(response.data.data._id));
      yield put(setProfileUrl(response.data.data.profileUrl));
        yield put(setToken(response.data.data.token));
      }
      else {
        yield put(setRememberMe(false));
      }
    } else {
      yield put(loginFail(response.data.msg));
      yield action.handleError();
    }
  } catch (error) {
    yield put(loginFail("Something Went Wrong"));
    yield action.handleError();
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_INITIATE, loginSaga);
}
