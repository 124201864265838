import { STRINGS, VALIDATION_MESSAGES } from "./constants";
const {
  defaultConfig: { PLATFORM }
} = require("config/default");
const { REGEX } = require(`shared/${PLATFORM}/constants`);
const validator = values => {
  const errors = {};
  if (!values[STRINGS.BLOOD_GROUP_KEY]) {
    errors[STRINGS.BLOOD_GROUP_KEY] = VALIDATION_MESSAGES.BLOOD_TYPE_REQUIRED;
  }

  // if (!values[STRINGS.BLOOD_ALLERGIES_KEY]) {
  //     errors[STRINGS.BLOOD_ALLERGIES_KEY] =
  //         VALIDATION_MESSAGES.BLOOD_ALLERGIES_REQUIRED;
  // }

  // if (!values[STRINGS.MEDICAL_HISTORY_KEY]) {
  //     errors[STRINGS.MEDICAL_HISTORY_KEY] =
  //         VALIDATION_MESSAGES.PAST_MEDICAL_HISTORY_REQUIRED;
  // }

  // if (!values[STRINGS.OTHER_ISSUES_KEY]) {
  //     errors[STRINGS.OTHER_ISSUES_KEY] =
  //         VALIDATION_MESSAGES.ISSUES_UNDER_TREATMENT_REQUIRED;
  // }

  // if (!values[STRINGS.TUE_KEY]) {
  //     errors[STRINGS.TUE_KEY] = VALIDATION_MESSAGES.T_U_E_REQUIRED;
  // }

  // if (!values[STRINGS.LAST_TETANUS_INJECTION_YAER_KEY]) {
  //   errors[STRINGS.LAST_TETANUS_INJECTION_YAER_KEY] =
  //     VALIDATION_MESSAGES.LAST_TETANUS_INJECTION_YEAR_REQUIRED;
  // }

  if (!values[STRINGS.EMERGENCY_CONTACT_NAME_KEY]) {
    errors[STRINGS.EMERGENCY_CONTACT_NAME_KEY] =
      VALIDATION_MESSAGES.EMERGENCY_CONTACT_NAME_REQUIRED;
  } else if (
    !REGEX.NAME_REGX.test(values[STRINGS.EMERGENCY_CONTACT_NAME_KEY])
  ) {
    errors[STRINGS.EMERGENCY_CONTACT_NAME_KEY] =
      VALIDATION_MESSAGES.EMERGENCY_CONTACT_NAME_INVALID;
  }

  if (!values[STRINGS.EMERGENCY_CONTACT_RELATION_KEY]) {
    errors[STRINGS.EMERGENCY_CONTACT_RELATION_KEY] =
      VALIDATION_MESSAGES.RELATIONSHIP_REQUIRED;
  }

  if (!values[STRINGS.EMERGENCY_CONTACT_NUMBER_KEY]) {
    errors[STRINGS.EMERGENCY_CONTACT_NUMBER_KEY] =
      VALIDATION_MESSAGES.EMERGENCY_CONTACT_NUMBER_REQUIRED;
  } else if (values[STRINGS.EMERGENCY_CONTACT_NUMBER_KEY].length < 6) {
    errors[STRINGS.EMERGENCY_CONTACT_NUMBER_KEY] =
      VALIDATION_MESSAGES.PHONE_NUMBER_MIN_LENGTH;
  }
  if (!values[STRINGS.EMERGENCY_CONTACT_NUMBER_CODE_KEY]) {
    errors[STRINGS.EMERGENCY_CONTACT_NUMBER_CODE_KEY] =
      VALIDATION_MESSAGES.EMERGENCY_CONTACT_NUMBER_CODE_REQUIRED;
  }

  return errors;
};

export default validator;
