import { connect } from "react-redux";
import { MyStatistics } from "./screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  fetchStatsInitiate
} = require(`../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    loading: state.fetchStats.loading,
    status: state.fetchStats.status,
    msg: state.fetchStats.msg,
    data: state.fetchStats.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchStats: (handleSuccess, handleError) =>
      dispatch(fetchStatsInitiate(handleSuccess, handleError))
  };
};

export const MyStatisticsScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyStatistics);
