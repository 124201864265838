import React from "react";
import { reduxForm, Field } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { STRINGS } from "./constants";
import { useParams } from "react-router-dom";

import validator from "./validator";
import "./style.scss";
const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  startTestInitiate
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const { Checkbox } = require(`components/${PLATFORM}/atoms/checkbox`);
const { InputSubmit } = require(`components/${PLATFORM}/atoms/input-submit`);
const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

const { onSubmitFail } = require(`helpers/${PLATFORM}`);

const TestInstructions = ({
  testInstructions,
  onTestInstructionsRead,
  handleSubmit,
  showSnackBar
}) => {
  const dispatch = useDispatch();
  const msg = useSelector(state => state.startTestRequest.msg);
  const loading = useSelector(state => state.startTestRequest.loading);
  const { id } = useParams();
  return (
    <>
      {loading ? <Loader /> : null}
      <form
        onSubmit={handleSubmit(() => {
          dispatch(
            startTestInitiate(
              id,
              () => {
                onTestInstructionsRead();
              },
              (msg) => {
                showSnackBar(msg, "error");
              }
            )
          );
        })}
      >
        <h3 className="title-text">Instructions</h3>
        <div className="card border-0 shadow instruction-hei">
          <div
            className="card-body"
            dangerouslySetInnerHTML={{ __html: testInstructions }}
          ></div>
        </div>
        <Field
          name={STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME}
          component={Checkbox}
          text={STRINGS.TERMS_CONDITIONS_CHECKBOX_TEXT}
          texts={[
            {
              isLink: false,
              value: STRINGS.TERMS_CONDITIONS_CHECKBOX_TEXT,
            },
          ]}
          customstyle="instruction-term"
        />
        <InputSubmit
          buttonLabel={STRINGS.BUTTON_LABEL}
          containerStyle={[]}
          immediateDivStyle="justify-content-sm-start"
        />
      </form>
    </>
  );
};

export const TestInstructionsScreen = reduxForm({
  form: "test-instructions",
  validate: validator,
  onSubmitFail: onSubmitFail
})(TestInstructions);
