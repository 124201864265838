import { put, takeLatest } from "redux-saga/effects";
import {
  getUserInfoStart,
  getUserInfoPass,
  getUserInfoFail,
  GET_USER_INFO_INITIATE,
  setUserInfo
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { getRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* userInfoFetchingSaga() {
  yield put(getUserInfoStart());
  try {
    const response = yield getRequest({
      API: `${URL.GET_USER_INFO}`
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(getUserInfoPass(response.data.msg));
      yield put(setUserInfo(response.data.data));
    } else {
      yield put(getUserInfoFail(response.data.msg));
    }
  } catch (error) {
    yield put(getUserInfoFail("Something Went Wrong"));
  } finally {
  }
}

export function* watchUserInfoFetching() {
  yield takeLatest(GET_USER_INFO_INITIATE, userInfoFetchingSaga);
}
