import { connect } from "react-redux";

import { EventDetails } from "./screen.jsx";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  eventCheckInInitiate,
  updateEventDetails
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    eventDetails: state.getEventDetails.data,
    eventCheckInLoading: state.eventCheckIn.loading,
    eventCheckInStatus: state.eventCheckIn.status,
    eventCheckInMsg: state.eventCheckIn.msg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkIn: (eventId, handleSuccess, handleError) =>
      dispatch(eventCheckInInitiate(eventId, handleSuccess, handleError)),
    viewAllAttendees: (eventId, handleSuccess, handleError) => dispatch(),
    updateEventDetails: data => dispatch(updateEventDetails(data))
  };
};

export const EventDetailsScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetails);
