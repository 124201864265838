export const STRINGS = {
  TEAM_NAME_KEY: "teamName",
  TEAM_NAME_LABEL: "Team Name",
  TEAM_NAME_PLACEHOLDER: "E.g. Champions",

  CATEGORY_KEY: "category",
  CATEGORY_LABEL: "ASN/Category",
  CATEGORY_PLACEHOLDER: "Enter ASN/Category",

  FIA_CATEGORY_KEY: "FIACategorisation",
  FIA_CATEGORY_LABEL: "FIA Categorisation",
  FIA_CATEGORY_PLACEHOLDER: "Select",

  LICENSE_INFO_KEY: "licenseNumber",
  LICENSE_INFO_LABEL: "Licence Number",
  LICENSE_INFO_PLACEHOLDER: "E.g. 100588",

  VEHICLE_INFO_KEY: "vehicleRegistrationNumber",
  VEHICLE_INFO_LABEL: "Vehicle Registration Number",
  VEHICLE_INFO_PLACEHOLDER: "E.g. BD 51 SMR",

  INSURANCE_INFO_KEY: "insurancePolicyNumber",
  INSURANCE_INFO_LABEL: "Insurance Policy Number",
  INSURANCE_INFO_PLACEHOLDER: "E.g. LH7351935",

  CREATE_ACCOUNT_BUTTON_LABEL: "Submit",
  PREVIOUS_BUTTON_LABEL: "PREVIOUS"

  // TERMS_CONDITIONS_CHECKBOX_NAME: "agree",
  // TERMS_CONDITIONS_CHECKBOX_TEXT: "I agree to the Terms & Conditions"
};

export const VALIDATION_MESSAGES = {
  TEAM_NAME_REQUIRED: "Team name is required",
  ASN_REQUIRED: "ASN is required",
  FIA_CATEGORISATION_REQUIRED: "FIA categorisation is required",
  LICENSE_INFORMATION_REQUIRED: "Licence information is required",
  VEHICLE_REGISTRATION_NUMBER_REQUIRED:
    "Vehicle registration number is required",
  INSURANCE_INFORMATION_REQUIRED: "Insurance information is required"
  // AGREE_TO_TERMS_CONDITIONS_REQUIRED: "Please accept the Terms and Conditions."
};
