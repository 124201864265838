import { put, takeLatest } from "redux-saga/effects";
import {
  eventCheckInStart,
  eventCheckInPass,
  eventCheckInFail,
  EVENT_CHECK_IN_INITIATE,
  logoutInitiate
} from "../actions";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { postRequest } = require(`helpers/${PLATFORM}`);

const { STATUS_CODE } = require(`shared/${PLATFORM}/constants`);
const { URL } = require(`shared/${PLATFORM}/api`);

function* eventCheckingSaga(action) {
  yield put(eventCheckInStart());
  try {
    const response = yield postRequest({
      API: `${URL.EVENT_CHECK_IN}`,
      DATA: {
        eventId: action.eventId
      }
    });

    if (response.status === STATUS_CODE.SUCCESSFUL) {
      yield put(eventCheckInPass(response.data.msg));
      yield action.handleSuccess(response.data.msg, true);
    } else if (response.status === STATUS_CODE.UNAUTHORIZED) {
      yield put(logoutInitiate());
    } else {
      yield put(eventCheckInFail(response.data.msg));
      yield action.handleError(response.data.msg, false);
    }
  } catch (error) {
    yield put(eventCheckInFail("Something went wrong"));
    yield action.handleError("Something went wrong", false);
  } finally {
  }
}

export function* watchEventChecking() {
  yield takeLatest(EVENT_CHECK_IN_INITIATE, eventCheckingSaga);
}
