import { connect } from "react-redux";

import { EventAttendees } from "./screen.jsx";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const {
  fetchEventAttendeesInitiate
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    attendees: state.eventAttendees.data.data,
    attendeesMetaData: state.eventAttendees.data.metaData,
    attendeesLoading: state.eventAttendees.loading,
    attendeesMsg: state.eventAttendees.msg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAttendees: (
      eventId,
      page,
      limit,
      filterRole,
      handleSuccess,
      handleError
    ) =>
      dispatch(
        fetchEventAttendeesInitiate(
          eventId,
          page,
          limit,
          filterRole,
          handleSuccess,
          handleError
        )
      )
  };
};

export const EventAttendeesScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventAttendees);
