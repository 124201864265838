import { useState } from "react";

export const useFeedbackMessages = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState({
    twoButtons: true,
    dialogContent: "",
    dialogTitle: "",
    confirmButtonTitle: "",
    rejectButtonTitle: "",
    onConfirmation: () => undefined,
    onRejection: () => undefined,
    toggleDialogModal: () => undefined
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    msg: "",
    variant: "",
    onClose: () => setOpenSnackBar(false)
  });

  const showPopup = (
    twoButtons,
    dialogContent,
    dialogTitle,
    confirmButtonTitle,
    rejectButtonTitle,
    onConfirmation,
    onRejection,
    toggleDialogModal
  ) => {
    setPopupData({
      twoButtons: twoButtons,
      dialogContent: dialogContent,
      dialogTitle: dialogTitle,
      confirmButtonTitle: confirmButtonTitle,
      rejectButtonTitle: rejectButtonTitle,
      onConfirmation: () => {
        setPopupVisible(false);
        onConfirmation();
      },
      onRejection: () => {
        setPopupVisible(false);
        onRejection();
      },
      toggleDialogModal: () =>
        toggleDialogModal ? setPopupVisible(!popupVisible) : undefined
    });
    setPopupVisible(true);
  };

  const showSnackBar = (msg, variant) => {
    setSnackBarData({
      ...snackBarData,
      msg: msg,
      variant: variant
    });
    setOpenSnackBar(true);
  };

  return {
    popupVisible: popupVisible,
    popupData: popupData,
    openSnackBar: openSnackBar,
    snackBarData: snackBarData,
    showPopup: showPopup,
    showSnackBar: showSnackBar
  };
};
