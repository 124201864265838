import React from "react";
import emailSentIcon from "assets/images/email_sent_icon.png";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { Logo } = require(`components/${PLATFORM}/atoms/logo`);
const { InputSubmit } = require(`components/${PLATFORM}/atoms/input-submit`);

const {
  BackgroundRacingFlags
} = require(`components/${PLATFORM}/atoms/background-racing-flags`);

export const Confirmation = ({ history }) => {
  return (
    <>
      <BackgroundRacingFlags />
      <div className={"container"}>
        <Logo />
        <div className={"login-signup-container"}>
          <>
            <div className="front-form">
              <div className="log_reg_form">
                <div className="col-md-6 offset-md-3">
                  <div className="form_title text-center">
                    <i>
                      <img
                        src={emailSentIcon}
                        alt=""
                        className="img-fluid"
                        width="150px"
                      />
                    </i>{" "}
                    <h3> Check your mail! </h3>{" "}
                    <p>
                      We just emailed you a reset password link on your
                      registered email address.{" "}
                    </p>{" "}
                  </div>

                  <InputSubmit
                    buttonLabel={"LOG IN"}
                    containerStyle={["text-center", "mt-2"]}
                    buttonStyle=""
                    config={{
                      onClick: () => history.push("/login")
                    }}
                    immediateDivStyle="justify-content-sm-center"
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
