import React from "react";
import { reduxForm, Field } from "redux-form";

import { STRINGS } from "./constants";

import { Input } from "../../atoms/input";
import { Select } from "../../atoms/select";
import { InputSubmit } from "../../atoms/input-submit";
import { DatePickerInput } from "../../atoms/date-picker";
import { Checkbox } from "../../atoms/checkbox";
import validator from "./validator";

import { ROUTES } from 'shared/sef/constants';

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { onSubmitFail } = require(`helpers/${PLATFORM}`);

const PersonalInfoForm = ({
  disableRoleField,
  disableEmailField,
  handleSubmit,
  countries,
  telephoneCodes,
  userTypes,
  hideCityField,
  hideHouseNoField,
  hideStreetField,
  hidePostalCodeField,
  hideNationalityField,
  hideChampionshipField,
  hideChampionshipAsterisk,
  showOtherUserTypeField,
  hideBirthDateField,
  hidePhoneField,
  hideTermsConditions,
  hideSubscriptionUpdates,
  hidePrivacyNotice
}) => {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="row">
        <Field
          asterisk={true}
          isDisabled={disableRoleField}
          name={STRINGS.USER_TYPE_KEY}
          component={Select}
          label={STRINGS.USER_TYPE_LABEL}
          options={userTypes}
          placeholde={STRINGS.USER_TYPE_PLACEHOLDER}
          isSearchable={false}
        />
        <Field
          asterisk={true}
          name={STRINGS.FIRST_NAME_KEY}
          component={Input}
          label={STRINGS.FIRST_NAME_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.FIRST_NAME_PLACEHOLDER,
          }}
        />
        <Field
          asterisk={true}
          name={STRINGS.LAST_NAME_KEY}
          component={Input}
          label={STRINGS.LAST_NAME_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.LAST_NAME_PLACEHOLDER,
          }}
        />
        <Field
          asterisk={true}
          name={STRINGS.EMAIL_KEY}
          component={Input}
          label={STRINGS.EMAIL_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.EMAIL_PLACEHOLDER,
            disabled: disableEmailField,
          }}
        />
        {!hideNationalityField && <Field
          asterisk={true}
          name={STRINGS.NATIONALITY_KEY}
          component={Select}
          label={STRINGS.NATIONALITY_LABEL}
          options={countries}
          isSearchable={true}
          placeholde={STRINGS.NATIONALITY_PLACEHOLDER}
        />}
        {!hideHouseNoField && <Field
          asterisk={false}
          normalize={(val, val1) => {
                if (!isNaN(val)) {
                  return val;
                } else {
                  return val1;
                }
              }}
          name={STRINGS.HOUSE_NO_KEY}
          component={Input}
          label={STRINGS.HOUSE_NO_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.HOUSE_NO_PLACEHOLDER,
          }}
        />}
        {!hideStreetField && <Field
          asterisk={true}
          name={STRINGS.STREET_KEY}
          component={Input}
          label={STRINGS.STREET_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.STREET_LABEL,
          }}
        />}
        {!hideCityField && <Field
          asterisk={true}
          name={STRINGS.CITY_KEY}
          component={Input}
          label={STRINGS.CITY_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.CITY_PLACEHOLDER,
          }}
        />}
        {!hidePostalCodeField && <Field
          asterisk={true}
          normalize={(val, val1) => {
                if (!isNaN(val)) {
                  return val;
                } else {
                  return val1;
                }
              }}
          name={STRINGS.POSTAL_CODE_KEY}
          component={Input}
          label={STRINGS.POSTAL_CODE_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.POSTAL_CODE_PLACEHOLDER,
          }}
        />}
        {showOtherUserTypeField && <Field
          asterisk={true}
          name={STRINGS.OTHER_USER_TYPE_KEY}
          component={Input}
          label={STRINGS.OTHER_USER_TYPE_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.OTHER_USER_TYPE_PLACEHOLDER,
          }}
        />}
        {!hideChampionshipField && <Field
          asterisk={!hideChampionshipAsterisk ? true : false}
          name={STRINGS.CHAMPIONSHIP_KEY}
          component={Input}
          label={STRINGS.CHAMPIONSHIP_LABEL}
          config={{
            type: "text",
            placeholder: STRINGS.CHAMPIONSHIP_PLACEHOLDER,
          }}
        />}
        {!hideBirthDateField && <Field
          asterisk={true}
          name={STRINGS.BIRTH_DATE_KEY}
          component={DatePickerInput}
          label={STRINGS.BIRTH_DATE_LABEL}
        />}

        {!hidePhoneField && <div className="col-md-6">
          <label>
            {STRINGS.PHONE_NUMBER_LABEL}
            <sup className="sup_error text-danger">*</sup>
          </label>

          <div className="form-row">
            <Field
              name={STRINGS.PHONE_CODE_KEY}
              component={Select}
              options={telephoneCodes}
              widthStyle="col-lg-4 col-md-12"
            />
            <Field
              name={STRINGS.PHONE_NUMBER_KEY}
              component={Input}
              normalize={(val, val1) => {
                if (!isNaN(val)) {
                  return val;
                } else {
                  return val1;
                }
              }}
              config={{
                type: "tel",
                placeholder: STRINGS.PHONE_NUMBER_PLACEHOLDER,
                maxLength: 15,
                minLength: 6,
              }}
              widthStyle="col-lg-8 col-md-12"
            />
          </div>
        </div>}
      </div>
      {!hideTermsConditions && <div className="row">
        <Field
          name={STRINGS.TERMS_CONDITIONS_CHECKBOX_NAME}
          component={Checkbox}
          texts={[{
            isLink: false,
            value: 'I agree to the SEF Race Control '
          },{
            isLink: true,
            value: 'Terms of Use',
            redirectLink: ROUTES.TERMS_CONDITIONS
          }]}
        />
      </div>}
      {!hidePrivacyNotice && <div className="row">
        <Field
          name={STRINGS.SEF_PRIVACY_NOTICE_NAME}
          component={Checkbox}
          texts={[{
            isLink: false,
            value: 'I agree to the processing of my personal data in accordance with the SEF '
          },{
            isLink: true,
            value: 'Privacy Notice',
            redirectLink: ROUTES.PRIVACY_NOTICE
          }]}
        />
      </div>}
      {!hideSubscriptionUpdates && <div className="row">
        <Field
          name={STRINGS.SEF_UPDATES_SUBSCRIPTION_NAME}
          component={Checkbox}
          texts={[{
            isLink: false,
            value: STRINGS.SEF_UPDATES_SUBSCRIPTION_TEXT,
            isBold: true
          }]}
        />
      </div>}
      <InputSubmit
        buttonLabel={STRINGS.NEXT_BUTTON_TEXT}
        containerStyle={[]}
        buttonStyle="float-right"
      />
    </form>
  );
};

export const PersonalInfoReduxForm = reduxForm({
  form: "signup",
  validate: validator,
  onSubmitFail: onSubmitFail,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(PersonalInfoForm);
