export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SET_USER_PROFILE_COMPLETED = "SET_USER_PROFILE_COMPLETED";
export const SET_USER_ID = "SET_USER_ID";
export const SET_PROFILE_URL = "SET_PROFILE_URL";
export const SET_FIRST_LOGIN = "SET_FIRST_LOGIN";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
export const SET_EMAIL = "SET_EMAIL";
export const SET_ENCRYPTED_PASSWORD = "SET_ENCRYPTED_PASSWORD";

export const setToken = userToken => {
  return {
    type: SET_TOKEN,
    userToken: userToken
  };
};

export const removeToken = () => {
  return {
    type: REMOVE_TOKEN
  };
};

export const setUserProfileCompleted = isProfileCompleted => {
  return {
    type: SET_USER_PROFILE_COMPLETED,
    isProfileCompleted: isProfileCompleted
  };
};

export const setUserId = id => {
  return {
    type: SET_USER_ID,
    id: id
  };
};

export const setProfileUrl = url => {
  return {
    type: SET_PROFILE_URL,
    profileUrl: url
  };
};

export const setFirstLogin = isFirstLogin => {
  return {
    type: SET_FIRST_LOGIN,
    isFirstLogin
  }
}

export const setRememberMe = (rememberMe) => { 
  return {
    type: SET_REMEMBER_ME,
    rememberMe
  }
};

export const setEmail = (email) => { 
  return {
    type: SET_EMAIL,
    email
  }
};

export const setEncryptedPassword = (encryptedPassword) => { 
  return {
    type: SET_ENCRYPTED_PASSWORD,
    encryptedPassword
  }
};
