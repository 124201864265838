import React, { useState } from "react";

import { Confirmation } from "./confirmation/screen";

const {
  defaultConfig: { PLATFORM }
} = require("config/default");

const { Logo } = require(`components/${PLATFORM}/atoms/logo`);
const {
  BackgroundRacingFlags
} = require(`components/${PLATFORM}/atoms/background-racing-flags`);

const {
  ForgotPasswordReduxForm
} = require(`components/${PLATFORM}/forms/forgot-password`);

const {
  SnackbarWrapper
} = require(`components/${PLATFORM}/molecules/snackbar-wrapper`);

const { Loader } = require(`components/${PLATFORM}/atoms/loader`);

export const ForgotPassword = ({
  history,
  requestForgotPassword,
  status,
  loading,
  msg
}) => {
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const onFormSubmit = values => {
    requestForgotPassword(
      values.email,
      () => {
        setShowConfirmationDialog(true);
      },
      () => {
        setOpenSnackbar(true);
      }
    );
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {showConfirmationDialog ? (
        <Confirmation history={history} />
      ) : (
        <>
          <BackgroundRacingFlags />
          <div className={"container"}>
            <Logo />
            <div className={"login-signup-container"}>
              <div className="front-form">
                <div className="log_reg_form">
                  <div className="col-md-6 offset-md-3">
                    <SnackbarWrapper
                      visible={openSnackBar}
                      onClose={() => setOpenSnackbar(false)}
                      variant={status ? "success" : "error"}
                      message={msg}
                    />
                    <ForgotPasswordReduxForm onSubmit={onFormSubmit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
