import { useEffect, useState } from "react";

export const usePagination = (initialPage, initialLimit) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage, limit]);

  const onPageChange = page => {
    setCurrentPage(page.selected + 1);
  };
  const onLimitChange = event => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  return [currentPage, limit, setCurrentPage, onPageChange, onLimitChange];
};
